import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {createStore, applyMiddleware, compose} from "redux";
import rootReducer from "./store/reducers/rootReducer";
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import { getFirebase, ReactReduxFirebaseProvider} from 'react-redux-firebase'
// import firebase from './config/fbConfig.js'
// import {createFirestoreInstance} from 'redux-firestore';

const middlewares = [

]
const store = createStore(rootReducer, compose(
  applyMiddleware(...middlewares)
))
// const rrfConfig = {
//   userProfile: "users",
//   userFirestoreForProfile: true
// }



ReactDOM.render(<Provider store={store}>
  {/* <ReactReduxFirebaseProvider
    firebase={firebase}
    config={rrfConfig}
    dispatch={store.dispatch}
    createFirestoreInstance={createFirestoreInstance}
  > */}
    <App />
  {/* </ReactReduxFirebaseProvider> */}
</Provider>, document.getElementById('root'));

serviceWorker.register();
