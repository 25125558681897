import React, {Component} from "react";
import $ from "jquery";
import DatePicker from "react-datepicker";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import tr from "date-fns/locale/tr";
// import "../css/form.css"

class CreateCampaign extends Component{
  state = {
    files: [],
    endDate: null,
    firm: null,
    id: null,
    notes: null,
    startDate: null,
    type: null,
    firms: [
      {
        name: "Bonami",
        deadline: 10,
        payment: 30,
      },
      {
        name: "Vivre",
        deadline: 10,
        payment: 30
      },
      {
        name: "Westwing",
        deadline: 20,
        payment: 30
      }
    ],
    types: [
      "Halı",
      "Kilim",
      "Yastık Kılıfı",
      "Karma"
    ]
  }
  populateFirmDropdown = () => {
    if(this.state.firms.length > 0){
      this.state.firms.forEach(firmOption => {
        $("#firm").append("<option value=" + firmOption.name +">" + firmOption.name + "</option>")
      })
    }
  }
  populateTypeDropdown = () => {
    if(this.state.types.length > 0){
      this.state.types.forEach(typeOption => {
        $("#type").append("<option value=" + typeOption +">" + typeOption + "</option>")
      })
    }
  }
  handleChange = (e) => {
    if(e.target.id === "files"){
      this.setState({
        [e.target.id]: e.target.files
      })
    }
    else{
      this.setState({
        [e.target.id]: e.target.value
      })
    }
  }
  handleSubmit = (e) => {
    e.preventDefault()
  }
  componentDidMount(){
    this.populateFirmDropdown();
    this.populateTypeDropdown();
    registerLocale("tr", tr);
    setDefaultLocale(tr);
  }
  handleStartDateChange = date => {
    this.setState({
      startDate: date
    })
  }
  handleEndDateChange = date => {
    this.setState({
      endDate: date
    })
  }
  render(){
    return(
      <div className="container">
        <form id="campaignCreateForm" onSubmit={this.handleSubmit} onChange={this.handleChange}>
          <div className="Subhead">
            <h2 className="Subhead-heading">Kampanya Oluştur</h2>
            <p className="Subhead-description">
            </p>
          </div>
          <div>
            <div>
              <label htmlFor="firm">Firma</label>
            </div>
            <div>
              <select name="firm" id="firm">
                <option value="" className="theme1-default-option" defaultValue>-----</option>
              </select>
            </div>
          </div>
          <div>
            <div>
              <label htmlFor="type">Kampanya Türü</label>
            </div>
            <div>
              <select name="type" id="type">
                <option value="" className="theme1-default-option" defaultValue>-----</option>
              </select>
            </div>
          </div>
          <div className="datepicker">
            <div>
              Başlangıç Tarihi
            </div>
            <DatePicker selected={this.state.startDate} onChange={this.handleStartDateChange}/>
          </div>
          <div className="datepicker">
            <div>
              Bitiş Tarihi
            </div>
            <DatePicker selected={this.state.endDate} onChange={this.handleEndDateChange}/>
          </div>
          <textarea type="text" name="notes" id="notes" placeholder="Notlar" rows="7" cols="50"/>
          <input multiple type="file" name="files" id="files"/>
          <button className="theme1-button">Oluştur</button>
        </form>
      </div>
    )
  }
}

export default CreateCampaign;