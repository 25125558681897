import authReducer from "./authReducer";
import campaignReducer from "./campaignReducer";
import orderReducer from "./orderReducer";
import {combineReducers} from "redux";

const rootReducer = combineReducers({
  auth: authReducer,
  campaign: campaignReducer,
  order: orderReducer
})

export default rootReducer;