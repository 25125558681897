import React from "react";

import axios from "axios";
import LogoLinkSmall from "../LogoLinkSmall";
import VitausLogo from "../VitausLogo"
import {ReactComponent as ReturnLogo} from "../../assets/icon/return.svg";
import ip from "../../config/backend";
import "handsontable/dist/handsontable.full.css";
import Handsontable from "handsontable";
import tr from "../../config/dict";
import {StackedColumnChart, DonutChart, ColumnChart} from 'bizcharts';

import {withStyles } from '@material-ui/core/styles';
import $ from "jquery";

// import Datetime from 'react-datetime';

const styles = {
    "tablo": {
        float: "left",
        width: "100%",
        marginLeft:"20px",
      },
      "chart":{
        width: "100%",
        // overflow: "auto",
        // background: "red",
        // padding: "2px"
        float: "right",
        overflow: "visible",
      },
      "check": {
        float: "right",
        width: "100%",
        overflow: "visible",
      },
      reportContainer:{
          width:"100%",
          marginTop:"35px"
      },
      monthlyRevenue:{
          height:"550px",
        //   overflow: "visible",
          width: "60%",
          float:"left"
      },
      monthlyRevenueByCustomer:{
        height:"550px",
      //   overflow: "visible",
        width: "30%",
        float:"right"
    }
}


class MonthlyRevenue extends React.Component{
    ref = React.createRef();
    state = {
        data: [],
        reportGraph: [],
        selectedYear: "",
        top8: [],
        selectedStockCode: "",
        hotSettings: {
            rowHeaders: true,
            rowHeights: 25,
            colHeaders: ["Yıl", "Ay", "Toplam Ciro"],
            licenseKey: "non-commercial-and-evaluation"
          },
          darkMode: false
        }

    changeReportGraph =(month, year) =>{
        //   window.location = value
        let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
        let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

        let authData = {
        authKey:  authKey,
        username: username,
        expirationTime: ""
        }

        let postObject = {"year" : year, "month": month, "sessionData": authData}

        axios.post(ip + "api/report/monthlyrevenuewithcustomer", postObject ).then(response => {
            if(response.data !== "Authorization Error"){
                let data = JSON.parse(response.data);
                

                data.forEach(element => 
                    element.TotalRevenue = parseInt(element.TotalRevenue)
                );

                this.setState({
                    reportGraph: data,

                })

            }
        }).catch(error => {
        this.setState({
            redirect: "/reports/monthlyrevenue"
        })
        console.log(error)
        })
    }

    handlePieClick =(e) =>{
        console.log(e)
        if(e.data){
            if(e.data.data)
                this.changeReportGraph(e.data.data.OrderMonth, e.data.data.OrderYear)
        }
        
    }

    handleYearChange =(e) =>{
        this.getReport(e.target.value);
    }

    handleChartType =(e) =>{
        if(e.target.value === "donut" ){
            this.setState({
                isColumnChart : false
            })
        }
        else
        {
            this.setState({
                isColumnChart : true
            })
        }

    
        
        
    }

    getReport(year){
        let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
        let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

        let authData = {
        authKey:  authKey,
        username: username,
        expirationTime: ""
        }

        let postObject = {"year" : year, "sessionData": authData}
        
        axios.post(ip + "api/report/monthlyrevenue", postObject).then(response => {
            
            if(response.data !== "Authorization Error"){
                let data = JSON.parse(response.data);
                data.forEach(element => 
                    element.TotalRevenue = parseInt(element.TotalRevenue)
                );
                

                this.setState({
                    data: data,
                })
                
                this.changeReportGraph(data[0].OrderMonth, year)
                
            }
            
          }).catch(error => {
            console.log(error)
            this.setState({
                redirect: "/reports/monthlyrevenue"
            })
        })
    }
    
    componentDidMount(){
        this.setState({
            isColumnChart: false,
        })
        Handsontable.languages.registerLanguageDictionary(tr)
        let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
        let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

        let authData = {
        authKey:  authKey,
        username: username,
        expirationTime: ""
        }

        axios.post(ip + "api/report/getyears", authData).then(response => {
            console.log(response.data)
            if(response.data !== "Authorization Error"){
                let data = JSON.parse(response.data);
                data.forEach(element => {
                    $("#years").append("<option value=" + element.Year + ">" + element.Year + "</option>");
                  })

                this.getReport(data[0].Year);

            }
            
          }).catch(error => {
            console.log(error)
            this.setState({
                redirect: "/reports/monthlyrevenue"
            })
        })


        
        
        

    }
    classes = this.props.classes;
    

    

    render(){
        const isColumnChart = this.state.isColumnChart;
        return(
            <div id="hot-app" style={{height:"100%"}}>
                <VitausLogo/>
                <LogoLinkSmall to="/reportexplorer" logo={<ReturnLogo height={20} width={20}/>} label=".."/>
                


                <div className = {this.classes.reportContainer}>

                    <div className= {this.classes.monthlyRevenue}>
                        <div>
                            <b>
                                Ay Bazlı Ciro Tablosu
                            </b>
                            <select style={{marginLeft:"30px"}} name="years" id="years" onChange={this.handleYearChange}>
                            </select>
                            <select style={{marginLeft:"10px"}} name="chart-type" id="chart-type" onChange={this.handleChartType}>
                                <option value="donut">Donut Grafik</option>
                                <option value="bar">Bar Grafik</option>
                            </select>
                        </div>
                        {isColumnChart? (
                        <ColumnChart
                            data={this.state.data}
                            title={{
                                visible: true,
                            }}
                            height={"100%"}
                            // highlight-end
                            xField="OrderMonth"
                            yField="TotalRevenue"
                            colorField="OrderMonth"
                            events={{
                                // onPieClick: (event) => console.log("selam"),
                                onLegendClick: (event) => console.log(event),
                                onPlotClick: (event) => this.handlePieClick(event),
                            }}
                        />
                        ) : (
                        <DonutChart
                            data={this.state.data}
                            // title={{
                            //     visible: true,
                            //     text: 'Ay Bazlı Ciro Tablosu',
                            // }}
                            description={{
                                visible: true,
                                text: '',
                            }}
                            height="100%"
                            radius={0.8}
                            innerRadius={0.5}
                            angleField='TotalRevenue'
                            colorField='OrderMonth'
                            label={{
                                visible: true,
                                type: 'spider',
                                allowOverlap: "false",
                                labelHeight: 28,

                                content: v => `${v.OrderMonth}: ${v.TotalRevenue}€`
                            }}
                            meta={{
                                OrderMonth:{
                                    alias: "Ay"
                                },
                                TotalRevenue:{
                                    alias: "Ciro",
                                    formatter:(v)=>{
                                        return v+"€"
                                    }
                                }

                            }}
                            
                            tooltip={{
                                fields : ["OrderMonth", "TotalRevenue"],
                                
                                // formatter : (v,x)=>{
                                //     console.log(x)
                                //     let a = {"name" : "Ay - Ciro", "value": v.OrderMonth + " - " + v.TotalRevenue + "€"}
                                //     return a
                                // }
                                
                            }}
                            legend = {{
                                position: "top-center"
                            }}
                            events={{
                                // onPieClick: (event) => console.log("selam"),
                                onLegendClick: (event) => console.log(event),
                                onPlotClick: (event) => this.handlePieClick(event),
                            }}
                        />)}
                    </div>
                    <div className= {this.classes.monthlyRevenueByCustomer}>
                        <div>
                            <b>
                                Müşteri Dağılımı
                            </b>
                        </div>
                        <StackedColumnChart
                            data={this.state.reportGraph}
                            title={{
                                visible: true,
                            }}
                            height={"100%"}
                            // highlight-end
                            xField="OrderMonth"
                            yField="TotalRevenue"
                            stackField="CustomerName"
                            legend = {{
                                position: "top-right"
                            }}
                        />
                    </div>
                </div>
            </div>
           
        )
    }
}

export default withStyles(styles)(MonthlyRevenue)
