import React from "react";

import axios from "axios";
import LogoLinkSmall from "../LogoLinkSmall";
import VitausLogo from "../VitausLogo"
import {ReactComponent as ReturnLogo} from "../../assets/icon/return.svg";
import ip from "../../config/backend";
import "handsontable/dist/handsontable.full.css";
import Handsontable from "handsontable";
import tr from "../../config/dict";
import {ColumnChart, StackedColumnChart} from 'bizcharts';
import {withStyles } from '@material-ui/core/styles';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';


// import Datetime from 'react-datetime';

const styles = {
    allCharts:{
        width:"100%",
        height:"540px",
    },
    allCategoriesChart:{
        marginTop:"30px",
        width:"53%",
        height:"100%",
        float:"left"
    },
    singleCategoryChart:{
        width:"45%",
        height:"100%",
        marginTop:"50px",
        float:"right"
    },
    chart:{
        height:"100%"
    }
}

const animatedComponents = makeAnimated();
const customStyles = {
    valueContainer: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        const overflowY = "auto"
        const maxHeight = "37px"
    
        return { ...provided, opacity, transition, overflowY, maxHeight };
      }
}

// const selectComponents = { ValueContainer, animatedComponents };
const options = [
    { value: 'all', label: 'Tümünü Seç' },
    { value: 'Ocak', label: 'Ocak' },
    { value: 'Şubat', label: 'Şubat' },
    { value: 'Mart', label: 'Mart' },
    { value: 'Nisan', label: 'Nisan' },
    { value: 'Mayıs', label: 'Mayıs' },
    { value: 'Haziran', label: 'Haziran' },
    { value: 'Temmuz', label: 'Temmuz' },
    { value: 'Ağustos', label: 'Ağustos' },
    { value: 'Eylül', label: 'Eylül' },
    { value: 'Ekim', label: 'Ekim' },
    { value: 'Kasım', label: 'Kasım' },
    { value: 'Aralık', label: 'Aralık' }
  ]


class MonthlyProduct extends React.Component{
    ref = React.createRef();
    state = {
        data: [],
        testGraph : [],
        productGraph: [],
        selectedMonths : [options[1]],
        hotSettings: {
            rowHeaders: true,
            rowHeights: 25,
            colHeaders: ["Kategori", "Toplam", ""],
            licenseKey: "non-commercial-and-evaluation"
          },
          darkMode: false
        }

    handleMonthChange = (selectedOptions) => {
        let selecteds = []
        selectedOptions.forEach(element =>{
            selecteds.push(element.value)
        })
        if(selectedOptions.length>0){
            if(selectedOptions.find(element => element.value === "all")){
                let allMonthOptions =options.slice(1)
                this.setState({
                    selectedMonths : allMonthOptions
                })
                selecteds = []
                for(let i=0; i<allMonthOptions.length; i++)
                    selecteds.push(allMonthOptions[i].value)
            }
            else{
                this.setState({
                    selectedMonths : selectedOptions
                })
            }
        }
        else{
            selecteds = [options[1].value]
            this.setState({
                selectedMonths : options[1]
            })
        }
        
         this.updateCategoryGraph(selecteds);
    }

    handleCategoryClick =(e) =>{
        if(e.data){
            if(e.data.data)
                this.setState({
                    selectedCategory : e.data.data.Category
                })
                this.changeReportGraph(e.data.data.Category, e.data.data.OrderYear)
        }
        
    }

    changeReportGraph =(category, year) =>{
        //   window.location = value
        let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
        let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

        let authData = {
        authKey:  authKey,
        username: username,
        expirationTime: ""
        }

        let postObject = {"category" : category, "year": year, "iswithsizes": "true", "sessionData": authData}

        axios.post(ip + "api/report/yearly/product/category", postObject ).then(response => {
            if(response.data !== "Authorization Error"){
                let data = JSON.parse(response.data);
                data.forEach(element => {
                    element.TotalSold = parseInt(element.TotalSold)
                    element.Size = parseInt(element.Width)+"x"+parseInt(element.Length)
                });

                this.setState({
                    productGraph: data,
                    // selectedStockCode : pGraph
                })
            }
        }).catch(error => {
        console.log(error)
        })

        postObject = {"year": year, "sessionData": authData}
        axios.post(ip + "api/report/yearly/product/category/all", postObject ).then(response => {
            if(response.data !== "Authorization Error"){
                console.log(response)
                let data = JSON.parse(response.data);
                data.forEach(element => {
                    element.TotalSold = parseInt(element.TotalSold)
                    element.Size = parseInt(element.Width)+"x"+parseInt(element.Length)
                });

                this.setState({
                    testGraph: data,
                    // selectedStockCode : pGraph
                })
            }
        }).catch(error => {
        console.log(error)
        })
    }

    updateCategoryGraph(selectedMonths){
        let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
        let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')
        let authData = {
            authKey:  authKey,
            username: username,
            expirationTime: ""
        }
    
        let postObject = {"year" : "2023", "month" : selectedMonths, "sessionData": authData}
        axios.post(ip + "api/report/monthlyproduct", postObject).then(response => {
            if(response.data !== "Authorization Error"){
                let data = JSON.parse(response.data);
                data.forEach(element => {
                    element.TotalSold = parseInt(element.TotalSold)
                    element.TotalM2 = parseFloat(element.TotalM2)
                });

                this.setState({
                    data: data,

                })  
            }
                
        }).catch(error => {
            console.log(error)
        })
    }

    createCategoryGraph(){
        let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
        let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')
        let authData = {
            authKey:  authKey,
            username: username,
            expirationTime: ""
        }
    
        let postObject = {"year" : "2023", "month" : ["Ocak"], "sessionData": authData}
        
        axios.post(ip + "api/report/monthlyproduct", postObject).then(response => {
            if(response.data !== "Authorization Error"){
                let data = JSON.parse(response.data);
                // let top8 = data.slice(1,8);
                data.forEach(element => {
                    element.TotalSold = parseInt(element.TotalSold)
                    element.TotalM2 = parseFloat(element.TotalM2)
                });

                this.setState({
                    data: data,
                    selectedCategory : data[0].Category

                })
                this.changeReportGraph(this.state.selectedCategory, this.state.data[0].OrderYear)
                
            }
                
        }).catch(error => {
            console.log(error)
        })
    }
    
    componentDidMount(){

        Handsontable.languages.registerLanguageDictionary(tr)

        this.createCategoryGraph();

    }
    classes = this.props.classes;
    

    

    render(){
    
        return(
            <div id="hot-app" >
                
                <VitausLogo/>
                <LogoLinkSmall to="/reportexplorer" logo={<ReturnLogo height={20} width={20}/>} label=".."/>

                <div className={this.classes.allCharts}>
                    <div className={this.classes.allCategoriesChart}>
                        <div style={{width:"95%", marginTop:20}}>
                                    <Select
                                        heigh
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        styles={customStyles}
                                        isClearable
                                        options={options}
                                        defaultValue={options[1]}
                                        onChange={this.handleMonthChange}
                                        value = {this.state.selectedMonths}
                                    />
                        </div>
                        <div className= {this.classes.chart}>      
                            <ColumnChart
                                data={this.state.data}
                                title={{
                                    visible: true,
                                }}
                                height={"100%"}
                                // highlight-end
                                xField="Category"
                                yField="TotalSold"
                                colorField="Category"
                                label={{
                                    visible:true,
                                    position:"top",
                                    content: v => `${v.TotalSold} | ${v.TotalM2}m²`
                                }}
                                tooltip={{
                                    fields : ["TotalSold", "TotalM2"]
                                }}
                                meta= {{
                                    TotalSold :{
                                        alias: "Toplam Adet"
                                    },
                                    TotalM2 :{
                                        alias: "Toplam Metraj",
                                        formatter: (v)=>{
                                            return v+"m²"
                                        }
                                    }
                                }}
                                events={{
                                    // onPieClick: (event) => console.log("selam"),
                                    onPlotClick: (event) => this.handleCategoryClick(event),
                                }}
                            />
                        </div>
                    </div>
                    <div className={this.classes.singleCategoryChart}>
                        <div className= {this.classes.chart}> 
                            <div style={{minHeight:"38px"}} >
                                <center>
                                    <b>
                                        Görüntülenen Kategori: {this.state.selectedCategory}
                                    </b>
                                </center>
                            </div>
                            <StackedColumnChart
                                data={this.state.productGraph}
                                title={{
                                    visible: true,
                                }}
                                height={"100%"}
                                // highlight-end
                                xField="OrderMonth"
                                yField="TotalSold"
                                stackField="Size"
                                legend = {{
                                    position: "top-right"
                                }}
                                
                            />
                        </div>
                    </div>
                </div>
            </div>
           
        )
    }
}

export default withStyles(styles)(MonthlyProduct)
