import React from "react";
import {withStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom";

const styles = {
  tableContainer: {
    '& table': {
      margin: "0 auto",
      borderCollapse:"collapse",
      captionSide: "top",
      width:"100%"
    },
    '& table, td, th': {
      border: "solid 1px #a1a1a1",
      padding: "10px"
    },
    '$ table td:first-child': {
      padding: "4px",
      paddingTop: "8px"
    },
    '& table tbody tr:nth-child(even)': {
      background: "#E9E8DD",
    },
    '& table tbody tr:hover': {
      background: "#FFDD52"
    },
    '& table caption > div': {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    '& table input':{
      width: "70px"
    }
  }
}

const styles2 = {
  tableContainer: {
    '& table': {
      margin: "0 auto",
      borderCollapse:"collapse",
      captionSide: "top"
    },
    '& table, td, th': {
      border: "solid 1px #a1a1a1",
      padding: "5px 3px 3px 5px"
    },
    '$ table td:first-child': {
      padding: "4px",
      paddingTop: "8px"
    },
    '& table tbody tr:nth-child(even)': {
      background: "#E9E8DD",
    },
    '& table tbody tr:hover': {
      background: "black"
    },
    '& table caption > div': {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    '& table input':{
      width: "70px"
    }
  }
}

class FilterableTable extends React.Component{
  classes = this.props.classes;
  data =  this.props.data;

  state = {
    // orderNoKey: "",
    displayedData: this.props.data
  }

  componentDidMount(){
    
  }

  clearFilters = (e) => {

  }
  handleFilterChange = (e) => {
     this.setState({
       [e.target.name]: e.target.value
     })
  }
  
  render(){
    // console.log(this.props.links)
    if(!this.props.columns){
       this.props.columns = []
    }
    return(
      <div className={this.classes.tableContainer} style={{border:"none", width:"1180px"}}>
        <table>
          <caption>
            <div>
             <h3>{this.props.tableName}</h3>
            </div>
          </caption>
          <thead>
            <tr>
              <td>
                <img src="https://img.icons8.com/wired/64/000000/delete-forever.png" alt="back" height="23px" width="23px" onClick={this.clearFilters}/>
              </td>
         
              {this.props.columns.map(column => {
                return(
                  <td key={column.name + "Key"}>
                    <input type="text" name={column.name + "Key"} onChange={this.handleFilterChange} value={this.state[column.name + "Key"]}></input>
                  </td>
                )
              })}
            </tr>
            <tr>
              <th>&nbsp;</th>
              {this.props.columns.map(column => {
                return(
                  <th key={column.title}>
                    {column.title}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
              {this.state.displayedData ? this.state.displayedData.map((row,index) => {
                return(
                  <tr key={this.props.ids[index]}>
                    {this.props.isLinked ? <td><Link to={this.props.links[index]}>Düzenle</Link></td> : <td></td>}
                    {this.props.columns.map((column,index) => {
                      return (<td key={column.name}>{row[column.name]}</td>)
                    })}
                  </tr>
                )
              }): null}
          </tbody>
        </table>
      </div>
    )
  }
}

// class tt extends React.Component{
//   classes = this.props.classes;
//   data =  this.props.data;

//   state = {
//     // orderNoKey: "",
//     displayedData: this.props.data
//   }

//   componentDidMount(){
    
//   }

//   clearFilters = (e) => {

//   }
//   handleFilterChange = (e) => {
//      this.setState({
//        [e.target.name]: e.target.value
//      })
//   }
  
//   render(){
//     console.log(this.props.links)
//     if(!this.props.columns){
//        this.props.columns = []
//     }
//     return(
//       <div className={this.classes.tableContainer}>
//         <table>
//           <caption>
//             <div>
//              <h3>anan</h3>
//             </div>
//           </caption>
//           <thead>
//             <tr>
//               <td>
//                 <img src="https://img.icons8.com/wired/64/000000/delete-forever.png" alt="back" height="23px" width="23px" onClick={this.clearFilters}/>
//               </td>
         
//               {this.props.columns.map(column => {
//                 return(
//                   <td key={column.name + "Key"}>
//                     <input type="text" name={column.name + "Key"} onChange={this.handleFilterChange} value={this.state[column.name + "Key"]}></input>
//                   </td>
//                 )
//               })}
//             </tr>
//             <tr>
//               <th>&nbsp;</th>
//               {this.props.columns.map(column => {
//                 return(
//                   <th key={column.title}>
//                     {column.title}
//                   </th>
//                 )
//               })}
//             </tr>
//           </thead>
//           <tbody>
//               {this.state.displayedData ? this.state.displayedData.map((row,index) => {
//                 return(
//                   <tr key={this.props.ids[index]}>
//                     {this.props.isLinked ? <td><Link to={this.props.links[index]}>Düzenle</Link></td> : <td></td>}
//                     {this.props.columns.map((column,index) => {
//                       return (<td key={column.name}>{row[column.name]}</td>)
//                     })}
//                   </tr>
//                 )
//               }): null}
//           </tbody>
//         </table>
//       </div>
//     )
//   }
// }

export default withStyles(styles)(FilterableTable);
// export const TestTable =  withStyles(styles2)(tt)