import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom";

const useStyles = makeStyles({
    logoContainer :{
        "width":"210px",
        "height" : "70px",
        "position": "absolute",
        "marginLeft" : "40px"
      },
      logo :{
        "width":"210px",
        "height" : "100%",
      }
})
export default function LogoLinkSmall(props){
  const classes = useStyles();
  return(
    <div className = {classes.logoContainer}>
        <center>
          <Link to="/"><img className= {classes.logo} src="https://dekormin.com/product-images/erp/logo/vitauslogo.png" alt="">
          </img></Link>
        </center>
    </div>
  )
}