import React from "react";
import { Redirect } from "react-router-dom";
// import "../css/LoginPage.css";
import firebase from 'firebase'
import axios from "axios";
import ip from "../../config/backend";
import './Login.css';


class LoginPage extends React.Component{
  state = {
    email: "",
    password: ""
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  HandleLogin(email, password){
    console.log(email)
    firebase.auth().signInWithEmailAndPassword(email,password)
  .then((userCredential) => {
        sessionStorage.setItem('vts-2016-xyztqwerty', userCredential.user.refreshToken)
        sessionStorage.setItem('vts-2016-ytrewqtzyx', email)
        this.setState({
          redirect: "/"
        })
        let authData = {
          authKey:  userCredential.user.refreshToken,
          username: this.state.email,
          expirationTime: ""
        }
        axios.post(ip + "api/adminuser", authData).then(response => {
          console.log(response)
          let data = JSON.parse(response.data);
          console.log(data)
        }).catch(error => {
          console.log(error)
        })
    // ...
  })
  .catch((error) => {
    // var errorCode = error.code;
    // var errorMessage = error.message;
    return false
  });

}

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state.email)

    this.HandleLogin(this.state.email, this.state.password)

}

  render(){
    if(this.state.redirect){
      return <Redirect to={this.state.redirect}/>
    }
    return(
      
      <div className="test">
        <form onSubmit={this.handleSubmit}>
          <div id="loginform">
              <h2 id="headerTitle">Vitaus</h2>
              <div className="row">
                <label>Email Address</label>
                <input id="email" placeholder="Email" type="text" onChange={this.handleChange}/>
              </div>
              <div className="row">
                <label>Password</label>
                <input id="password" placeholder="Password" type="password" onChange={this.handleChange}/>
              </div>
              <div id="button" className="row">
                <button>Login</button>
              </div>
          </div>
        </form>
      </div>
      
      
    )
  }
  
}

export default LoginPage;