import React, {Component} from "react";
import "react-router-dom";
import {withStyles } from '@material-ui/core/styles';
import {ReactComponent as ReturnLogo} from "../../assets/icon/return.svg";
import LogoLinkSmall from "../../components/LogoLinkSmall";
import VitausLogo from "../VitausLogo"
import ip from "../../config/backend";
import axios from "axios";
import Handsontable from "handsontable";
import { HotTable } from "@handsontable/react";
import tr from "../../config/dict";
import "handsontable/dist/handsontable.full.css";
const localIp = "https://vitaus-erp.herokuapp.com/";
// const localIp = "http://localhost:3000/";

const styles = {
  "root": {

  }
}

class ProductPage extends Component{
  ref = React.createRef();
  state = {
    products: []
  }
  componentDidMount(){
    let instance = this.ref.current.hotInstance;
    Handsontable.languages.registerLanguageDictionary(tr)
    this.setState({
      instance: instance
    })

    let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
    let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

    let authData = {
    authKey:  authKey,
    username: username,
    expirationTime: ""
    }

    axios.post(ip + "api/products", authData).then(response => {
      console.log(response.data)
      let data = JSON.parse(response.data);
      this.setState({
        products: data,
        displayedCustomers: data
      })
    }).catch(error => {
      console.log(error)
    })

  }
  classes = this.props.classes;
  render(){
    return(
      <div>
        <div className={this.classes.tableContainer}>
          <div>
          <VitausLogo/>
          <LogoLinkSmall to="/productexplorer" logo={<ReturnLogo height={20} width={20}/>} label=".."/>
          <HotTable
            style = {{marginTop:"25px"}}
            ref={this.ref}
            licenseKey={"non-commercial-and-evaluation"}
            data={this.state.products.map(element => {
              return [
                element.stockCode,
                element.barcode,
                element.category,
                element.priceDefault,
                element.provider,
                element.width,
                element.length,
                element.msquare,
                "/products/" + element.id
              ]
            })}
            colHeaders={["Stok Kodu", "Barkod", "Kategori", "Fiyat", "Provider", "Genişlik", "Uzunluk", "m2", ""]}
            language={"tr-TR"}
            readOnly={true}
            tableClassName={this.classes.hot}
            filters={true}
            dropdownMenu={true}
            outsideClickDeselects={true}
            columns={[
              {
                type: "text"
              },{
                type: "text"
              },{
                type: "text"
              },{
                type: "numeric"
              },{
                type: "text"
              },{
                type: "numeric"
              },{
                type: "numeric"
              },{
                type: "numeric"
              },
              {
                renderer: (instance, td, row, col, prop, value, cellProperties) => {
                         
                  let button = document.createElement("button", {value: "dsdsds"});
                  Handsontable.dom.addEvent(button, 'mousedown', function(event) {
                    event.preventDefault();
                    
                    window.location = value
                  });
        
                  let newlink = document.createElement('a');
                  newlink.setAttribute('href', value);
                  button.appendChild(newlink)
                  var text = document.createTextNode("Düzenle");
                  button.appendChild(text)
                  Handsontable.dom.empty(td);
                  td.appendChild(button)
                  return td;
                }
              }
            ]}
          />
          </div>
        </div>
      </div>
    )
  }
}



export default withStyles(styles)(ProductPage)