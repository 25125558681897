import React from "react";
import {ReactComponent as ReturnLogo} from "../../assets/icon/return.svg";
import {ReactComponent as ListLogo} from "../../assets/icon/list.svg"
import {ReactComponent as ReportLogo} from "../../assets/icon/report.svg";
import {ReactComponent as AddLogo} from "../../assets/icon/add.svg";
import LogoLink from "../LogoLink";

export default function ProductExplorer()
{
  return(
    <div>
      <div>
        <LogoLink to="/" logo={<ReturnLogo height={45} width={45}/>} label=".."/>
        <LogoLink to="/createProduct" logo={<AddLogo height={45} width={45}/>} label="Ürün Ekle"/>
        <LogoLink to="/products" logo={<ListLogo height={45} width={45}/>} label="Tam Portfolio Listele"/>
        <LogoLink to="/fetchproductdata" logo={<ListLogo height={45} width={45}/>} label="Ürün Verisi Çağır"/>
        <LogoLink to="/productexplorer" logo={<ReportLogo height={45} width={45}/>} label="Rapor"/>
        
      </div>
    </div>
  )
}

