import React, {Component} from "react";
import $, { event } from "jquery";
import { HotTable } from "@handsontable/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import {registerLocale, setDefaultLocale} from "react-datepicker";
import tr from "date-fns/locale/tr";
import dayjs from "dayjs";
import axios from "axios"
import {withStyles } from '@material-ui/core/styles';
import {ReactComponent as ReturnLogo} from "../../assets/icon/return.svg";
import LogoLinkSmall from "../../components/LogoLinkSmall";
import VitausLogo from "../VitausLogo"
import ip from "../../config/backend"
import query from "array-query"
import clsx from "clsx";
import FilterableTable, {TestTable} from "../../components/Tables";
import {Link} from "react-router-dom";
import {Redirect} from "react-router-dom";
import Handsontable from "handsontable";

import ReactExport from "react-export-excel";
import { saveAs } from 'file-saver';
import "../../toggle.css"

const ExcelJS = require('exceljs');

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet2 = [
  {
      name: "Johnson",
      total: 25,
      remainig: 16
  },
  {
      name: "Josef",
      total: 25,
      remainig: 7
  }
];


const styles = {
  "root": {
    '& a': {
      color: "inherit",
      textDecoration: "inherit"
    },
    '& form': {

      padding: "5px"
    },
    '& input[type="file"]':{
      display: "none"
    },
    '& > div': {
      border: "1px solid #ccc"
    },
    '& h5,h4,h3': {
      padding: "0",
      margin: "0"
    },
    '& textarea': {
      resize: "none"
    }
  },
  "fs": {
    background: "red",
    '& > div': {
      display: "inline-block",
      marginRight: "5px"
    }
  },
  "slip": {
    width: "33%",
    border: "1px solid #ccc",
    float: "left",
    height: "550px",
    marginTop: "25px",
    '& > div': {
      padding: "5px",
      width: "auto"
    },
    '& > input': {
      width: "40px"
    }
  },
  slipleft: {
    width: "45%",
    float: "left",
  },
  slipright: {
    textAlign: "right",
    width: "45%",
    float: "right",
    '& > div': {
      float: "right"
    }
  },
  "customer": {
    border: "1px solid #ccc",
    width: "35%",
    float: "left",
    height: "550px",
    marginTop: "25px",
    '& > div': {
      padding: "5px",
      width: "auto"
    },
  },
  "ek": {
    border: "1px solid #ccc",
    float: "left",
    width: "30%",
    height: "550px",
    marginTop: "25px",
    '& > div': {
      padding: "5px",
      width: "auto"
    }
  },
  "detay": {
    clear: "both",
  },
  "upload": {
    border: "1px solid #ccc",
    display: "inline-block",
    padding: "3px 12px",
    cursor: "pointer"
  },
  "deleteFile": {
    border: "1px solid #ccc",
    display: "inline-block",
    padding: "3px 12px",
    cursor: "pointer"
  },
  "fileNames": {
    background: "#cccccc",
    padding: "4px",
    marginTop: "5px",
    width: "90%",
    height: "170px",
    overflowY: "scroll",
  },
  "uploadfileNames": {
    background: "#cccccc",
    padding: "4px",
    marginTop: "5px",
    width: "90%",
    height: "80px",
    overflowY: "scroll",
  },

  "hataCiktisi": {
    // background: "#cccccc",
    padding: "4px",
    marginTop: "5px",
    width: "90%",
    height: "80px",
    // overflowY: "scroll",
  },

  "fileName": {
     overflowX: "hidden",
     background: "#a1a1a1",
     wrodWrap: "normal",
     padding: "2px",
     marginTop: "2px",
     '&:hover':{
       cursor: "pointer"
     }
  },
  "submitButton": {
  },
  "selected": {
    background: "#FFDD52"
  },
  "selectedExcel": {
    border: "solid 2px red"
  },
  "productDetail": {
    display: "block",
    marginTop: "5px",
    width: "100%",
    height: "250px",
    overflow: "hidden",
    "& .ht-product-detail tbody tr:nth-child(even) td ":{
      backgroundColor: "#faedf0",
      transition: "background-color 0.3s"
    },
    "& .ht-product-detail tbody tr:hover td ":{
      backgroundColor: "#fac5d2"
    },
    "& .ht-product-detail td ":{
      fontWeight:"400"
    },
    "& .ht-product-detail thead th":{
      backgroundColor: "#fc3565",
      color: "white",
      fontWeight:"500"
    },
    "& .ht-product-detail tbody th":{
      backgroundColor: "#fc3565",
      color: "white",
      fontWeight:"500"
    },
    "& .ht-product-detail .htAutocompleteArrow" : {
      display: "none"
    },
    "& .ht-product-detail .order-no-button":{
      color: "#000dff",
      textDecoration:"none",
      transition: "color 0.3s, fontWeight 0.3s"
    },
  }
  
}


class CreateOrder extends Component{
  // ref = React.createRef();
  classes = this.props.classes;
  state = {
    id: "",
    creationDate: null,
    currency: "$",
    deadline: null,
    detail: "",
    files: [],
    newFiles: [],
    firm: "",
    no: "",
    paymentDate: null,
    price: "",
    state: "",
    discountRate: "",
    grandTotal: "",
    previewFile: null,
    selectedFile: {
      path: "",
    },
    orderExcelFile: {
    },
    productDetail : [],
    errorExcelProducts : [],
    hataCiktisi : "",
    hataCiktisiButton: true,
    productDetailToggleStatus: "none",
    selectedSumProducts: 0

    

  }

  

  populateDropdown = (dropdownId, optionObject) => {
    Object.keys(optionObject).forEach(key => {
      $(dropdownId).append("<option value=" + key+ ">" + key+ "</option>")
    })
  }

  handleFirmChange = (e) => {
      //populate dates based on firm objects
      if(e.target.value){
        let deadline, payment;
        deadline = this.customers[e.target.value].shipmentDeadline;
        payment = this.customers[e.target.value].paymentDeadline;

        this.setState({
          firm: e.target.value,
          deadline: dayjs(this.state.creationDate).add(deadline, "day")["$d"],
          paymentDate: dayjs(this.state.creationDate).add(payment, "day")["$d"],
        })
      }
  }

  componentDidMount(){
    // let instance =  this.ref.current.hotInstance;
    // this.setState({
    //   instance: instance,
    // })

    let authData = ""
    let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
    let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

    authData = {
      authKey:  authKey,
      username: username,
      expirationTime: ""
    }
    axios.get(ip + "api/customer").then((response) => {

      this.customers = {};
      //populate customers
      JSON.parse(response.data).forEach(customer => {
        $("#firm").append("<option value=" + customer.id + ">" + customer.customerName + "</option>");
        this.customers[customer.id] = customer;
      })
      axios.get(ip + "api/orderstate").then((response) => {
        this.orderStates = {};
        JSON.parse(response.data).forEach(state => {
          $("#state").append("<option value=" + state.id + ">" + state.name + "</option>");
          this.orderStates[state.id] = state;
        })           
        axios.post(ip + "api/order/" + this.props.match.params.id, authData).then((response2) => {
        // axios.get(ip + "api/order/" + this.props.match.params.id).then((response2) => {
          if(response2.data){
            if(response2.data != "Login Error"){
              let allData = JSON.parse(response2.data)
              let order = JSON.parse(allData.order)
              let errorExcelProducts = JSON.parse(allData.errorExcelList)
              let errorExcel = allData.exceptions
              let hataCiktisi = ""
              let hataCiktisiButton = true;
              
              if(errorExcel != null){
                hataCiktisi = "Sipariş excelinde hata mevcut. Hataları düzeltip yeniden yükleyiniz.";
                hataCiktisiButton = false;
              }
              else if(errorExcelProducts.length>0){
                hataCiktisi = "Barkod hataları mevcut. Hata dosyasını indirebilirsiniz.";
                hataCiktisiButton = false;
              }
              let excelfile = query("id").is(order.fileId.toString()).on(order.files);
              // let siraliDosyalar = order.files
              // let excelFileIndex = order.files.indexOf(excelfile)
              // siraliDosyalar.splice(excelFileIndex, 1)
              this.setState({
              creationDate: new Date(order.creationDate),
              currency: order.currency,
              deadline: new Date(order.deadline),
              detail: order.detail,
              files: order.files,
              firm: order.firmId,
              no: order.orderNo,
              paymentDate: new Date(order.paymentDate),
              price: order.price,
              state: order.orderStateId,
              discountRate : order.discountRate,
              grandTotal : order.grandTotal,
              id: order.id,
              excelProducts: order.excelProducts,
              errorExcelProducts : errorExcelProducts,
              hataCiktisi : hataCiktisi,
              hataCiktisiButton : hataCiktisiButton,
              orderExcelFile: (excelfile.length > 0 ? excelfile[0] : {}),
            })

        let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
        let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

        let authData = {
        authKey:  authKey,
        username: username,
        expirationTime: ""
        }


        let postObject = {"id" : this.state.id, "sessionData": authData}
        axios.post(ip + "api/orderproductinfo", postObject).then(response => {
              let data = JSON.parse(response.data);
              // console.log(data)
              let productDataString = ""
              for (let i = 0; i< data.length; i++){
                productDataString += "Kategori: ";
                productDataString += data[i].category;
                productDataString += " - Toplam m2: ";
                productDataString += data[i].totalArea;
                if(data[i].totalPrice){
                  productDataString += " - Tutar: ";
                  productDataString += data[i].totalPrice + this.state.currency;
                }
                productDataString += "\n";
              }
              

              this.setState({
                  productDetail: data.map(product => {
                    return([product.category, product.totalArea, product.totalPrice])
                  })
              })

              const container = document.querySelector('#product-detail');

              const hot = new Handsontable(container, {
                licenseKey:"non-commercial-and-evaluation",
                  className :"ht-product-detail",
                  data: this.state.productDetail,
                  colHeaders:["Kategori", "Alan", "Tutar"],
                  rowHeaders:true,
                  heigh:"200",
                  width : "100%",
                  contextMenu : ["hidden_columns_show", "hidden_columns_hide"],
                  hiddenColumns:{columns:[2]},
                  
                  
                  // dropdownMenu={true}
                  colWidths:"118",
                  // rowHeights = "22"
                  filters:true,
                  columns:[
                    {
                      type: "text",
                      className: "htCenter"
                    },{
                      type: "numeric",
                      className: "htCenter"
                    },{
                      type: "numeric",
                      className: "htCenter"
                    }
                  ],
                  beforeOnCellMouseDown: this.doNotSelectColumn,
                  outsideClickDeselects :this.handleDeselectProducts,
                  afterSelection : this.handleSelectedSumProducts,
              });

              hot.validateCells();
              this.setState({
                hot: hot
              })

            }).catch(error => {
              console.log(error)
          })
        }
          else{
            this.setState({
              redirect: "/login"
            })
          }
            
          }
          else{
            this.setState({
              // redirect: "/orderexplorer"
            })
          }
        }).catch(error =>{
          this.setState({
            redirect: "/orderexplorer"
          })
          console.log(error)
        })
      })
    })
    this.checkCekiListesi()
    registerLocale("tr", tr);
    setDefaultLocale(tr);
    this.setState({
      creationDate: new Date()
    })
  }
  checkCekiListesi = () => {
    axios.get(ip + "api/prepareorder/" + this.props.match.params.id).then(response => {
      let data = JSON.parse(response.data);
      if(data.length > 0){
        this.setState({
          cekiListesi: true
        })
      }
    })
  }


   exportListWithImage = (e) => {

    e.preventDefault()
    // var headerRow = sheet.addRow();

    async function test(links, barcodeList, stockCodeList, quantityList, sheet, workbook){
      

      let results = []
      let defaultImage = ""
      let noImageLink = "https://dekormin.com/product-images/erp/noimage.png"

      for(let i = 0; i<links.length; i++){
        let link = ""

          if(!links[i].includes("https://")){
            link = links[i].replace("http://", "https://")
          }
          else
            link = links[i]

        await fetch(link, {  crossDomain:true}).then((r)=> {
          if(r.status == 200)
            results.push(r)
          else
            results.push("NoImage")
            
        })
        .catch((error)=>{
          results.push("NoImage")
          console.log(error)
        })
      }
      for(let i=0; i<links.length; i++){
        if(results[i]=="NoImage"){
          await fetch(noImageLink, {  crossDomain:true}).then((r)=> {
            if(r.status == 200)
              results[i]= r
            else
              results[i]= "NoImage"
              
          })
          .catch((error)=>{
            results[i]= "NoImage"
            console.log(error)
          })
        }
      }
        return results
    }
    let orderNo = this.state.no
    let barcodeList = []
    let stockCodeList = []
    let imageLinks = []
    let quantityList = []
    let thumbnails = []
    barcodeList = this.state.excelProducts.map(a => a.barcode)
    stockCodeList = this.state.excelProducts.map(a => a.stockCode)
    quantityList = this.state.excelProducts.map(a => a.amount)

    axios.post(ip + "api/productimage", barcodeList).then(response => {

      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet(this.state.no);
  
      sheet.columns = [
        { header: 'Barkod', key: 'barcode', width: 20},
        { header: 'Stok Kodu', key: 'stockCode', width:35 },
        { header: 'Adet', key: 'quantity', width: 10 },
        { header: 'Fotograf', key: 'image', width:17}
      ];
      sheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
      sheet.getCell("B1").alignment = { vertical: 'middle', horizontal: 'center' }
      sheet.getCell("C1").alignment = { vertical: 'middle', horizontal: 'center' }
      sheet.getCell("D1").alignment = { vertical: 'middle', horizontal: 'center' }
      sheet.properties.defaultRowHeight = 120;

      imageLinks = JSON.parse(response.data)
      thumbnails = JSON.parse(imageLinks.thumbnails)
      
      let testLinks = [
        "https://static.vecteezy.com/system/resources/thumbnails/002/318/271/small/user-profile-icon-free-vector.jpg",
    ]


    test(thumbnails, barcodeList, stockCodeList, quantityList, sheet, workbook).then((r)=>{
          // let defaultImage = workbook.addImage({
          //   buffer: r[0].arrayBuffer(),
          //   extension: "png",
          // });
          let image=""
          for(let i=0; i<barcodeList.length; i++){
            var dataRow = sheet.addRow();
            dataRow.height = 120
            let cell = dataRow.getCell(1);
            cell.value = barcodeList[i]
            cell.alignment = { vertical: 'middle', horizontal: 'center' }
            cell = dataRow.getCell(2);
            cell.value = stockCodeList[i]
            cell.alignment = { vertical: 'middle', horizontal: 'center' }
            cell = dataRow.getCell(3);
            cell.value = quantityList[i]
            cell.alignment = { vertical: 'middle', horizontal: 'center' }

            // if(!r[i+1].url.includes("noimage")){
              image = workbook.addImage({
                buffer: r[i].arrayBuffer(),
                extension: "png",
              });

              sheet.addImage(image, {
                tl: {col:3, row: i+1},
                br: { col: 4, row: i+2 },
                editAs: 'twoCell'
              })

              
            // }
            // else{
            //   sheet.addImage(defaultImage, {
            //     tl: {col:3, row: i+1},
            //     br: { col: 4, row: i+2 },
            //     editAs: 'twoCell'
            //   })
            //   console.log(stockCodeList[i])
            //   console.log(r[i+1])
            // }
            
            if(i==thumbnails.length-1){
              
              workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                  new Blob([buffer], { type: "application/octet-stream" }),
                  orderNo + `-Fotografli-Liste.xlsx`
                );
              }).catch((error)=>{
                console.log("Bir hata oluştu")
                console.log(error)
              });
            }
          }
          
    })

    }).catch(e =>{
      console.log(e)
    })


    


  }

  removeOrder = (e) => {
    e.preventDefault();
    if(window.confirm("Sipariş ve tüm içeriği silinecektir. Onaylıyor musunuz?")){
      if(window.confirm("Emin misiniz?")){
        var answer = prompt("Silme işlemi onayı için sipariş numarasını giriniz.")
        if(answer == this.state.no){
          axios.post(ip + "api/removeorder/" + this.state.id).then(response => {
            window.alert("Sipariş kaldırıldı.");
            this.setState({
              redirect: "/orders"
            })
          })
        }
        else
        window.alert("Sipariş numarasını hatalı girdiniz. İşlem iptal edildi.");
        
      }
      else
        window.alert("İşlem İptal Edildi.");

    }
    else
      window.alert("İşlem İptal Edildi.");


  }

  updateStocks = () => {
    if(window.confirm("Çeki listesi stoklardan düşülecektir. Onaylıyor musunuz?")){
      axios.get(ip + "api/order/issuestocks/" + this.state.id).then(response => {
        window.alert("Sipariş listesi stoklardan düşüldü.");
      })
    }
    else
      window.alert("İşlem İptal Edildi.");


  }
  handleChange = (e) => {
    if(e.target.id == "files"){
      let tmp = Object.values(e.target.files).map((f, index) => {
          return f;
      })
      this.setState({
        newFiles: tmp
      })
    }
    else if(e.target.id == "firm"){
      this.handleFirmChange(e);
    }
    else{
      this.setState({
        [e.target.id]: e.target.value
      })
    }
  }
  validated = () => {
    return true;
  }
  handleSubmit = (e) => {
    if(window.confirm("Sipariş kaydedilecektir. Onaylıyor musunuz?")){
        e.preventDefault();
      if(this.validated()){
        if(!parseInt(this.state.orderExcelFile.id))
          this.state.orderExcelFile.id = 0

        let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
        let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

        let authData = {
        authKey:  authKey,
        username: username,
        expirationTime: ""
        }
        let orderData = {
          firmId: this.state.firm,
          creationDate: this.state.creationDate,
          currency: this.state.currency,
          deadline: this.state.deadline,
          detail: this.state.detail,
          orderNo: this.state.no,
          paymentDate: this.state.paymentDate,
          price: parseFloat(this.state.price),
          orderStateId: this.state.state,
          id: this.state.id,
          fileId: parseInt(this.state.orderExcelFile.id),
          discountRate: this.state.discountRate
        }

        let mergedData = {"sessionData": authData, "order": orderData}
        axios.post(ip + "api/updateorder", mergedData)
        .then((response) => {
          if(response != "Authorization Error"){
            window.location.reload()
            window.alert("Sipariş Kaydedildi")
          }
          else
          {
            this.setState({
              redirect : "/login"
            })
          }
        }).catch((error) => {
          console.log(error)
        })
      }
    }
    else
      window.alert("İşlem iptal edildi.")
      
  }
  handleCreationDateChange = date => {
    this.setState({
      creationDate: date,
      deadline: dayjs(date).add(this.customers[this.state.firm].shipmentDeadline, "day")["$d"],
      paymentDate: dayjs(date).add(this.customers[this.state.firm].paymentDeadline, "day")["$d"]
    })
  }
  handleDeadlineDateChange = date => {
    this.setState({
      deadline: date,
    })
  }
  handlePaymentDateChange = date => {
    this.setState({
      paymentDate: date,
    })
  }
  handlePreviewChange = e => {
  }
  uploadFiles = e => {
    let fileCount = this.state.newFiles.length;
    // axios.get("https://www.uuidtools.com/api/generate/v1/count/" + fileCount).then(response => {
    axios.get("https://www.uuidgenerator.net/api/version1/" + fileCount,{
      headers: {
        'Accept': 'application/json',
      }
    }
    
    ).then(response => {
      

      let uids = response.data;
      let formData = new FormData();
      let counter = 1;
      //
      // let nf = document.getElementById("files").files;
      // Object.values(nf)
      this.state.newFiles.forEach((f, index) => {
        formData.append(counter.toString(), f);
        counter += 1
        formData.append(counter.toString(), this.state.id)
        counter += 1
        formData.append(counter.toString(), uids[index].trim())
        //formData.append(counter.toString(), "0157705a-3a52-11ea-b77f-2e728ce88125")
        counter += 1
      })
      axios.post(ip + "api/file", formData).then((response) => {
        window.location.reload()
      }).catch(error => {
        console.log(error)
      })
    }).catch(error => {
      console.log(error)
    })
  }
  changeSelectedFile = e => {
    let tmp = {
      id: e.target.id
    }
    let selectedfile = query("id").is(e.target.id).on(this.state.files);
    this.setState({
      selectedFile: selectedfile[0]
    })
  }
  changeOrderExcel = e => {
    this.setState({
      orderExcelFile: this.state.selectedFile
    })
  }

  deleteFile = e => {
    if(window.confirm("Seçili dosya silinecektir. Onaylıyor musunuz?")){
      let fileOrderData = {
        fileId: this.state.selectedFile.id,
        orderId : this.state.id
      }
      axios.post(ip + "api/deletefile/",fileOrderData).then((response) => {
        if(response.data=="Basarili"){
          if(this.state.selectedFile.id == this.state.orderExcelFile.id){
            this.setState({
              orderExcelFile : {}
            })
          }
          window.location.reload()
          // this.handleSubmit("deleteFile");
        }
        // window.location.reload()
      }).catch(error => {
        console.log(error)
      })
    }
    // this.setState({
    //   orderExcelFile: this.state.selectedFile
    // })
  }

  productDetailToggle = e => {

    const plugin = this.state.hot.getPlugin('hiddenColumns')
    
    if(this.state.productDetailToggleStatus =="none"){
      plugin.showColumns([0,1,2])
      this.state.hot.render();
      this.setState({
        productDetailToggleStatus :"block"
      })
    }
      
    else{
      plugin.hideColumn(2)
      this.state.hot.render();
      this.setState({
        productDetailToggleStatus :"none"
      })
    }
    
  }

  doNotSelectColumn = (event, coords) => {
    if (coords.row === -1 && event.target.nodeName === 'INPUT') {
      event.stopImmediatePropagation();
      // this.state.instance3.deselectCell();
    }
  };

  handleDeselectProducts = (e) => {
    if(e.id != "selectedSumBox"){
      this.setState({
        selectedSumProducts: 0
      })
      return true
    }
    
  }

  handleSelectedSumProducts = (e) => {
    try{
      let selectedInstance = this.state.hot
      let selected = selectedInstance.getSelected() || [];

      const data = [];
      let sum = 0;
      if(selected.length != 0){
        for (let i = 0; i < selected.length; i += 1) {
          const item = selected[i];
          data.push(selectedInstance.getData(...item));
        }

        for(let j = 0; j<data.length; j++)
        {
          for(let i = 0; i<data[j].length; i++)
          {
            sum += parseFloat(data[j][i])
          }
        }
      }
      this.setState({
        selectedSumProducts: parseFloat(sum).toFixed(2)
      })
      console.log(this.state.selectedSumProducts)
    }
    catch(error){
      console.log(error)
    }
    
  }

  render(){
    if(this.state.redirect){
      return <Redirect to={this.state.redirect}/>
    }
    return(
      <div className={this.classes.root}>
        <label style = {{bottom:0, right:15, position:"fixed"}}><b>Toplam</b>
            <input id="selectedSumBox" name="selectedSum" type="number" value={this.state.selectedSumProducts} style = {{marginLeft:5, width:"100px"}} readOnly="{true}"/>
        </label>
        <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
        <VitausLogo/>
        <LogoLinkSmall userInfo={this.state.userInfo} to="/orders" logo={<ReturnLogo height={20} width={20}/>} label=".."/>
          <div className={this.classes.firstRow}>
            <div className={this.classes.slip}>
              <h4>Sipariş Bilgileri</h4>
              <div>
                <div className={this.classes.slipleft}>
                  <div>
                    <div>
                      <label htmlFor="no">Sipariş Numarası</label>
                    </div>
                    <div>
                      <input type="text" value={this.state.no} onChange={this.handleChange} name="no" id="no" width="40"/>
                    </div>
                  </div>
                  <div className="datepicker">
                  <div> Oluşturulma Tarihi</div> 
                    <DatePicker selected={this.state.creationDate} onChange={this.handleCreationDateChange}/>
                  </div>
                  <div className="datepicker">
                    <div>Termin Tarihi</div>
                    <DatePicker selected={this.state.deadline} onChange={this.handleDeadlineDateChange}/>
                  </div>
                  <div className="datepicker">
                    <div>Ödeme Tarihi</div>
                    <DatePicker selected={this.state.paymentDate} onChange={this.handlePaymentDateChange}/>
                  </div>
                </div>
                <div className={this.classes.slipright}>
                  <div>
                    <div>
                      <label htmlFor="state">Sipariş Durumu</label>
                    </div>
                    <div>
                      <select name="state" id="state"  value={this.state.state} onChange={this.handleChange}>
                        <option defaultValue>-----</option>
                      </select>
                    </div>             
                  </div>
                  <div>
                    <div style={{marginTop:"2px"}}>
                      <label htmlFor="price">Tutar</label>
                    </div>
                    <div>
                      <input type="number" step="0.01" value={this.state.price} onChange={this.handleChange} name="price" id="price"/>
                    </div>
                    
                  </div>
                  <div style= {{marginTop:"2px"}}>
                    <div style={{float:"right", width:"50%",  }}>
                      <div>
                        <label htmlFor="currency">Döviz</label>
                      </div>
                      <div>
                        <select name="currency" id="currency" value={this.state.currency} onChange={this.handleChange}>
                          <option value="$">Dolar</option>
                          <option value="€">Euro</option>
                          <option value="₺">Lira</option>
                        </select>
                      </div>
                    </div>
                    <div style={{float:"right", width:"40%"}}>
                      <div>
                        <label htmlFor="discount-rate">İndirim (%)</label>
                      </div>
                      <div>
                        <input type="number" step="0.01" value={this.state.discountRate} onChange={this.handleChange} name="discountRate" id="discountRate" style={{width:"100%"}}/>
                      </div>
                    </div>
                    
                    
                  </div>

                  <div>
                    <div>
                      <label htmlFor="grandTotal">Genel Tutar</label>
                    </div>
                    <div>
                      <input type="number" step="0.01" value={this.state.grandTotal} name="grandTotal" id="grandTotal" readOnly={true}/>
                    </div>
                    
                  </div>


                </div>
                <div className={this.classes.detay}>
                  
                <div style={{paddingTop: "20px"}}>
                <h4 >Müşteri Bilgileri</h4>
                  <div>
                    <label htmlFor="firm">Firma</label>
                  </div>
                  <div>
                    <select name="firm" id="firm" onChange={this.handleChange} value={this.state.firm}>
                      <option defaultValue >-----</option>
                    </select>
                  </div>
                </div>
                <div className={this.classes.customerLeft}>
                  <div>
                    Adres: {(this.state.firm ? this.customers[this.state.firm].customerAddress : null)}
                  </div>
                  <div>
                    İletişim: {(this.state.firm ? this.customers[this.state.firm].contactPerson : null)}
                  </div>
                  <div>
                    Email: {(this.state.firm ? this.customers[this.state.firm].contactEmail : null)}
                  </div>
                  <div>
                    Vat No.: {(this.state.firm ? this.customers[this.state.firm].vatNumber : null)}
                  </div>
                  <div>
                    Vat Ofis: {(this.state.firm ? this.customers[this.state.firm].vatOffice : null)}
                  </div>
                  <div>
                    Sipariş Termini: {(this.state.firm ? this.customers[this.state.firm].shipmentDeadline + " Gün" : null)}
                  </div>
                  <div>
                    Ödeme Vadesi: {(this.state.firm ? this.customers[this.state.firm].paymentDeadline + " Gün" : null)}
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div className={this.classes.customer}>
              <div>
                <h4 >Sipariş Detayları</h4>
                <div>
                  <label htmlFor="detail">Notlar</label>
                </div>
                <textarea type="text" name="detail" id="detail" value={this.state.detail} onChange={this.handleChange} placeholder="" rows="10" cols="51"/>
              </div>
              <div style={{paddingTop:"8px"}}>
                <label className="toggle">
                  <input className="toggle-checkbox" type="checkbox" onChange={this.productDetailToggle}></input>
                  <div className="toggle-switch"></div>
                  <span className="toggle-label">Detay Görüntüle</span>
                </label>
                <div className={this.classes.productDetail}>
                  <div id="product-detail"></div>
                </div>
              </div>
            </div>
            <div className={this.classes.ek}>
              <h4>Ekler</h4>
              <div className={this.classes.filecontainer}>
                <h5>Dosyalar</h5>
                <div className={this.classes.fileNames}>
                  {this.state.files.map((f, index) => {
                    return (            
                     <div key={f.name} id={f.id} onClick={this.changeSelectedFile}
                     style={ 
                       {/* (f.id == this.state.selectedFile.id ? {background: "#FFDD52"} : null) */}
                       }
                       className={clsx(this.classes.fileName, 
                       (f.id == this.state.selectedFile.id ? this.classes.selected : null),
                       (f.id == this.state.orderExcelFile.id ? this.classes.selectedExcel: null)
                       )}
                       //className={this.classes["fileName" + (f.id==this.state.selectedFile.id ? "selected": "")]}
                     >
                       {f.name}
                    </div>
                    )
                  })}
                </div>
              <a href={this.state.selectedFile.path}><div className={this.classes.upload}>İndir</div></a>
              <div className={this.classes.upload} onClick={this.changeOrderExcel}>Sipariş Exceli Tanımla</div>
              <div className={this.classes.deleteFile} onClick={this.deleteFile}>Dosyayı sil</div>
              </div>
              <div className={this.classes.filecontainer}>
                <input multiple  type="file" name="files" id="files"/>
                <div className={this.classes.uploadfileNames}>
                  {this.state.newFiles.map((f,index) => {
                    return (
                      <div 
                        key={f.name} 
                        id={"file" + index} 
                        onClick={this.handlePreviewChange} 
                        >
                        {f.name}
                      </div>)
                  })}
                </div>
                
                <label htmlFor="files" className={this.classes.upload}>Dosya Seç</label>
                <div className={this.classes.upload} onClick={this.uploadFiles}>Yükle</div>
              </div>
              <div className={this.classes.hataCiktisi}>
                    <div><h5>Hata Çıktısı</h5></div>
                    <textarea readOnly={true} type="text" name="hata-ciktisi" id="hata-ciktisi" value={this.state.hataCiktisi} placeholder="" rows="3" cols="41"/>
                    <ExcelFile filename={"Hata Ciktisi-" + this.state.no} element={<button type="button" hidden= {this.state.hataCiktisiButton}>Hataları Gör</button>}>
                      <ExcelSheet data={this.state.errorExcelProducts} name="Hatalar">
                          <ExcelColumn label="Barkod" value="barcode"/>
                          <ExcelColumn label="Stok Kodu" value="stockCode"/>
                          <ExcelColumn label="Adet" value="amount"/>
                          <ExcelColumn label="Hata" value={(col) => "Barkod Hatası"}/>
                      </ExcelSheet>
                    </ExcelFile>
              </div>
            </div>
          </div>
          
          {this.state.excelProducts ? (<Link to={"/prepare/" + this.state.id}><button>Çeki Listesi</button></Link>) : null}
          {this.state.excelProducts ? (<button onClick={this.exportListWithImage}>Fotoğraflı Liste</button>)  : null}
          {this.state.cekiListesi ? (<button onClick={this.updateStocks}>Siparişi Tamamla</button>) : null}
          <button style={{background:"red", color:"white"}} onClick ={this.removeOrder}>Siparişi Sil</button>
          <button className={this.classes.submitButton} style={{float: 'right', marginRight: "1.4%"}}>Kaydet</button>
          
          <div className={this.classes.detay}>
          </div>

        </form>
        {this.state.excelProducts ? (
          <FilterableTable
          
           tableName={"Seçili Sipariş"}
           columns = {[
             {
               name: "stockCode",
               title: "Stok Kodu"
             },
             {
               name: "barcode",
               title: "Barkod"
             },
             {
               name: "amount",
               title: "Miktar"
             },
             {
               name: "category",
               title: "Kategori"
             },
             {
              name: "priceDefault",
              title: "Fiyat"
             },
             {
               name: "provider",
               title: "Tedarikçi"
             },
             {
               name: "width",
               title: "Genişlik"
             },
             {
               name: "length",
               title: "Uzunluk"
             },
             {
               name: "msquare",
               title: "m2"
             }
           ]}
           data={this.state.excelProducts.map(row => {
             return {
              stockCode: row.stockCode,
              barcode: row.barcode,
              amount: row.amount,
              category: row.category,
              priceDefault: row.price,
              provider: row.provider,
              width: row.width,
              length: row.length,
              msquare: row.msquare
             }
           })}
           isLinked={false}
           ids={this.state.excelProducts.map(row => {
             return row.id
           })}
        />

        ): null}
        
       </div>
       
    )
  }
}


export default withStyles(styles)(CreateOrder);

