import React from "react";
import {NavLink} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  logo: {
    height:"60px",
    textAlign: "center"
  },
  label: {
    height:"25px",
    textAlign: "center",
    background: "linear-gradient(174deg, rgba(222,235,255,1) 0%, rgba(168,168,168,0.3536765047816002) 83%)"   
  },
  link: {
    margin: "10px",
    width: "auto",
    display: "inline-block"
  },
})
export default function LogoLink(props){
  const classes = useStyles();
  return(
    <NavLink to={props.to}>
      <div className={classes.link}>
        <div className={classes.logo}>
          {props.logo}
        </div>
        <div className={classes.label}>
          {props.label}
        </div>
      </div>
    </NavLink>
  )
}