import React from "react";
import { Redirect } from "react-router-dom";
import {withStyles } from '@material-ui/core/styles';
import axios from "axios";
import LogoLinkSmall from "../LogoLinkSmall";
import VitausLogo from "../VitausLogo"
import {ReactComponent as ReturnLogo} from "../../assets/icon/return.svg";
// import FilterableTable from "../../components/Tables";
import ip from "../../config/backend";
import "handsontable/dist/handsontable.full.css";
import Handsontable from "handsontable";
import tr from "../../config/dict";
import moment from "moment"
import dayjs from "dayjs";
import Select, { components } from 'react-select'
import makeAnimated from 'react-select/animated';


const styles = {
  test: {
    border: "1px solid #ccc",
    padding: "1px 6px"
    },
  "#example3": {
    width: "100%",
    marginLeft: "0px"
  },
  
  htTest:{
    "& table":{
      background:"black"
    }
  },
  tableContainer: {
    "& .ht-test tbody tr:nth-child(odd) td ":{
      backgroundColor: "#edf3f7",
      transition: "background-color 0.3s"
    },
    "& .ht-test tbody tr:hover td ":{
      backgroundColor: "#a3e0f7"
    },
    "& .ht-test td ":{
      fontWeight:"400"
    },
    "& .ht-test thead th":{
      backgroundColor: "#dceefa"
    },
    "& .ht-test tbody th":{
      backgroundColor: "#dceefa"
    },
    "& .ht-test .htAutocompleteArrow" : {
      display: "none"
    },
    "& .ht-test .order-no-button":{
      color: "#000dff",
      textDecoration:"none",
      transition: "color 0.3s, fontWeight 0.3s"
      
    },

    "& .ht-test .order-no-button:hover":{
      color: "#eb0260",
      textDecoration:"underline",
      fontWeight: "700"
    }

    // '& table': {
    //   margin: "0 auto",
    //   borderCollapse:"collapse",
    //   captionSide: "top"
    // },
    // '& table, td, th': {
    //   border: "solid 1px #a1a1a1",
    //   // padding: "10px"
    // },
    // '$ table td:first-child': {
    //   // padding: "4px",
    //   // paddingTop: "8px"
    // },
    // '& table tbody tr:nth-child(even)': {
    //   background: "#E9E8DD",
    // },
    // '& table tbody tr:hover': {
    //   background: "#FFDD52"
    // },
    // '& table caption > div': {
    //   display: "flex",
    //   justifyContent: "space-between",
    //   alignItems: "center"
    // },
    // '& table input':{
    //   width: "70px"
    // },
  },
}

const customStyles = {
  container: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      // const overflowY = "auto"
      const zIndex = "1000"
  
      return { ...provided, opacity, transition, zIndex };
    }
}

const animatedComponents = makeAnimated();

class OrderPage extends React.Component{
  ref = React.createRef();
  state = {
    orders: [],
    customerFilter: "",
    noFilter: "",
    displayedOrders: [],
    cDateFilter: "",
    dDateFilter: "",
    pDateFilter: "",
    currency: "",
    price: "",
    orderNotifs: [],
    selectedSumOrders : 0,
    hot: "",
  }
  classes = this.props.classes;
  filter = () => {
    if(this.state.orders){
      //customer filter
      let filtered = ""
      filtered = this.state.orders.filter(item => {
        return item.customer.customerName.toLowerCase().includes(this.state.customerFilter.toLowerCase());
      })
      //currency
      filtered = filtered.filter(item => {
        return item.currency.toLowerCase().includes(this.state.currency.toLowerCase());
      })
      //price
      if(this.state.price.length > 1){
        if(this.state.price.charAt(0) === ">"){
          filtered = filtered.filter(item => {
            return parseFloat(item.price) > this.state.price.slice(1, this.state.price.length)
          })
        }
        if(this.state.price.charAt(0) === "<"){
          filtered = filtered.filter(item => {
            return parseInt(item.price) < this.state.price.slice(1, this.state.price.length)
          })
        }
      }
      //no filter
      if(!Number.isNaN(parseInt(this.state.noFilter.charAt(0)))){
        filtered = filtered.filter(item => {
          return item.orderNo.toLowerCase().includes(this.state.noFilter.toLowerCase());
        })
      }else{
        let number = parseInt(this.state.noFilter.slice(1, this.state.noFilter.length));
        if(this.state.noFilter.charAt(0) === ">"){
          filtered = filtered.filter(item => {
            return parseInt(item.orderNo) > number;
          })
        }
        if(this.state.noFilter.charAt(0) === "<"){
          filtered = filtered.filter(item => {
            return parseInt(item.orderNo) < number;
          })
        }      
      }
      //creation date filter
      if(!Number.isNaN(parseInt(this.state.cDateFilter.charAt(0)))){ 
        filtered = filtered.filter(item => { 
          let dt = this.dateFormatter(item.creationDate)         
          return dt.toLowerCase().includes(this.state.cDateFilter.toLowerCase());
        })
      }else if(this.state.cDateFilter.length>1){
        let date = this.state.cDateFilter.slice(1, this.state.cDateFilter.length);
        date = date.split(".");
        date = new Date(date[2], date[1]-1, date[0]);
        if(this.state.cDateFilter.charAt(0) === ">"){
          filtered = filtered.filter(item => {
            return new Date(item.creationDate) > date;
          })
        }
        if(this.state.cDateFilter.charAt(0) === "<"){
          filtered = filtered.filter(item => {
            return new Date(item.creationDate) < date;
          })
        }      
      }

       //deadline date filter
       if(!Number.isNaN(parseInt(this.state.dDateFilter.charAt(0)))){ 
        filtered = filtered.filter(item => { 
          let dt = this.dateFormatter(item.deadline)         
          return dt.toLowerCase().includes(this.state.dDateFilter.toLowerCase());
        })
      }else if(this.state.dDateFilter.length>1){
        let date = this.state.dDateFilter.slice(1, this.state.dDateFilter.length);
        date = date.split(".");

        date = new Date(date[2], date[1]-1, date[0]);

        if(this.state.dDateFilter.charAt(0) === ">"){
          filtered = filtered.filter(item => {
            return new Date(item.deadline) > date;
          })
        }
        if(this.state.dDateFilter.charAt(0) === "<"){
          filtered = filtered.filter(item => {
            return new Date(item.deadline) < date;
          })
        }      
      }

      //payment date filter
      if(!Number.isNaN(parseInt(this.state.pDateFilter.charAt(0)))){ 
        filtered = filtered.filter(item => { 
          let dt = this.dateFormatter(item.paymentDate)         
          return dt.toLowerCase().includes(this.state.pDateFilter.toLowerCase());
        })
      }else if(this.state.pDateFilter.length>1){
        let date = this.state.pDateFilter.slice(1, this.state.pDateFilter.length);
        date = date.split(".");
        date = new Date(date[2], date[1]-1, date[0]);
        if(this.state.pDateFilter.charAt(0) === ">"){
          filtered = filtered.filter(item => {
            return new Date(item.paymentDate) > date;
          })
        }
        if(this.state.pDateFilter.charAt(0) === "<"){
          filtered = filtered.filter(item => {
            return new Date(item.paymentDate) < date;
          })
        }      
      }
      
      this.setState({
        displayedOrders: filtered
      })
      
    }
    
  }
  componentDidMount(){
    // let instance =  this.ref.current.hotInstance;
    Handsontable.languages.registerLanguageDictionary(tr)
    let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
    let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

    let authData = {
      authKey:  authKey,
      username: username,
      expirationTime: ""
    }
    axios.post(ip + "api/orders", authData)
    .then(({data}) => {
      if(data != "Login Error"){
      let orderList = JSON.parse(data);
      this.setState({
        // instance: instance,
        orders: JSON.parse(data),

        displayedOrders: JSON.parse(data),
        // myData: myArray
        
        myData: orderList.map(order => {
          var link = "<a class='order-no-button' href ='/orders/"+order.id+"'><div style='width:100%'>"+order.orderNo+"</div></a>"
          // var creationDate = new Date(order.creationDate).getDate() + "/" + new Date(order.creationDate).getMonth() + "/" + new Date
          // (order.creationDate).getFullYear()
          var creationDate= moment(new Date(order.creationDate)).format("DD/MM/YYYY")
          var deadline= moment(new Date(order.deadline)).format("DD/MM/YYYY")
          var paymentDate= moment(new Date(order.paymentDate)).format("DD/MM/YYYY")
          return([ link, order.customer.customerName, creationDate, order.grandTotal, order.currency, deadline, paymentDate, order.discountRate, order.orderState])
        })
      })
      // var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
      // dayjs.extend(isSameOrAfter)
      let orderNotifs = []
      this.state.orders.forEach((element) => {
        let deadline = element.deadline
        let paymentDeadline = element.paymentDate

        let marginDeadline = dayjs().add(2, "days");

        // let deadlineStatus = dayjs().isSameOrAfter(dayjs(deadline))
        // let paymentDeadlineStatus = dayjs().isSameOrAfter(dayjs(paymentDeadline))
        // let marginDeadlineStatus = dayjs(marginDeadline).isSameOrAfter(dayjs(deadline))

        let deadlineStatus = dayjs().diff(deadline, "hour")
        let paymentDeadlineStatus = dayjs().diff(paymentDeadline, "hour")
        let marginDeadlineStatus = dayjs(marginDeadline).diff(dayjs(deadline),"hour")
        
        if(deadlineStatus >= 0 && parseInt(element.orderStateId)<4){
          orderNotifs.push({"value":element.orderNo, "label": "Sipariş No: " + element.orderNo + "    # Teslim Süresi Gecikti! #"})
        }
        else if(marginDeadlineStatus >= 0 && parseInt(element.orderStateId)<4){
          orderNotifs.push({"value":element.orderNo, "label":"Sipariş No: " + element.orderNo + "    # Teslim Terminine 2 Günden Az Kaldı! #"})
        }
        if(paymentDeadlineStatus >=0 && parseInt(element.orderStateId)<6){
          orderNotifs.push({"value": element.orderNo, "label":"Sipariş No: " + element.orderNo + "    # Ödeme Termin Süresi Gecikti! #"})
        }

      })

      this.setState({
        orderNotifs : orderNotifs
      })

      function checkQueryString(query){
        var operators=[];
        var numbers= [];
        var finalNumbers = [];
        var finalResult = [];
        for (var i = 0; i < query.length; i++) {
          if(query.charAt(i)==="!"){
            operators.push("!")
          }
          else if(query.charAt(i)==="<"){
            operators.push("<")
          }
          else if(query.charAt(i)===">"){
            operators.push(">")
          }
        }
        var numbers = query.split(",")
        
        numbers.forEach(function(item){
          item = item.replace('>','');
          item = item.replace('<','');
          finalNumbers.push(item)
        });


        finalResult.push(operators);
        finalResult.push(finalNumbers);

        return(finalResult)
      }

      var debounceFn = Handsontable.helper.debounce(function (colIndex, event) {
        var filtersPlugin = hot.getPlugin('filters');
        var queryString = checkQueryString(event.target.value)
        var operators = queryString[0];
        var numbers = queryString[1];
        filtersPlugin.removeConditions(colIndex);
        if(colIndex === 2 || colIndex === 5 || colIndex===6){
          if(operators.length===1){
            if(operators[0]===">")
              filtersPlugin.addCondition(colIndex, "date_after", [numbers[0]]);
            if(operators[0]==="<")
              filtersPlugin.addCondition(colIndex, "date_before", [numbers[0]]);
              if(operators[0]==="!"){
                console.log(event.target.value)
                let trunc_str = event.target.value.replace("!", "")
                filtersPlugin.addCondition(colIndex, 'not_contains', [trunc_str]);
              }
          }
          else if (operators.length>1){
            if(operators[0]===">"){
              filtersPlugin.addCondition(colIndex, "date_after", [numbers[0]]);
              filtersPlugin.addCondition(colIndex, "date_before", [numbers[1]]);
            }
            else{
              filtersPlugin.addCondition(colIndex, "date_after", [numbers[1]]);
              filtersPlugin.addCondition(colIndex, "date_before", [numbers[0]]);
            }
          }
          else
            filtersPlugin.addCondition(colIndex, 'contains', [event.target.value]);
        }
        else{
          if(operators.length===1){
            if(operators[0]===">")
              filtersPlugin.addCondition(colIndex, "gt", [numbers[0]]);
            if(operators[0]==="<")
              filtersPlugin.addCondition(colIndex, "lt", [numbers[0]]);
            if(operators[0]==="!"){
              console.log(event.target.value)
              let trunc_str = event.target.value.replace("!", "")
              filtersPlugin.addCondition(colIndex, 'not_contains', [trunc_str]);
            }
              
          }
          else if (operators.length>1){
            if(operators[0]===">"){
              filtersPlugin.addCondition(colIndex, "gt", [numbers[0]]);
              filtersPlugin.addCondition(colIndex, "lt", [numbers[1]]);
            }
            else{
              filtersPlugin.addCondition(colIndex, "gt", [numbers[1]]);
              filtersPlugin.addCondition(colIndex, "lt", [numbers[0]]);
            }
          }
          else
            filtersPlugin.addCondition(colIndex, 'contains', [event.target.value]);
        }
      
        filtersPlugin.filter();
      }, 200);

      var addEventListeners = function (input, colIndex) {
        input.addEventListener('keydown', function(event) {
          debounceFn(colIndex, event);
        });
      };
      
      // Build elements which will be displayed in header.
      var getInitializedElements = function(colIndex) {
        var div = document.createElement('div');
        var input = document.createElement('input');
      
        div.className = 'filterHeader';
      
        addEventListeners(input, colIndex);
      
        div.appendChild(input);
      
        return div;
      };

      var addInput = function(col, TH) {
        // Hooks can return value other than number (for example `columnSorting` plugin use this).
        if (typeof col !== 'number') {
          return col;
        }
      
        if (col >= 0 && TH.childElementCount < 2) {
          TH.appendChild(getInitializedElements(col));
        }
      };
      
      // Deselect column after click on input.
      var doNotSelectColumn = function (event, coords) {
        if (coords.row === -1 && event.target.nodeName === 'INPUT') {
          event.stopImmediatePropagation();
          this.deselectCell();
        }
      };

      var hot = new Handsontable(document.getElementById('example3'), {
        licenseKey:"non-commercial-and-evaluation",
        data: this.state.myData,
        rowHeaders: true,
        className: 'ht-test',
        filters: true,
        colWidths: [170, 120, 140, 150, 50, 150, 150, 80, 140],
        readOnly: true,
        
        columns:[
          {renderer: "html", className:"htCenter"},
          {type: "text"},
          {className: "htCenter",
          type: 'date',
          dateFormat : "DD/MM/YYYY",
          correctFormat: false},
          {type: "text",
          className: "htRight"},
          {type: "text",
          className: "htCenter"},
          {className: "htCenter",
          type: 'date',
          dateFormat : "DD/MM/YYYY",
          correctFormat: true},
          {className: "htCenter",
          type: 'date',
          dateFormat : "DD/MM/YYYY",
          correctFormat: true},
          {type: "text",
          className: "htCenter"},
          {type: "text",
          className: "htCenter"},
        ],
        colHeaders:["Sipariş No", "Firma", "Oluşturma Tarihi", "Genel Toplam", "Döviz", "Termin Tarihi", "Ödeme Tarihi", "İndirim(%)", "Durum"],
        columnSorting: {
          initialConfig: {
            column: 2,
            sortOrder: 'desc',
            indicator: true,
            headerAction: true
          }
        },
        afterGetColHeader: addInput,
        beforeOnCellMouseDown: doNotSelectColumn,
        outsideClickDeselects : this.handleDeselectOrders,
        afterSelection: this.handleSelectedSumOrders

        
        

      });
      
      hot.validateCells();
      this.setState({
        hot: hot
      })
    }
    else
    this.setState({
      redirect: "/login"
    })
    })
    .catch((error) => {
      console.log(error)
    })
  }
  dateFormatter =(date) => {
    return new Date(date).toLocaleDateString("en-GB")
  }
  handleFilterInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    }, this.filter)
  }
  deleteFilter = (e) => {
    this.setState({
      customerFilter: "",
      noFilter: "",
      pDateFilter: "",
      dDateFilter: "",
      cDateFilter: "",
      currency: "",
      price: ""
    }, this.filter)
  }
  handleRowClick = (e) => {
    console.log(e.target)
  }
  
  handleSelectedSumOrders = (e) => {
    try{
      let selectedInstance = this.state.hot
      let selected = selectedInstance.getSelected() || [];

      const data = [];
      let sum = 0;
      if(selected.length != 0){

        for (let i = 0; i < selected.length; i += 1) {
          const item = selected[i];
          data.push(selectedInstance.getData(...item));
        }


        for(let j = 0; j<data.length; j++)
        {
          for(let i = 0; i<data[j].length; i++)
          {
            sum += parseInt(data[j][i])
          }
        }
      }
      this.setState({
        selectedSumOrders: sum
      })
    }
    catch(error){
      console.log(error)
    }
    
  }

  handleDeselectOrders = (e) => {
    this.setState({
      selectedSumOrders: 0
    })
    return true
  }

  
  render(){
    if(this.state.redirect){
      return <Redirect to={this.state.redirect}/>
    }
    //console.log(this.state.orders)
    return(
      
        <div style={{marginLeft:"0px"}}>
          <VitausLogo/>
          <LogoLinkSmall to="/orderexplorer" logo={<ReturnLogo height={20} width={20}/>} label=".."/>
          <div style = {{marginTop: "25px"}}className={this.classes.detay}>
                  <div style={{width:"50%"}}>
                    {/* {this.state.orderNotifs.map((value, index) => {
                      return <span className={this.classes.test} role="textbox" name="detail" id="detail" placeholder="">{value}</span>
                    })} */}
                    <Select
                      closeMenuOnSelect={false}
                      isClearable={false}
                      components={animatedComponents}
                      styles={customStyles}
                      options = {this.state.orderNotifs}
                      placeholder='Bildirimler'
                      defaultValue={this.state.orderNotifs}
                      
                      // value = {this.state.orderNotifs}
                  />
                    

                  </div>
          </div>
          <div className={this.classes.tableContainer}>
            <div style={{marginTop:"10px"}} id="example3"></div>
          </div>
         
          <label style = {{bottom:0, right:15, position:"fixed"}}><b>Toplam</b>
            <input id="selectedSum" name="selectedSum" type="number" value={this.state.selectedSumOrders} style = {{marginLeft:5, width:"100px"}} readOnly="{true}"/>
          </label>
        </div>

      // <div className={this.classes.eksik}>
      // <h5>Sipariş Listesi</h5>
      // <HotTable
      //   licenseKey={"non-commercial-and-evaluation"}
      //   startCols={2}
      //   ref={this.ref}
      //   dropdownMenu={true}
      //   filters={true}
      //   rowHeights = "22"
      //   columns={[
      //     {renderer: "html",
      //     },
      //     {type: "text"},
      //     {type: "text"},
      //     {type: "text"},
      //     {type: "text"},
      //     {type: "text"},
      //     {type: "text"},
      //     {type: "text"}
      //   ]}
      //   colHeaders={["", "Firma", "Sipariş No", "Oluşturma Tarihi", "Genel Toplam", "Döviz", "Termin Tarihi", "Ödeme Tarihi"]}
      //   data={this.state.myData}
      //   readOnly={true}
      //   colWidths={[120,120,120,120,120,120,120,120]}
      // />
      // </div>
        
         
 


     
      // <div>
      //   <div className={this.classes.tableContainer}>
      //     <table>
      //       <caption>
      //         <div>
      //           <LogoLinkSmall to="/orderexplorer" logo={<ReturnLogo height={20} width={20}/>} label=".."/>
      //         </div>
      //         <div>
      //           <h3>Siparişler</h3>
      //         </div>
      //       </caption>
      //       <thead>
      //         <tr>
      //           <td>
      //             <img src="https://img.icons8.com/wired/64/000000/delete-forever.png" alt="back" height="23px" width="23px" onClick={this.deleteFilter}/>
      //           </td>
      //           <td>
      //             <input type="text" name="customerFilter" onChange={this.handleFilterInput} value={this.state.customerFilter}/>
      //           </td>
      //           <td>
      //             <input type="number" name="noFilter" onChange={this.handleFilterInput} value={this.state.noFilter}/>
      //           </td>
      //           <td>
      //             <input type="text" name="cDateFilter" onChange={this.handleFilterInput} value={this.state.cDateFilter}/>
      //           </td>
      //           <td>
      //             <input type="text" name="price" onChange={this.handleFilterInput} value={this.state.price}/>
      //           </td>
      //           <td>
      //             <input type="text" name="currency" onChange={this.handleFilterInput} value={this.state.currency}/>
      //           </td>
      //           <td>
      //             <input type="text" name="dDateFilter" onChange={this.handleFilterInput} value={this.state.dDateFilter}/>
      //           </td>
      //           <td>
      //             <input type="text" name = "pDateFilter" onChange={this.handleFilterInput} value={this.state.pDateFilter}/>
      //           </td>
      //         </tr>
      //         <tr>
      //           <th>&nbsp;</th>
      //           <th>Firma</th>
      //           <th>Sipariş No.</th>
      //           <th>Oluşturma Tarihi</th>
      //           <th>Genel Toplam</th>
      //           <th>Döviz</th>
      //           <th>Termin Tarihi</th>
      //           <th>Ödeme Tarihi</th>
      //         </tr>
      //       </thead>
      //       <tbody>
      //         {this.state.displayedOrders ? this.state.displayedOrders.map(order => {
      //           console.log(this.state)
      //           return(
      //             <tr key={order.orderNo}>
      //               <td><Link to={"/orders/"+ order.id}>Düzenle</Link></td>
      //               <td>{order.customer.customerName}</td>
      //               <td>{order.orderNo}</td>
      //               <td>{this.dateFormatter(order.creationDate)}</td>
      //               <td>{order.price}</td>
      //               <td>{order.currency}</td>
      //               <td>{this.dateFormatter(order.deadline)}</td>
      //               <td>{this.dateFormatter(order.paymentDate)}</td>
      //             </tr>
      //           )
      //         }) : null}
      //     </tbody>
      //   </table>
      //   </div>
        
        
      // </div>
      
    )
  }
}

export default withStyles(styles)(OrderPage);
