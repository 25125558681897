import React from 'react';
import "typeface-roboto";
import {BrowserRouter, Route, Switch} from "react-router-dom"
import OrderPage from "./components/order/OrderPage"
import CampaignPage from "./components/campaign/CampaignPage";
import MainExplorer from "./components/MainExplorer";
import ReportExplorer from "./components/ReportExplorer";
import StockExplorer from "./components/StockExplorer";
import OrderExplorer from "./components/OrderExplorer";
import CampaignExplorer from "./components/CampaignExplorer";
import StockPage from "./components/stock/StockPage";
import LoginPage from "./components/auth/LoginPage";
import OrderDetail from "./components/order/OrderDetail";
import CampaignDetail from "./components/campaign/CampaignDetail";
import CreateOrder from "./components/order/CreateOrder";
import CreateCampaign from "./components/campaign/CreateCampaign"
import PrepareOrder from "./components/order/PrepareOrder"
import "./index.css"
import CustomerExplorer from "./components/customer/CustomerExplorer"
import CustomerDetail from "./components/customer/CustomerDetail"
import CreateCustomer from "./components/customer/CreateCustomer"
import CustomerPage from "./components/customer/CustomerPage"
import ProductExplorer from "./components/product/ProductExplorer"
import ProductDetail from "./components/product/ProductDetail"
import CreateProduct from "./components/product/CreateProduct"
import FetchProductData from "./components/product/FetchProductData"
import BestSeller from "./components/report/BestSeller"
import ProductPage from "./components/product/ProductPage"
import PrivateRoute from './components/auth/PrivateRoute';
import MonthlyRevenue from './components/report/MonthlyRevenue';
import MonthlyProduct from './components/report/MonthlyProduct';
import YearlyCategories from './components/report/YearlyCategories';


class App extends React.Component{
  state = {
    
  }
  handleLogin = () => {
    alert()
  }
  render(){
    return (
      <BrowserRouter>
        <div className="App">
          {/* <Navbar/> */}
          <div className="content">
            <Switch>
              <PrivateRoute exact path="/" component={MainExplorer}/>
              <PrivateRoute path="/orders/:id" component={OrderDetail}/>
              <PrivateRoute path="/orders" component={OrderPage}/>
              <PrivateRoute path="/createOrder" component={CreateOrder}/>
              <PrivateRoute path="/campaigns/:id" components={CampaignDetail} />
              <PrivateRoute path="/campaigns" component={CampaignPage}/>
              <PrivateRoute path="/createcampaign" component={CreateCampaign}/>
              <PrivateRoute path="/stockmanage" component={StockPage}/>
              <Route path="/login" component={LoginPage}/>
              <PrivateRoute path="/orderexplorer" component={OrderExplorer}/>
              <PrivateRoute path="/campaignexplorer" component={CampaignExplorer}/>
              <PrivateRoute path="/stockexplorer" component={StockExplorer}/>
              <PrivateRoute path="/reportexplorer" component={ReportExplorer}/>
              <PrivateRoute path="/customerexplorer" component={CustomerExplorer}/>
              <PrivateRoute path="/createCustomer" component={CreateCustomer}/>
              <PrivateRoute path="/customers/:id" component={CustomerDetail}/>
              <PrivateRoute path="/customers" component={CustomerPage}/>
              <PrivateRoute path="/productexplorer" component={ProductExplorer}/>
              <PrivateRoute path="/createProduct" component={CreateProduct}/>
              <PrivateRoute path="/fetchproductdata" component={FetchProductData}/>
              <PrivateRoute path="/products/:id" component={ProductDetail}/>
              <PrivateRoute path="/products" component={ProductPage}/>
              <PrivateRoute path="/prepare/:id" component={PrepareOrder}/>
              <PrivateRoute path="/reports/bestseller" component={BestSeller}/>    
              <PrivateRoute path="/reports/monthlyrevenue" component={MonthlyRevenue}/>         
              <PrivateRoute path="/reports/monthlyproduct" component={MonthlyProduct}/>       
              <PrivateRoute path="/reports/yearlycategories" component={YearlyCategories}/>   
            </Switch>
          </div>
        </div> 
      </BrowserRouter>
    );
  }
}

export default App;