import React from "react";
import {ReactComponent as ReturnLogo} from "../assets/icon/return.svg";
import {ReactComponent as ReportLogo} from "../assets/icon/report.svg";
import LogoLink from "./LogoLink";

export default function OrderExplorer()
{
  return(
    <div>
      <div>
        <LogoLink to="/" logo={<ReturnLogo height={45} width={45}/>} label=".."/>
        <LogoLink to="/createOrder" logo={<ReportLogo height={45} width={45}/>} label="Sipariş Raporu"/>
        <LogoLink to="/reports/monthlyrevenue" logo={<ReportLogo height={45} width={45}/>} label="Aylık Satış Raporu"/>
        <LogoLink to="/reports/bestseller" logo={<ReportLogo height={45} width={45}/>} label="Ürün Kalem Satış Raporu"/>
        <LogoLink to="/reports/monthlyproduct" logo={<ReportLogo height={45} width={45}/>} label="Kategori Satış Raporu"/>
        <LogoLink to="/reports/yearlycategories" logo={<ReportLogo height={45} width={45}/>} label="Kategori Yıllık Satış Raporu"/>
      </div>
    </div>
  )
}

