import Handsontable from "handsontable";
let C = Handsontable.languages.dictionaryKeys;
let tr = {
  languageCode: "tr-TR",
  [C.CONTEXTMENU_ITEMS_ROW_ABOVE]: "Yukarı satır ekle",
  [C.CONTEXTMENU_ITEMS_ROW_BELOW]: "Aşağı satır ekle",
  [C.CONTEXTMENU_ITEMS_INSERT_LEFT]: "Sola sütun ekle",
  [C.CONTEXTMENU_ITEMS_INSERT_RIGHT]: "Sağa sütun ekle",
  [C.CONTEXTMENU_ITEMS_REMOVE_ROW]: ["Satır sil", "Satırları sil"],
  [C.CONTEXTMENU_ITEMS_REMOVE_COLUMN]: ["Sütun sil", "Sütunları sil"],
  [C.CONTEXTMENU_ITEMS_UNDO]: "Geri al",
  [C.CONTEXTMENU_ITEMS_REDO]: "İleri al",
  [C.CONTEXTMENU_ITEMS_READ_ONLY]: "Salt okunur",
  [C.CONTEXTMENU_ITEMS_CLEAR_COLUMN]: "Sütunu temizle",
  [C.CONTEXTMENU_ITEMS_ALIGNMENT]: "Yatay hiza",
  [C.CONTEXTMENU_ITEMS_ALIGNMENT_LEFT]: "Sol",
  [C.CONTEXTMENU_ITEMS_ALIGNMENT_CENTER]: "Orta",
  [C.CONTEXTMENU_ITEMS_ALIGNMENT_RIGHT]: "Sağ",
  [C.CONTEXTMENU_ITEMS_ALIGNMENT_JUSTIFY]: "Dikey hiza",
  [C.CONTEXTMENU_ITEMS_ALIGNMENT_TOP]: "Üst",
  [C.CONTEXTMENU_ITEMS_ALIGNMENT_MIDDLE]: "Orta",
  [C.CONTEXTMENU_ITEMS_ALIGNMENT_BOTTOM]: "Alt",
  [C.CONTEXTMENU_ITEMS_FREEZE_COLUMN]: "Sütunu dondur",
  [C.CONTEXTMENU_ITEMS_COPY]: "Kopyala",
  [C.CONTEXTMENU_ITEMS_CUT]: "Kes",
  [C.FILTERS_CONDITIONS_NONE]: "Hiçbiri",
  [C.FILTERS_CONDITIONS_EMPTY]: "Boş",
  [C.FILTERS_CONDITIONS_NOT_EMPTY]: "Boş değil",
  [C.FILTERS_CONDITIONS_EQUAL]: "Eşittir",
  [C.FILTERS_CONDITIONS_NOT_EQUAL]: "Eşit değildir",
  [C.FILTERS_CONDITIONS_BEGINS_WITH]: "İle başlar",
  [C.FILTERS_CONDITIONS_ENDS_WITH]: "İle biter",
  [C.FILTERS_CONDITIONS_CONTAINS]: "İçerir",
  [C.FILTERS_CONDITIONS_NOT_CONTAIN]: "İçermez",
  [C.FILTERS_CONDITIONS_GREATER_THAN]: "Büyüktür",
  [C.FILTERS_CONDITIONS_GREATER_THAN_OR_EQUAL]: "Büyük eşittir",
  [C.FILTERS_CONDITIONS_LESS_THAN]: "Küçüktür",
  [C.FILTERS_CONDITIONS_LESS_THAN_OR_EQUAL]: "Küçük eşittir",
  [C.FILTERS_CONDITIONS_BETWEEN]: "Aralıkta",
  [C.FILTERS_CONDITIONS_NOT_BETWEEN]: "Aralıkta değil",
  [C.FILTERS_CONDITIONS_AFTER]: "Sonra",
  [C.FILTERS_CONDITIONS_BEFORE]: "Önce",
  [C.FILTERS_CONDITIONS_TODAY]: "Bugün",
  [C.FILTERS_CONDITIONS_TOMORROW]: "Yarın",
  [C.FILTERS_CONDITIONS_YESTERDAY]: "Dün",
  [C.FILTERS_VALUES_BLANK_CELLS]: "Boş hücreler",
  [C.FILTERS_DIVS_FILTER_BY_CONDITION]: "Duruma göre filtrele",
  [C.FILTERS_DIVS_FILTER_BY_VALUE]: "Değere göre filtrele",
  [C.FILTERS_LABELS_CONJUNCTION]: "Ve",
  [C.FILTERS_LABELS_DISJUNCTION]: "Veya",
  [C.FILTERS_BUTTONS_SELECT_ALL]: "Hepsini seç",
  [C.FILTERS_BUTTONS_CLEAR]: "Temizle",
  [C.FILTERS_BUTTONS_OK]: "Tamam",
  [C.FILTERS_BUTTONS_CANCEL]: "İptal",
  [C.FILTERS_BUTTONS_PLACEHOLDER_SEARCH]: "Ara",
  [C.FILTERS_BUTTONS_PLACEHOLDER_VALUE]: "Değer",
  [C.FILTERS_BUTTONS_PLACEHOLDER_SECOND_VALUE]: "İkinci değer"
  



}
export default tr;