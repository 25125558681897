import React from "react";

import axios from "axios";
import LogoLinkSmall from "../LogoLinkSmall";
import VitausLogo from "../VitausLogo"
import {ReactComponent as ReturnLogo} from "../../assets/icon/return.svg";
import ip from "../../config/backend";
import "handsontable/dist/handsontable.full.css";
import { HotTable, HotColumn } from "@handsontable/react";
import Handsontable from "handsontable";
import tr from "../../config/dict";
import {ColumnChart, StackedColumnChart} from 'bizcharts';
import GraphButton from "./GraphButton";
import {withStyles } from '@material-ui/core/styles';

// import Datetime from 'react-datetime';

const styles = {
    "tablo": {
        float: "left",
        width: "100%",
        marginLeft:"20px",
      },
      "chart":{
        width: "100%",
        // overflow: "auto",
        // background: "red",
        // padding: "2px"
        float: "right",
        overflow: "visible",
      },
      "check": {
        float: "right",
        width: "100%",
        overflow: "visible",
      },
      top8:{
          marginTop:"15px",
      }
}


class BestSeller extends React.Component{
    ref = React.createRef();
    state = {
        data: [],
        top8: [],
        selectedStockCode: "",
        productGraph: [],
        hotSettings: {
            rowHeaders: true,
            rowHeights: 25,
            colHeaders: ["Stok Kodu", "Toplam", ""],
            licenseKey: "non-commercial-and-evaluation"
          },
          darkMode: false
        }

    changeProductGraph =(pGraph) =>{
        //   window.location = value
        let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
        let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

        let authData = {
        authKey:  authKey,
        username: username,
        expirationTime: ""
        }

        let postObject = {"stockCode" : pGraph, "sessionData": authData}

        axios.post(ip + "api/report/productSellGraph", postObject ).then(response => {
            if(response.data !== "Authorization Error"){
                let data = JSON.parse(response.data);

                data.forEach(element => 
                    element.TotalSold = parseInt(element.TotalSold)
                );
                data.forEach(element => {
                    // element.Date = element.Date.substring(0,10) + " | " + element.OrderNo
                    element.Date = element.Date.substring(0,10)
                    }  
                );

                this.setState({
                    productGraph: data,
                    selectedStockCode : pGraph
                })
            }
        }).catch(error => {
        console.log(error)
        })
    }
    
    componentDidMount(){

        Handsontable.languages.registerLanguageDictionary(tr)
        let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
        let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

        let authData = {
        authKey:  authKey,
        username: username,
        expirationTime: ""
        }
        
        axios.post(ip + "api/report/topsoldproduct", authData).then(response => {
            if(response.data !== "Authorization Error"){
                let data = JSON.parse(response.data);
                let top8 = data.slice(1,8);
                top8.forEach(element => 
                    element.TotalSold = parseInt(element.TotalSold),
                    );

                this.setState({
                    top8:top8,
                    data: data,
                    selectedStockCode : top8[0].StockCode
                })

                this.changeProductGraph(this.state.selectedStockCode)
                
            }
            
          }).catch(error => {
            console.log(error)
        })

    }
    classes = this.props.classes;
    

    

    render(){
    
        return(
            <div id="hot-app" >
                <VitausLogo/>
                <LogoLinkSmall to="/reportexplorer" logo={<ReturnLogo height={20} width={20}/>} label=".."/>

                <div className= {this.classes.top8}>
                    <ColumnChart
                                    data={this.state.top8}
                                    title={{
                                        visible: true,
                                    }}
                                    height={450}
                                    // highlight-end
                                    xField="StockCode"
                                    yField="TotalSold"
                                    colorField="StockCode"
                    />
                </div>

                <div id="table-and-chart" style={{width:"40%", height:500, marginTop:20, float:"left", overflow:"hidden"}}>
                    <div className= {this.classes.tablo}>
                        <HotTable settings={this.state.hotSettings} data={this.state.data.map(element => {
                            return [
                                element.StockCode,
                                element.TotalSold,
                                element.StockCode
                            ]
                        })}>
                            <HotColumn width={200} type={"text"}></HotColumn>
                            <HotColumn width={80} type={"text"}></HotColumn>
                            <HotColumn width={80} type={"text"}>
                                <GraphButton onClick={this.changeProductGraph} hot-renderer ></GraphButton>
                            </HotColumn>
                        </HotTable>
                    </div>
                </div>
                <div id="table-and-chart" style={{width:"60%", height:460, marginTop:20, float:"right"}}>
                    <div className={this.classes.check}>
                        <div className= {this.classes.chart}> 
                            <div >
                                <center>
                                    <b>
                                        {this.state.selectedStockCode}
                                    </b>
                                </center>
                            </div>
                            <StackedColumnChart
                                data={this.state.productGraph}
                                title={{
                                    visible: true,
                                }}
                                height={460}
                                // highlight-end
                                xField="Date"
                                yField="TotalSold"
                                colorField="OrderNo"
                            />
                        </div>
                    </div>
                </div>
                
            {/* <PieChart
                data={this.state.top8}
                title={{
                    visible: true,
                    text: 'Ay Bazlı Ciro Tablosu',
                }}
                description={{
                    visible: true,
                    text: '',
                }}
                radius={1}
                angleField='TotalSold'
                colorField='StockCode'
                label={{
                    visible: true,
                    type: 'spider',
                    labelHeight: 28,
                    content: v => `${v.StockCode}\n${v.TotalSold}`
                }}
                events={{
                    onPieClick: (event) => console.log("selam"),
                    onLegendClick: (event) => console.log(event),
                    onPlotClick: (event) => console.log(event),
                }}
            /> */}
            </div>
           
        )
    }
}

export default withStyles(styles)(BestSeller)
