import React from "react";
import CampaignList from "./CampaignList";

class CampaignPage extends React.Component{
  state = {

  }
  render(){
    return(
      <div>
        <h5>
        Campaigns
        </h5>
        <CampaignList/>
      </div>
    )
  }
}

export default CampaignPage;