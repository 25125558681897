import React from "react";

const CampaignDetail = (props) => {
  const id = props.match.params.id;
  return(
    <div>
      Campaign {id} Detail
    </div>
  )
}

export default CampaignDetail;