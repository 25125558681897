import React, {Component} from "react";
import $ from "jquery";
import DatePicker from "react-datepicker";
import {Redirect} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css"
import {registerLocale, setDefaultLocale} from "react-datepicker";
import tr from "date-fns/locale/tr";
import dayjs from "dayjs";
import axios from "axios"
import {withStyles } from '@material-ui/core/styles';
import {ReactComponent as ReturnLogo} from "../../assets/icon/return.svg";
import LogoLinkSmall from "../../components/LogoLinkSmall";
import VitausLogo from "../VitausLogo"
import ip from "../../config/backend";

const styles = {
  "root": {
    '& form': {

      padding: "5px"
    },
    '& input[type="file"]':{
      display: "none"
    },
    '& > div': {
      border: "1px solid #ccc"
    },
    '& h5,h4,h3': {
      padding: "0",
      margin: "0"
    }
  },
  "fs": {
    background: "red",
    '& > div': {
      display: "inline-block",
      marginRight: "5px"
    }
  },
  "slip": {
    width: "38%",
    border: "1px solid #ccc",
    float: "left",
    height: "260px",
    marginTop: "25px",
    '& > div': {
      padding: "5px",
      width: "auto"
    },
    '& > input': {
      width: "40px"
    }
  },
  slipleft: {
    width: "45%",
    float: "left",
  },
  slipright: {
    textAlign: "right",
    width: "45%",
    float: "right",
    '& > div': {
      float: "right"
    }
  },
  "customer": {
    border: "1px solid #ccc",
    width: "30%",
    float: "left",
    height: "260px",
    marginTop: "25px",
    '& > div': {
      padding: "5px",
      width: "auto"
    },
  },
  "ek": {
    border: "1px solid #ccc",
    float: "left",
    width: "30%",
    height: "260px",
    marginTop: "25px",
    '& > div': {
      padding: "5px",
      width: "auto"
    }
  },
  "upload": {
    border: "1px solid #ccc",
    display: "inline-block",
    padding: "3px 12px",
    cursor: "pointer"
  },
  "fileNames": {
    background: "#cccccc",
    padding: "4px",
    marginTop: "5px",
    width: "90%",
    height: "60px",
    overflowY: "scroll",
    '& > div': {
      overflowX: "hidden",
      background: "#a1a1a1",
      wordWrap: "normal",
      padding: "2px",
      marginTop: "2px"
    }

  },
  "submitButton": {
  }
}

class CreateOrder extends Component{
  classes = this.props.classes;
  state = {
    creationDate: null,
    currency: "$",
    deadline: null,
    detail: "",
    firm: null,
    no: null,
    paymentDate: null,
    price: 0,
    state: null,
    previewFile: null,
    status: null,
    missingError: "",
    discountRate: 0,
  }
  populateDropdown = (dropdownId, optionObject) => {
    Object.keys(optionObject).forEach(key => {
      $(dropdownId).append("<option value=" + key+ ">" + key+ "</option>")
    })
  }

  handleFirmChange = (e) => {
      //populate dates based on firm objects
      if(e.target.value){
        let deadline, payment;
        deadline = this.customers[e.target.value].shipmentDeadline;
        payment = this.customers[e.target.value].paymentDeadline;

        this.setState({
          firm: e.target.value,
          deadline: dayjs(this.state.creationDate).add(deadline, "day")["$d"],
          paymentDate: dayjs(this.state.creationDate).add(payment, "day")["$d"],
        })
      }
  }

  componentDidMount(){
    let authData = ""
    let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
    let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

    authData = {
      authKey:  authKey,
      username: username,
      expirationTime: ""
    }
    
    axios.get(ip + "api/customer").then((response) => {
      console.log(response)
      this.customers = {};
      //populate customers
      
      JSON.parse(response.data).forEach(customer => {
        $("#firm").append("<option value=" + customer.id + ">" + customer.customerName + "</option>");
        this.customers[customer.id] = customer;
      })
    })
    axios.get(ip + "api/orderstate").then((response) => {
      this.orderStates = {};
      JSON.parse(response.data).forEach(state => {
        $("#state").append("<option value=" + state.id + ">" + state.name + "</option>");
        this.orderStates[state.id] = state;
      })
    })
    registerLocale("tr", tr);
    setDefaultLocale(tr);
    this.setState({
      creationDate: new Date()
    })

  }
  handleChange = (e) => {
    if(e.target.id == "firm"){
      this.handleFirmChange(e);
    }
    else{
      this.setState({
        [e.target.id]: e.target.value
      })
    }
  }

  validated = () => {
    if(
      this.state.firm &&
      this.state.no &&
      this.state.creationDate &&
      this.state.currency &&
      this.state.deadline &&
      this.state.paymentDate &&
      this.state.state
    )
    return true;
    else{
      this.setState({
        missingError: "Lütfen müşteri, sipariş no., tarih, döviz, durum alanlarını doldurunuz."
      })
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let postObject = {
      firmId: this.state.firm,
      creationDate: this.state.creationDate,
      currency: this.state.currency,
      deadline: this.state.deadline,
      detail: this.state.detail,
      orderNo: this.state.no,
      paymentDate: this.state.paymentDate,
      price: parseFloat(this.state.price),
      orderStateId: this.state.state,
      fileId: 0,
      discountRate: this.state.discountRate
      
    }

    let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
    let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

    let authData = {
      authKey:  authKey,
      username: username,
      expirationTime: ""
    }

    console.log(postObject)
    if(this.validated()){
      axios.post(ip + "api/createorder", {"order":postObject , "sessionData" : authData}).then((response) => {
        if(response.data != "Login Error"){
          let res = JSON.parse(response.data);
          this.setState({
            id: res.id
          })
        }
        else
        this.setState({
          redirect : "/login"
        })
        
        
      }).catch(error => {
        console.log(error)
      })
    }
  }

  handleCreationDateChange = date => {
    this.setState({
      creationDate: date,
      deadline: dayjs(date).add(this.customers[this.state.firm].shipmentDeadline, "day")["$d"],
      paymentDate: dayjs(date).add(this.customers[this.state.firm].paymentDeadline, "day")["$d"]
    })
  }

  handleDeadlineDateChange = date => {
    this.setState({
      deadline: date,
    })
  }

  handlePaymentDateChange = date => {
    this.setState({
      paymentDate: date,
    })
  }

  handlePreviewChange = e => {
    console.log(e.target["id"])
  }


  render(){
    if(this.state.id){
      return <Redirect to={"/orders/" + this.state.id}/>
    }
    if(this.state.redirect){
      return <Redirect to={this.state.redirect}/>
    }
    return(
      <div className={this.classes.root}>
        <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
        <VitausLogo/>
        <LogoLinkSmall to="/orderexplorer" logo={<ReturnLogo height={20} width={20}/>} label=".."/>
          <div className={this.classes.firstRow}>
            <div className={this.classes.slip}>
              <h4>Sipariş Bilgileri</h4>
              <div>
                <div className={this.classes.slipleft}>
                  <div>
                    <div>
                      <label htmlFor="no">Sipariş Numarası</label>
                    </div>
                    <div>
                      <input type="text" name="no" id="no" width="40"/>
                    </div>
                  </div>
                  <div className="datepicker">
                  <div> Oluşturulma Tarihi</div> 
                    <DatePicker selected={this.state.creationDate} onChange={this.handleCreationDateChange}/>
                  </div>
                  <div className="datepicker">
                    <div>Termin Tarihi</div>
                    <DatePicker selected={this.state.deadline} onChange={this.handleDeadlineDateChange}/>
                  </div>
                  <div className="datepicker">
                    <div>Ödeme Tarihi</div>
                    <DatePicker selected={this.state.paymentDate} onChange={this.handlePaymentDateChange}/>
                  </div>
                </div>
                <div className={this.classes.slipright}>
                  <div>
                    <div>
                      <label htmlFor="state">Sipariş Durumu</label>
                    </div>
                    <div>
                      <select name="state" id="state">
                        <option value="" defaultValue>-----</option>
                      </select>
                    </div>             
                  </div>
                  <div>
                    <div>
                      <label htmlFor="price">Tutar</label>
                    </div>
                    <div>
                      <input type="number" step="0.01" name="price" id="price" defaultValue={0}/>
                    </div>
                  </div>
                  {/* <div>
                    <div>
                      <label htmlFor="currency">Döviz</label>
                    </div>
                    <div>
                      <select name="currency" id="currency">
                        <option value="$">Dolar</option>
                        <option value="€">Euro</option>
                        <option value="₺">Lira</option>
                      </select>
                    </div>
                  </div> */}
                  <div style= {{marginTop:"2px"}}>
                    <div style={{float:"right", width:"50%",  }}>
                      <div>
                        <label htmlFor="currency">Döviz</label>
                      </div>
                      <div>
                        <select name="currency" id="currency">
                          <option value="$">Dolar</option>
                          <option value="€">Euro</option>
                          <option value="₺">Lira</option>
                        </select>
                      </div>
                    </div>
                    <div style={{float:"right", width:"35%"}}>
                      <div>
                        <label htmlFor="discount-rate">İndirim (%)</label>
                      </div>
                      <div>
                        <input type="number" step="0.01" onChange={this.handleChange} name="discountRate" id="discountRate" style={{width:"100%"}} defaultValue={0}/>
                      </div>
                    </div>
                    
                    
                  </div>

                  <div>
                    <div>
                      <label htmlFor="grandTotal">Genel Tutar</label>
                    </div>
                    <div>
                      <input type="number" step="0.01" value={this.state.price * (1-(this.state.discountRate/100))} name="grandTotal" id="grandTotal" readOnly="true"/>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className={this.classes.customer}>
              <h4>Müşteri Bilgileri</h4>
              <div>
                <div>
                  <label htmlFor="firm">Firma</label>
                </div>
                <div>
                  <select name="firm" id="firm" onChange={this.handleChange}>
                    <option value={this.state.firm} defaultValue >-----</option>
                  </select>
                </div>
              </div>
              <div className={this.classes.customerLeft}>
                <div>
                  Adres: {(this.state.firm ? this.customers[this.state.firm].customerAddress : null)}
                </div>
                <div>
                  İletişim: {(this.state.firm ? this.customers[this.state.firm].contactPerson : null)}
                </div>
                <div>
                  Email: {(this.state.firm ? this.customers[this.state.firm].contactEmail : null)}
                </div>
                <div>
                  Vat No.: {(this.state.firm ? this.customers[this.state.firm].vatNumber : null)}
                </div>
                <div>
                  Vat Ofis.: {(this.state.firm ? this.customers[this.state.firm].vatOffice : null)}
                </div>
              </div>
              <div className={this.classes.customerRight}>

              </div>
            </div>
            <div className={this.classes.ek}>
              <h4>Ekler</h4>
              <div className={this.classes.detay}>
                <div>
                  <div>
                    <label htmlFor="detail">Detay</label>
                  </div>
                  <textarea type="text" name="detail" id="detail" placeholder="" rows="4" cols="30"/>
                </div>
              </div>
              <div className={this.classes.filecontainer}>
              </div>
            </div>
          </div>
          <button className={this.classes.submitButton}>Oluştur</button>
          {this.state.missingError ? (<div>{this.state.missingError}</div>): null}
        </form>
      </div>   
    )
  }
}


export default withStyles(styles)(CreateOrder);
