import React from "react";
import {ReactComponent as ReturnLogo} from "../assets/icon/return.svg";
import {ReactComponent as ListLogo} from "../assets/icon/list.svg"
import {ReactComponent as ReportLogo} from "../assets/icon/report.svg";
import {ReactComponent as AddLogo} from "../assets/icon/add.svg";
import LogoLink from "./LogoLink";

export default function OrderExplorer()
{
  return(
    <div>
      <div>
        <LogoLink to="/" logo={<ReturnLogo height={45} width={45}/>} label=".."/>
        <LogoLink to="/stockmanage" logo={<AddLogo height={45} width={45}/>} label="Stok Yönet"/>
      </div>
    </div>
  )
}

