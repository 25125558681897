import React from "react";
import {withStyles } from '@material-ui/core/styles';
import ip from "../../config/backend"
import axios from "axios"
import $ from "jquery"
import "handsontable/dist/handsontable.full.css";
import { HotTable } from "@handsontable/react";
import arrayMove from "array-move";
import {Redirect} from "react-router-dom";

import Handsontable from "handsontable";
import tr from "../../config/dict";
import LogoLinkSmall from "../LogoLinkSmall";
import VitausLogo from "../VitausLogo"
import  {ReactComponent as ReturnLogo} from "../../assets/icon/return.svg"
// import { Hidden } from "@material-ui/core";
// import { auth } from "firebase";
// import shadows from "@material-ui/core/styles/shadows";
import "../../spinner.css"
import "../../toggle.css"

var XLSX = require('xlsx');

const styles = {

  "root": {
    width: "100%",
    margin: "0 auto"
  },
  "excel": {
    float: "left",
    maxWidth: "50%",
    marginTop: "15px"
  },
  "check": {
    float: "right",
    
    //paddingTop: 150,
    
  },
  "eksikFixed":{
    maxHeight: "200px",
    float: "right",
    top:0,
    position: "fixed",
    left: "51.4%"
    
  },

  "eksikRelative":{
    maxHeight: "200px",
    float: "right"
  },


  "orderDetail": {
    clear: "both",
    // marginTop: "20px",
    float: "left"
    // margin: "40px auto",
    // width: "380px"
  },
  "prepareOrderRelative": {
    clear: "both",
    // marginTop: "20px",
    float: "left",
    
    // margin: "40px auto",
    // width: "380px"
  },
  "prepareOrderFixed": {
    height: "300px",
    clear: "both",

    float: "left",
    
    // margin: "40px auto",
    // width: "380px"
  },
  
  "smallInput":{
    "width": "35px",

  },
  "handsontable":{
    '& > th': {
      "vertical-align": "middle",
    }

  },

  productImage :{
    "width":"50px",
    "height": "50px",
    "margin": "0 auto"
  },

  logoContainer :{
    "width":"210px",
    "height" : "70px",
    "position": "absolute",
    "marginLeft" : "50px"
  },

  logo :{
    "width":"210px",
    "height" : "100%",
  },
  loadingStatus:{
    zIndex: "1000",
    padding:"5px",
    width:"250px",
    position: "fixed",
    bottom: "0px",
    left: "20px",
    color:"#73AD21",
    fontWeight:"600"
  }
}

class PrepareOrder extends React.Component{

  ref = React.createRef();
  ref2 = React.createRef(); 
  ref3 = React.createRef();
  ref4 = React.createRef();
  classes = this.props.classes;
  state = {
    error: {},
    addedRowCount: 5,
    orderNo: "",
    addProductStatus : true,
    checkListState : true,
    checkListStateText: "Pasif",
    selectedSumChecklist : 0,
    selectedSumEksik : 0,
    imageLinks : [],
    cekiListesiState: "",
    productDetailToggleStatus: "none",
    readAndFilteredBarcode: -1,
    stockCheckToggle : true,
    productDetailChanged  : "",
    eksikClassName : this.classes.eksikRelative,
    prepareOrderClassName : this.classes.prepareOrderRelative,
  }

  customRenderer(instance, td, row, col, prop, value, cellProperties){
    td.style.background = '#000000';
    td.style.color = 'white';
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    return td
  }

  hotSettings = {
    data: ["a1", "a2"],
    columns: [
      {type: "text",
      className: "htCenter htMiddle"
      },
      {type: "text",
      className: "htCenter htMiddle"},
      {
        type: "text",
        className: "htCenter htMiddle",
        renderer: function (instance, td, row, col, prop, value, cellProperties) {
          const escaped = Handsontable.helper.stringify(value);
          let img = null;
          td.className= "htCenter htMiddle"
          if (escaped < 0) {
            td.style.background = '#fc0303';
            td.style.color = 'white';
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            
          }
          else if (escaped >0) {
            td.style.background = '#2f00c9';
            td.style.color = 'white';
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            
          }
          else{
            td.style.background = '#00ff3c';
            td.style.color = 'white';
            Handsontable.renderers.TextRenderer.apply(this, arguments);
          }
          return td;
        }
      },
      {
        type: "text",
        className: "htCenter htMiddle",
        renderer: function (instance, td, row, col, prop, value, cellProperties) {
          const escaped = Handsontable.helper.stringify(value);
          let img = null;
          td.className= "htCenter htMiddle"
          td.style.background = '#E8E8E8';
          td.style.color = 'black';
          Handsontable.renderers.TextRenderer.apply(this, arguments);
          return td;
        }
      },
      {
        renderer: (instance, td, row, col, prop, value, cellProperties) => {
        td.className = "htMiddle"
        const img = document.createElement('IMG');
        const center = document.createElement("center");

        img.src = value.thumbnail;
        img.style.width = "60px";
        img.style.height = "60px";
        img.style.verticalAlign = "middle";
        img.style.margin = "2px";
        img.style.cursor ="pointer";
        img.setAttribute("align", "center");
        
        Handsontable.dom.addEvent(img, 'mousedown', event =>{
          // event.preventDefault(); // prevent selection quirk
          this.setState({
            zenitalImageDiv : value.zenital
          })

        });

        Handsontable.dom.empty(td);
        center.appendChild(img)
        td.appendChild(center);
       },
       className : "htCenter htMiddle"
       
     }
    ]
  }
  

  componentDidMount(){

    window.addEventListener('scroll', (event) => {
      
      if(parseInt(window.scrollY)>=212){
        this.setState({
          eksikClassName: this.classes.eksikFixed,
          prepareOrderClassName: this.classes.prepareOrderFixed
        })
      }

      else{
        this.setState({
          eksikClassName: this.classes.eksikRelative,
          prepareOrderClassName: this.classes.prepareOrderRelative
        })
      }
      

    })

    let instance =  this.ref.current.hotInstance;
    let instance2 = this.ref2.current.hotInstance;
    let instance3 = this.ref3.current.hotInstance;
    let instance4 = this.ref4.current.hotInstance;
    Handsontable.languages.registerLanguageDictionary(tr)
    this.setState({
      instance: instance,
      instance2: instance2,
      instance3: instance3,
      instance4: instance4,
      id: this.props.match.params.id,
      
    })


    let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
    let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

    let authData = {
      authKey:  authKey,
      username: username,
      expirationTime: ""
    }

    axios.get(ip + "api/package/" + this.props.match.params.id).then(response => {
      if(response.data !== "Authorization Error"){
        let gelenPackages= JSON.parse(response.data)

          this.setState({
            packageList : gelenPackages.map(pack => {
              return([pack.package, pack.size, pack.icerik])
            })
          })
          if(gelenPackages.length>0){
            if(gelenPackages.length*40 <200){
              this.setState({
                koliHeight : gelenPackages.length*40+50
              })
            }
            else{
              this.setState({
                koliHeight : 200
              })
            }
          }
          console.log(this.state.packageList)
        
      }
    })
    
    axios.get(ip + "api/preporderdif/" + this.props.match.params.id).then(response => {
      let gelenEksikListesi = JSON.parse(response.data);
      axios.post(ip + "api/order/" + this.props.match.params.id, authData).then((response2) => {
        
      let order = JSON.parse(response2.data);
      order = JSON.parse(order.order)
      this.setState({
        orderDetail: order.detail
      })

      let barcodeList = []
      let imageLinks = []
      gelenEksikListesi.forEach(function(item){
        barcodeList.push(item.barcode)
      });

      axios.post(ip + "api/productimage", barcodeList).then(response3 => {

        imageLinks = JSON.parse(response3.data)
        let thumbnails = JSON.parse(imageLinks.thumbnails)
        let zenitals = JSON.parse(imageLinks.zenitals)
        gelenEksikListesi.forEach(function(item, index){
          item.imageLinks = {"thumbnail" :thumbnails[index], "zenital": zenitals[index]};
        });

        if(gelenEksikListesi){
          this.setState({
            orderNo: order.orderNo,
            eksikListesi: gelenEksikListesi.map(prod => {
              return([prod.barcode, prod.stockCode, prod.difference, prod.orderedAmount, prod.imageLinks])
            })
          })
        } else if(!gelenEksikListesi && order){
          this.setState({
            redirect: "/orders/" + order.id
          })
        } else {
          this.setState({
            redirect: "/orders"
          })
        }
  
        function cellColorRenderer(instance, td, row, col, prop, value, cellProperties) {
          const escaped = Handsontable.helper.stringify(value);
          let img = null;
          td.className= "htCenter"
          if (escaped < 0) {
            td.style.background = '#fc0303';
            td.style.color = 'white';
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            
          }
          else if (escaped >0) {
            td.style.background = '#2f00c9';
            td.style.color = 'white';
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            
          }
          else{
            td.style.background = '#00ff3c';
            td.style.color = 'white';
            Handsontable.renderers.TextRenderer.apply(this, arguments);
          }
      
          return td;
        }
  
        
        
        Handsontable.renderers.registerRenderer('cellColorRenderer', cellColorRenderer);

      }).catch(error => {
        console.log(error)
      })

      

      

      

      }).catch(error2 => {
        console.log(error2)
      })
      
    }).catch(error => {
      console.log(error)
    })

    

    axios.get(ip + "api/prepareorder/" + this.props.match.params.id).then(response => {
      this.state.instance3.updateSettings({readOnly: true})
      let lst = JSON.parse(response.data);
      this.setState({
        prepareOrder: lst.map(element => {
          return([element.barcode, element.stockCode, element.amount, element.package])
        })
      })
    })
    
  }

  checkQueryString = (query) => {
    var operators=[];
    var numbers= [];
    var finalNumbers = [];
    var finalResult = [];
    for (var i = 0; i < query.length; i++) {
      if(query.charAt(i)==="<"){
        operators.push("<")
      }
      else if(query.charAt(i)===">"){
        operators.push(">")
      }   
    }
    var numbers = query.split(",")
    
    numbers.forEach(function(item){
      item = item.replace('>','');
      item = item.replace('<','');
      finalNumbers.push(item)
    });

    finalResult.push(operators);
    finalResult.push(finalNumbers);

    return(finalResult)
  }

  debounceFn = Handsontable.helper.debounce(function (colIndex, event, tableClass, source) {
   
    
    var filtersPlugin
    if(tableClass.includes("eksik-listesi"))
      filtersPlugin = this.state.instance2.getPlugin('filters');
    else if(tableClass.includes("ceki-listesi"))
      filtersPlugin = this.state.instance3.getPlugin('filters');
    else if(tableClass.includes("koli-listesi"))
      filtersPlugin = this.state.instance4.getPlugin('filters');
    var queryString = this.checkQueryString(event.target.value)

    var operators = queryString[0];
    var numbers = queryString[1];
    filtersPlugin.removeConditions(colIndex);

    if(operators.length===1){
      if(operators[0]===">")
        filtersPlugin.addCondition(colIndex, "gt", [numbers[0]]);
      if(operators[0]==="<")
        filtersPlugin.addCondition(colIndex, "lt", [numbers[0]]);
    }
    else if (operators.length>1){
      if(operators[0]===">"){
        filtersPlugin.addCondition(colIndex, "gt", [numbers[0]]);
        filtersPlugin.addCondition(colIndex, "lt", [numbers[1]]);
      }
      else{
        filtersPlugin.addCondition(colIndex, "gt", [numbers[1]]);
        filtersPlugin.addCondition(colIndex, "lt", [numbers[0]]);
      }
    }
    else
      filtersPlugin.addCondition(colIndex, 'contains', [event.target.value]);
  
  
    filtersPlugin.filter();
    if(source == "barcodeRead"){
      this.setState({
        readAndFilteredBarcode: ""
      })
    }

  }, 200);


  addEventListeners = (input, colIndex, tableClass) => {
    
    input.addEventListener('keydown', (event) => {
      this.debounceFn(colIndex, event, tableClass, "usual");
    });
    input.addEventListener('click', (event)=>{
      this.state.instance2.deselectCell()
      this.state.instance3.deselectCell()
    })
  };

  barcodeReader = (event, colIndex, tableClass)=>{
    if(this.state.stockCheckToggle == true){
      if(this.state.readAndFilteredBarcode !="" && this.state.readAndFilteredBarcode !=-1){
        if(this.state.readAndFilteredBarcode == -2)
          event.target.value=""
        else
          event.target.value= this.state.readAndFilteredBarcode
        this.debounceFn(colIndex, event, tableClass, "barcodeRead");
      }
    }
    
  }
  
  // Build elements which will be displayed in header.
  getInitializedElements = (colIndex, tableClass) => {
    var div = document.createElement('div');
    var input = document.createElement('input');
    if(colIndex ===0){
      input.size = "10"
      if(tableClass.includes("eksik-listesi")){
        console.log("eksiğe yaptik")
        input.name = "barcodeInput"
        input.onchange =(e)=> this.barcodeReader(e, colIndex, tableClass)
      }
      
    }
      
    else if(colIndex ===1)
      input.size = "20"
    else
      input.size = "1"

    if(tableClass.includes("koli-listesi")){
      input.size="5"
    }

    
    input.className = 'filterInput';
    div.className = 'filterHeader';
    
    this.addEventListeners(input, colIndex, tableClass);
  
    div.appendChild(input);
  
    return div;
  };


  addInput = (col, TH) => {
    var tableClass = TH.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.className
    // Hooks can return value other than number (for example `columnSorting` plugin use this).
    if (typeof col !== 'number') {
      return col;
    }
    if (col >= 0 && TH.childElementCount < 2) {
      TH.appendChild(this.getInitializedElements(col, tableClass));
    }
  };
  
  // Deselect column after click on input.
  doNotSelectColumn = (event, coords) => {
    if (coords.row === -1 && event.target.nodeName === 'INPUT') {
      event.stopImmediatePropagation();
      this.state.instance2.deselectCell();
      // this.state.instance3.deselectCell();
    }
  };






  addRow = e => {
    this.state.instance.alter("insert_row", this.state.instance.countRows(), this.state.addedRowCount)
  }
  
  handleSubmit = e => {
    this.setState({
      addProductStatus :false
    })
    // this.state.addProductStatus = false;
    //VALIDATION NOT DONE YET
    
    const BARCODE_INDEX = 0;
    const STOCKCODE_INDEX = 1;
    const AMOUNT_INDEX = 2;
    const PACKAGE_INDEX = 3;
    let validated = false;
    let data = this.state.instance.getData();
    let reduced = []
    for(let i = 0; i < data.length; i++){
      if(data[i][BARCODE_INDEX] || data[i][STOCKCODE_INDEX] || data[i][AMOUNT_INDEX] ||data[i][PACKAGE_INDEX]){
        if(data[i][BARCODE_INDEX] && data[i][STOCKCODE_INDEX] && data[i][AMOUNT_INDEX] && data[i][PACKAGE_INDEX]){
          let obj = {};
          obj.orderId = this.state.id;
          obj.barcode = data[i][BARCODE_INDEX];
          obj.stockCode = data[i][STOCKCODE_INDEX];
          obj.amount = data[i][AMOUNT_INDEX];
          obj.package = data[i][PACKAGE_INDEX];  
          reduced.push(obj)
          this.setState({
            missingError: ""
          })
          validated = true;
        }
        else{
          this.setState({
            missingError: "Lütfen bütün sütunları doldurunuz"
          })
          validated = false
          this.setState({
            addProductStatus: true
          })
        }
      }
    }
    if(validated){
      this.setState({
        listSentState: "Girdiler gönderildi."
      })
      axios.post(ip + "api/prepareorder", reduced).then(response => {
        console.log(response.data)
        if(response.data == "Success"){
          this.state.instance.clear()
          this.updatePrepareOrder()
          this.setState({
            readAndFilteredBarcode : -2
          })
          $('input[name="barcodeInput"]').trigger("change")
        }
        else
        this.setState({
          missingError: "Yanlış veri türü bulunmaktadır. Düzeltip tekrar deneyiniz.",
          addProductStatus: true,
        })
        
      }).catch(error => {
        console.log(error)
        this.setState({
          addProductStatus: true
        })
      })
    }
  }
  handleChange = changes => {
    const ROW_INDEX = 0;
    const COLUMN_INDEX = 1;
    const IMAGE_COLUMN_INDEX = 4;
    const BARCODE_COLUMN_INDEX = 0;
    const VALUE_INDEX = 3;
    
    if(changes){
      let barcodeList = []
      let changeRowList = []
      changes.forEach(change => {
        if(change[COLUMN_INDEX] === BARCODE_COLUMN_INDEX){
          //request stockcode
          if(change[VALUE_INDEX] !== null && change[VALUE_INDEX] !== ""){
            barcodeList.push(change[VALUE_INDEX])
            changeRowList.push(change[ROW_INDEX])
          }
        }
      })

      let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
      let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

      let authData = {
        authKey:  authKey,
        username: username,
        expirationTime: ""
      }

      let postObject = {"barcodes": barcodeList, "sessionData": authData}

      axios.post(ip + "api/product/getstockcodes/", postObject).then(response => {
        if(response.data !== "Authorization Error"){
          let stockCodeList = JSON.parse(response.data);
          changeRowList.forEach((changeIndex, index) => {
            if(stockCodeList[index] !== null){
              this.state.instance.setDataAtCell(changeIndex, COLUMN_INDEX, stockCodeList[index].stockCode)
              var eksikListesiBarcodeFilter = document.getElementsByName("barcodeInput")[0]
              this.setState({
                readAndFilteredBarcode: barcodeList[index]
              })
              $('input[name="barcodeInput"]').trigger("change")
            }
              
            else
              this.state.instance.setDataAtCell(changeIndex, COLUMN_INDEX, "#Bulunamadi#")
          })
        }
      }).catch(error => {
        console.log(error)
      })
      axios.post(ip + "api/productimage", barcodeList).then(response => {
        let imageLinks = JSON.parse(response.data);
        let thumbnails = JSON.parse(imageLinks.thumbnails)
        let zenitals = JSON.parse(imageLinks.zenitals)
        changeRowList.forEach((changeIndex, index) => {
          let imageData = {"thumbnail" : thumbnails[index], "zenital": zenitals[index]}
          if(imageData.thumbnail !== null)
            this.state.instance.setDataAtCell(changeIndex, IMAGE_COLUMN_INDEX, imageData)
          else
            this.state.instance.setDataAtCell(changeIndex, IMAGE_COLUMN_INDEX, "#Bulunamadi#")
        })
      }).catch(error => {
        console.log(error)
      })
    }
  }
  handleSelection = (row, column, row2, column2) => {

    this.setState({
      selectionStart: row,
      selectionEnd: row2
    })
    
  }

  handleSelectedSumChecklist = (e) => {
    try{
      let selectedInstance = this.state.instance3
      let selected = selectedInstance.getSelected() || [];

      const data = [];
      let sum = 0;
      if(selected.length !== 0){

        for (let i = 0; i < selected.length; i += 1) {
          const item = selected[i];
          data.push(selectedInstance.getData(...item));
        }


        for(let j = 0; j<data.length; j++)
        {
          for(let i = 0; i<data[j].length; i++)
          {
            if(!isNaN(parseInt(data[j][i])))
              sum += parseInt(data[j][i])
          }
        }
      }
      this.setState({
        selectedSumChecklist: sum
      })
    }
    catch(error){
      console.log(error)
    }
    
  }

  handleDeselectCheckList = (e) => {
    this.setState({
      selectedSumChecklist: 0
    })
    if(e.className === "removeRows")
      return false
    else
      return true
  }

  handleSelectedSumEksik = (e) => {
    try{
      let selectedInstance = this.state.instance2
      let selected = selectedInstance.getSelected() || [];

      const data = [];
      let sum = 0;
      if(selected.length !== 0){
        for (let i = 0; i < selected.length; i += 1) {
          const item = selected[i];
          data.push(selectedInstance.getData(...item));
        }

        for(let j = 0; j<data.length; j++)
        {
          for(let i = 0; i<data[j].length; i++)
          {
            if(!isNaN(parseInt(data[j][i])))
              sum += parseInt(data[j][i])
          }
        }
      }
      this.setState({
        selectedSumEksik: sum
      })
    }
    catch(error){
      console.log(error)
    }
    
  }

  handleDeselectEksik = () => {
    this.setState({
      selectedSumEksik: 0
    })
    return true
  }

  handleExcelExport = () => {
    // var columns = [
    //   { label: 'Barkod', value: 'Barkod' }, // Top level data
    //   { label: 'Stok Kodu', value: 'Stok Kodu' }, // Run functions
    //   { label: 'Adet', value: 'Adet' }, // Deep props
    //   { label: 'Koli', value: 'Koli' }, // Deep props
    // ]
    var headers = this.state.instance3.getColHeader()
    var data = this.state.instance3.getData();
    data.unshift(headers)
    var filename = 'Export-CheckList-'+ this.state.orderNo + "-" + new Date().toLocaleDateString();
    const book = XLSX.utils.book_new();
    const sheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(book, sheet, 'Checklist');
    XLSX.writeFile(book, `${filename}.xlsx`);
  }

  handleDelete = () => {
    let selectedCells = this.state.instance3.getSelected();
    let mappedCells =[];
    for(let i=0; i<selectedCells.length; i++){
      mappedCells.push([selectedCells[i][0], selectedCells[i][2]-selectedCells[i][0]+1]);
    }

    let correctedMappedCells = mappedCells.map(mapped  => {
      if(mapped[1]<0){
        return [mapped[0]-(-1*mapped[1]+1), -1*mapped[1]+2]
      }
      else
        return mapped
    })
    console.log(correctedMappedCells)
    this.state.instance3.alter('remove_row',correctedMappedCells);

    /*let tmp = this.state.prepareOrder.map(el => el);
    selectedCells.forEach((element )=> {
      let fark = element[2] - element[0] + 1
      let nulls = []
      for(let i = 0; i < fark; i++){
        nulls.push(null)
      }
      tmp.splice(element[0], fark, ...nulls)
    })
    tmp = tmp.filter(element => element)*/

    // this.state.instance3.getPlugin('filters').removeConditions(0);
    // this.state.instance3.getPlugin('filters').removeConditions(1);
    // this.state.instance3.getPlugin('filters').removeConditions(2);
    // this.state.instance3.getPlugin('filters').removeConditions(3);
    
    let tmp =  this.state.instance3.getSourceData();
    let deletedSend = tmp.map(el => {
      return{
        barcode: el[0],
        stockCode: el[1],
        amount: el[2],
        package: el[3],
        orderId: this.props.match.params.id
      }
    })
    this.setState({
      prepareOrder: tmp
    })
    this.state.instance3.getPlugin('filters').filter()

    axios.put(ip + "api/prepareorder/" + this.props.match.params.id, deletedSend).then(response => {

      // this.updateEksikListesi()
      this.updatePrepareOrder()
    }).catch(error => {
      console.log(error)
    })
  }
  updateEksikListesi = () => {
    this.setState({
      cekiListesiState: "Eksik listesi güncelleniyor",
    })
    axios.get(ip + "api/preporderdif/" + this.props.match.params.id).then(response => {
      let eksikListesi = JSON.parse(response.data);
      

      let barcodeList = []
      let imageLinks = []
      eksikListesi.forEach(function(item){
        barcodeList.push(item.barcode)
      });

      axios.post(ip + "api/productimage", barcodeList).then(response3 => {

        imageLinks = JSON.parse(response3.data)
        let thumbnails = JSON.parse(imageLinks.thumbnails)
        let zenitals = JSON.parse(imageLinks.zenitals)
        eksikListesi.forEach(function(item, index){
          item.imageLinks = {"thumbnail" :thumbnails[index], "zenital": zenitals[index]};
        });

        this.setState({
          cekiListesiState: "",
          eksikListesi: eksikListesi.map(prod => {
            return([prod.barcode, prod.stockCode, prod.difference, prod.orderedAmount, prod.imageLinks])
          }),
          addProductStatus: true,
        })
        let emptyRows = this.state.instance.countRows()
        this.state.instance.alter("remove_row", 5, emptyRows-5)
        window.alert("Eksik listesi güncellendi.")

        this.updateKoliListesi();
      }).catch(e =>{
        console.log(e)
      })
      
    }).catch(error => {
      console.log(error)
      this.setState({
        addProductStatus: true
      } )
    })
  }

  updateKoliListesi = () => {
    this.setState({
      cekiListesiState: "Koli listesi güncelleniyor",
    })
    axios.get(ip + "api/package/" + this.props.match.params.id).then(response => {
    if(response.data !== "Authorization Error"){
      let gelenPackages= JSON.parse(response.data)
        this.setState({
          packageList : gelenPackages.map(pack => {
            return([pack.package, pack.size, pack.icerik])
          })
        })
        if(gelenPackages.length>0){
          if(gelenPackages.length*40 <200){
            this.setState({
              koliHeight : gelenPackages.length*40 + 50
            })
          }
          else{
            this.setState({
              koliHeight : 200
            })
          }
        }
    }
    this.setState({
      cekiListesiState: "",
    })
    window.alert("Koli listesi güncellendi.")
    console.log(this.state.packageList)
  })
  }

  updatePrepareOrder = () => {
    this.setState({
      cekiListesiState: "Çeki listesi güncelleniyor",
    })
    axios.get(ip + "api/prepareorder/" + this.props.match.params.id).then(response => {
      let data = JSON.parse(response.data);
      this.setState({
        prepareOrder: data.map(element => {
          return([element.barcode, element.stockCode, element.amount, element.package])
        }),
        cekiListesiState: "Çeki listesi güncellendi",
      })
      this.updateEksikListesi()
    })
  }

  checkListState = () => {  
    var state= this.state.instance3.getSettings().readOnly
    if(state){
      if(window.confirm("Çeki Listesi düzenleme moduna geçilecektir. Onaylıyor musunuz?")){
        this.state.instance3.updateSettings({
          readOnly:false
        })
        this.setState({
          checkListState: false,
          checkListStateText: "Aktif"
        })
      }
    }
    else{
      this.state.instance3.updateSettings({
        readOnly:true
      })
      this.setState({
        checkListState: true,
        checkListStateText: "Pasif"
      })
      window.alert("Çeki Listesi kilitlendi.")

    }
  }

  changePrepareOrder = () => {
    
    var filtersPlugin = this.state.instance3.getPlugin('filters');
    var filtersPlugin2 = this.state.instance3.getPlugin('filters');
    if(window.confirm("Değişiklikler kaydedilecektir. Onaylıyor musunuz ?"))
    {
      var filterInputs = document.getElementsByClassName("filterInput")
      for(let item of filterInputs)
        item.value= ''
      filtersPlugin.clearConditions();
      filtersPlugin2.clearConditions();
      filtersPlugin.filter();
      filtersPlugin2.filter();
      let data = this.state.instance3.getData();
      let sentData = data.map(el => {
        return{
          barcode: el[0],
          stockCode: el[1],
          amount: el[2],
          package: el[3],
          orderId: this.props.match.params.id
        }
    })
      axios.put(ip + "api/prepareorder/" + this.props.match.params.id, sentData).then(response => {
        console.log(response.data)
        if(response.data == "Success"){
          this.updatePrepareOrder()
          window.alert("Değişiklikler kaydedildi.");
          this.setState({
            updateChecklistError: "",
          })
        }
        else{
          this.setState({
            updateChecklistError: "Yanlış veri türü bulunmaktadır. Düzeltip tekrar deneyiniz.",
          })
          this.updatePrepareOrder()
        }
        
      }).catch(error => {
        console.log(error)
      })
    }
    else
      window.alert("İşlem İptal Edildi.");
  }
  handleAddChange = (e) => {
    this.setState({
      addedRowCount: e.target.value
    })
  }

  koliGuncelle = () => {
    let data = this.state.instance4.getData();
   
    let sentData = data.map(el => {
      return{
        orderId: this.props.match.params.id,
        package: el[0],
        size: el[1],
        icerik: el[2]
      }
    })
    console.log(sentData)
    let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
    let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

    let authData = {
      authKey:  authKey,
      username: username,
      expirationTime: ""
    }

    axios.post(ip + "api/package", sentData).then(response => {
      console.log(response.data)
      if(!response.data.includes("error"))
        this.updateKoliListesi();
    })
  }

  handleZenitalClose = (e) => {
    // console.log(e.target.className)
    if(e.target.className === "zenital-container"){
      this.setState({
        zenitalImageDiv: null
      })
    }
  }

  updateDetail = (e) => {

    if(window.confirm("Detay bilgilerindeki değişiklikler kaydedilecektir. Onaylıyor musunuz ?"))
    {

      let orderDetail = {id: this.props.match.params.id, detail: this.state.orderDetail}
      axios.post(ip + "api/updateorderdetail", orderDetail).then(response => {
        console.log(response.data)
        if(!response.data.includes("error")){
          this.setState({
            productDetailChanged : ""
          })
          window.alert("Detay bilgisi güncellendi.")
        }
        else
          window.alert("Bir hata oluştu")
      }).catch(e =>{
        window.alert("Bir Hata Oluştu!")
        console.log(e)
      })
    }
    else{
      window.alert("İşlem iptal edildi")
    }
  }

  handleDetailChange = (e) => {

    this.setState({
      [e.target.id]: e.target.value,
      productDetailChanged : "Değişiklikleri Kaydedin !"
    })
  }

  barcodeStockCheckToggle = e =>{
    if(this.state.stockCheckToggle == true){
      this.setState({
        stockCheckToggle: false
      })
    }
    else{
      this.setState({
        stockCheckToggle: true
      })
    }
  }

  productDetailToggle = e => {
    let detailElements = document.getElementsByClassName("product-detail")

      if(this.state.productDetailToggleStatus =="none"){
        for(let i = 0; i < detailElements.length; i++){
          detailElements[i].style.display = "Block"
        }
        this.setState({
          productDetailToggleStatus :"block"
        })
      }
        
      else{
        for(let i = 0; i < detailElements.length; i++){
          detailElements[i].style.display = "None"
        }
        this.setState({
          productDetailToggleStatus :"none"
        })
      }
  }

  handleBarcodeRead = (e) => {
    if(e.target.className =="handsontableInput" && this.state.instance.getSelected()!=undefined){
      let columnNumber = this.state.instance.getSelected()[0][1]
      let rowNumber = this.state.instance.getSelected()[0][0]
      if(e.key == "Enter"){
        if(columnNumber==0){
          this.state.instance.selectCell(rowNumber,2)
        }
        if(columnNumber==2){
          this.state.instance.selectCell(rowNumber,0)
        }
      }
    }
    
    // if(e.key == "Shift"){
    //   console.log()
    //   this.state.instance.selectCell(this.state.instance.getSelected()[0][0]+1,0)
    // }
  }


  


  

  render(){
    if(this.state.redirect){
      return <Redirect to={this.state.redirect}/>
    }
    const isZenitalOpen = this.state.zenitalImageDiv;
    var isLoadingText = false
    if(this.state.cekiListesiState !== "")
      isLoadingText = true
    return(
      
      <div className={this.classes.root}>
        
          {isZenitalOpen ? (

            <div class="zenital-container" onClick={this.handleZenitalClose} style={{height:"100%", width:"1200px", background:"rgb(255 255 255 / 60%)", position:"fixed", zIndex:"200", textAlign:"center"}}>
              <p class="zenital-container" style={{float:"right", marginRight:"10px", cursor:"pointer", textShadow:"4px 4px 4px #ccc"}} onClick={this.handleZenitalClose}><b class="zenital-container">Kapat</b></p>
              <img src={isZenitalOpen} style={{margin:"0px", position:"absolute", top:"50%", left:"50%", background: "rgba(255, 255, 255, 1)", transform: "translate(-50%, -50%)", maxHeight:"80%", boxShadow:"4px 4px 4px #BEBEBE"}}></img> 
            </div>

            
          ) : (
            <div></div>
          )}
      <VitausLogo/>
      <LogoLinkSmall to={"/orders/"+this.state.id} logo={<ReturnLogo height={20} width={20}/>} label=".."/>
     
        <div>
        {(isLoadingText) ? 
          <div className={this.classes.loadingStatus}>
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
            <div className="parent-loading-text">
              {/* <div>Aktarımlar tamamlanıyor...</div> */}
              <div>{this.state.cekiListesiState}</div>
            </div>
            
            
          </div>
          : (null)
        } 
          <div className={this.classes.excel}>
          <div id="hot-app">
          <h3>Order No: {this.state.orderNo}</h3>
          <h5>Girdi Tablosu</h5>
            <HotTable
              afterChange={this.handleChange}
              licenseKey={"non-commercial-and-evaluation"}
              ref={this.ref}
              startCols={4}
              colHeaders={["Barkod", "Stok Kodu", "Adet", "Koli", "Fotoğraf"]}
              rowHeaders={true}
              contextMenu={true}
              afterDocumentKeyDown = {this.handleBarcodeRead}
              allowInsertColumn={false}
              allowRemoveColumn={false}
              afterGetColHeader ={ function(col, TH) {
                TH.className = 'htCenter'
               }}
              afterGetRowHeader={ function(col, TH) {
                TH.className = 'htMiddle'
               }}
              colWidths={[120, 210, 50, 50, 110]}
              columns={[
                {
                  type: "text",
                  className: "htCenter htMiddle"
                },{
                  type: "text",
                  className : "htMiddle"
                },{
                  type: "numeric",
                  className: "htCenter htMiddle"
                },{
                  type: "text",
                  className: "htCenter htMiddle"
                },
                {
                  renderer: (instance, td, row, col, prop, value, cellProperties) => {
                    td.className = "htMiddle"
                  if(value !== null && value !== "")
                    // td.innerHTML = "<center><img src = '" + value + "' style ='width:60px; height:60px; margin:2px; vertical-align:middle;' ></img></center>"
                    {
                      td.className = "htMiddle"
                      const img = document.createElement('IMG');
                      const center = document.createElement("center");

                      img.src = value.thumbnail;
                      img.style.width = "60px";
                      img.style.height = "60px";
                      img.style.verticalAlign = "middle";
                      img.style.margin = "2px";
                      img.style.cursor ="pointer";
                      img.setAttribute("align", "center");
                      
                      Handsontable.dom.addEvent(img, 'mousedown', event =>{
                        // event.preventDefault(); // prevent selection quirk
                        this.setState({
                          zenitalImageDiv : value.zenital
                        })

                      });

                      Handsontable.dom.empty(td);
                      center.appendChild(img)
                      td.appendChild(center);
                    }

                  else{
                    Handsontable.dom.empty(td);
                    td.innerHTML = ""
                  }
                    
                 },
                 className : "htCenter htMiddle"
                 
               }
              ]}
              language={"tr-TR"}
            />
            <input name="addedRowCount" onChange={this.handleAddChange} className={this.classes.smallInput} type="number" value={this.state.addedRowCount}/>
            <button onClick={this.addRow}>Satır Ekle </button>
            <button onClick={this.handleSubmit} disabled= {!this.state.addProductStatus}>Gönder</button>
            <label className="toggle" style={{marginTop: "10px", marginLeft: "5px"}}>
                  <input checked={this.state.stockCheckToggle} className="toggle-checkbox-barcode" type="checkbox" onChange={this.barcodeStockCheckToggle}></input>
                  <div className="toggle-switch"></div>
                  <span className="toggle-label">Stok Kontrol?</span>
          </label>
            {this.state.missingError ? (<div><b>{this.state.missingError}</b></div>) : null}
          </div>
          </div>
          <div className={this.classes.check}>
          <h5 style={{marginTop:"75px"}}>Eksik Listesi</h5>

        {
          
          <div className={this.state.eksikClassName}>
          
          <HotTable
            id= "hot"
            licenseKey="non-commercial-and-evaluation"
            data = {this.state.eksikListesi}
            settings={this.hotSettings}
            ref = {this.ref2}
            rowHeaders= {false}
            className= 'eksik-listesi'
            filters= {true}
            colWidths= {[120, 230, 50, 50, 110]}
            width=  {580}
            height ={650}
            readOnly= {true}
            outsideClickDeselects={this.handleDeselectEksik}

            colHeaders={["Barkod", "Stok Kodu", "Fark", "Sipariş", "Fotoğraf"]}
            // columns={[
            //   {className: "htCenter"},{className: "htCenter"},{className: "htCenter"},
            //   {
            //      renderer: (instance, td, row, col, prop, value, cellProperties) => {
            //      td.innerHTML = "<center><img src = '" + value + "' style ='width:60px; height:60px' ></img></center>"
            //     },
                
            //   }
            // ]}
            afterSelection={this.handleSelectedSumEksik}
            afterGetColHeader= {this.addInput}
            beforeOnCellMouseDown = {this.doNotSelectColumn}
             />
            </div>
            }




          </div>
        </div>
        


        <div className={this.classes.orderDetail}>
        <div className = {this.classes.packageList}>
        <h5 className="product-detail" style={{display:"none"}}>Koli Tablosu</h5>
        <HotTable
            
            style = {{marginTop:"10px", display: "None"}}
            afterChange={this.handleChange}
            filters = {true}
            className = "koli-listesi product-detail"
            licenseKey={"non-commercial-and-evaluation"}
            ref={this.ref4}
            startCols={4}
            height = {this.state.koliHeight}
            width = {590}
            data = {this.state.packageList}
            colHeaders={["Koli No", "Ebat", "Icerik"]}
            rowHeaders={true}
            contextMenu={true}
            //afterDocumentKeyDown = {this.handleBarcodeRead}
            allowInsertColumn={false}
            allowRemoveColumn={false}
            afterGetRowHeader={ function(col, TH) {
              TH.className = 'htMiddle'
              }}
              colWidths={[100,100,310]}
              columns={[
              {
                type: "text",
                className: "htCenter"
              },{
                type: "text",
                className: "htCenter"
              },{
                type: "text",
                className: "htCenter"
              }
            ]}
            language={"tr-TR"}
            afterGetColHeader= {this.addInput}
            beforeOnCellMouseDown = {this.doNotSelectColumn}
        />
        <button className="product-detail" onClick={this.koliGuncelle} style={{display:"none", marginTop:"10px"}}>Koli Güncelle </button>
          </div>
          {/* <h4 >Sipariş Detayları</h4> */}
          <label className="toggle" style={{marginTop: "10px"}}>
                  <input className="toggle-checkbox" type="checkbox" onChange={this.productDetailToggle}></input>
                  <div className="toggle-switch"></div>
                  <span className="toggle-label">Detay Görüntüle</span>
          </label>
          <button className="product-detail" onClick={this.updateDetail} style = {{float: "right", marginTop:"10px", display: "None"}}>Detayları Kaydet </button> 
          <span className="product-detail"  style = {{float: "right", marginRight:"10px", marginTop:"10px", display: "None", color: "red"}}><b>{this.state.productDetailChanged}</b></span>
          
          <div style={{marginTop: "5px"}}>
            <label htmlFor="detail"></label>
          </div>
          <div className="product-detail" style={{display:"None"}}>
            <textarea style={{resize: "none"}} type="text" name="detail" id="orderDetail" value={this.state.orderDetail} onChange={this.handleDetailChange} placeholder="" rows="10" cols="78"/>
            
          </div>
        </div>
        
        <div className={this.state.prepareOrderClassName}>
          <h5>Çeki Listesi <button onClick={this.checkListState} style = {{float: "right"}}>Düzenleme {this.state.checkListStateText} </button> 
          
          </h5>
          <HotTable
            outsideClickDeselects={this.handleDeselectCheckList}
            licenseKey={"non-commercial-and-evaluation"}
            ref={this.ref3}
            id = "hot-ceki"
            className = "ceki-listesi"
            data={this.state.prepareOrder}
            // columns={[{
            //   readOnly: true
            // }, {
            //   readOnly: true
            // }, {}, {}]}
            startCols={4}
            colHeaders={["Barkod", "Stok Kodu", "Adet", "Koli"]}
            rowHeaders={true}
            
            // dropdownMenu={true}
            colWidths={[160, 250, 65, 65]}
            // rowHeights = "22"
            filters={true}
            columns={[
              {
                type: "text",
                className: "htCenter"
              },{
                type: "text"
              },{
                type: "numeric",
                className: "htCenter"
              },{
                type: "text",
                className: "htCenter"
              }
            ]}
            
            /*afterSelectionEnd={(row, column, row2, column2) => {
              this.handleSelection(row, column, row2, column2)
            }}*/
            afterSelection={this.handleSelectedSumChecklist}
            afterGetColHeader= {this.addInput}
            beforeOnCellMouseDown = {this.doNotSelectColumn}
          />
          <button onClick={this.changePrepareOrder} className= "saveCheckList">Kaydet</button>
          <button onClick={this.handleDelete} disabled = {this.state.checkListState} className="removeRows">Seçili Girdiyi Sil</button>
          <button onClick={this.handleExcelExport}>Excel'e İndir</button>
          {this.state.updateChecklistError ? (<div><b>{this.state.updateChecklistError}</b></div>) : null}

          <label style = {{bottom:0, right:15, position:"fixed"}}><b>Toplam</b>
            <input id="selectedSum" name="selectedSum" type="number" value={String(this.state.selectedSumChecklist + this.state.selectedSumEksik)} style = {{marginLeft:5, width:"50px"}} readOnly="{true}"/>
          </label>
        </div>
       
      </div>
    )
  }
}

export default withStyles(styles)(PrepareOrder)

