import React from "react";
import {NavLink} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom";
const useStyles = makeStyles({
  logo: {
    textAlign: "center",
  },
  label: {
    textAlign: "center",
    background: "linear-gradient(174deg, rgba(222,235,255,1) 0%, rgba(168,168,168,0.3536765047816002) 83%)"   
  },
  link: {
    width: "auto",
    display: "inline-block",
    marginBottom: "4px",
    marginTop: "10px"
  },
  userInfo: {   
    marginTop: "-1px",
    float:"right",
  }
})
export default function LogoLinkSmall(props){
  const classes = useStyles();
  let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
  let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

  let authData = {
    authKey:  authKey,
    username: username,
    expirationTime: ""
  }

  let logout = (e) => {
    //populate dates based on firm objects
    sessionStorage.clear();

}



  return(
    <div>
      <NavLink to={props.to}>
            <div className={classes.link}>
              <div className={classes.logo}>
                {props.logo}
              </div>
              <div className={classes.label}>
                {props.label}
              </div>
            </div>
      </NavLink>
      {authData ? (
        <div className={classes.userInfo}>
          <div style={{float:"right"}} >
            <b>Yönetici Hesabı</b>
          </div>
          <div style={{color: "red"}}>{authData.username}</div>
          <Link to='/login' ><div style={{color: "gray", float:"right", fontSize:"0.9em", cursor:"pointer"}} onClick={logout}>Çıkış</div></Link>
        </div>
      ) : (
        <div className={classes.userInfo}>Hoşgeldiniz</div>
        // <LoginButton onClick={this.handleLoginClick} />
      )}
      
    </div>
  )
}