import React, {Component} from "react";
import {withStyles } from '@material-ui/core/styles';
import {ReactComponent as OrderLogo} from "../assets/icon/order.svg"
import {ReactComponent as CampaignLogo} from "../assets/icon/social-media-marketing.svg"
import {ReactComponent as StockLogo} from "../assets/icon/warehouse.svg"
import {ReactComponent as ReportLogo} from "../assets/icon/report.svg"
import {ReactComponent as CustomerLogo} from "../assets/icon/value.svg"
import {ReactComponent as ProductLogo} from "../assets/icon/product.svg"
import LogoLink from "./LogoLink";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import tr from "date-fns/locale/tr";

const styles = {
  "root": {
    background:"red",

  },
  "spanDiv":{
    display:"block",
    width:"100%",
    borderTop: "1px solid #ccc",
    marginTop:"10px"
 },
 "divHeaderContainer":{
  marginTop:"10px",
  lineHeight:"26px",
  fontSize: "22px",
  color:"#029ed1"

 },
 "divHeaderContainerOrders":{
  marginTop:"10px",
  lineHeight:"26px",
  fontSize: "22px",
  color:"#7c009e"

 },
 "divHeader":{ 
  fontSize:"15px",
  fontWeight:"400",
  

 },
  "overviewContainer": {
    width:"1200px",
    height:"140px",
    marginTop:"20px",

  },
  "totalSales":{
    padding:"1%",
    float:"left",
    width:"25%",
    height:"100%",
    border:" solid 1px #d9d7d7",
    borderRadius: "5px",
    fontSize:"20px",
    fontWeight:"350",
    color:"black"
  },
  "currentOrders":{
    padding:"1%",
    marginLeft:"9%",
    float:"left",
    width:"25%",
    height:"100%",
    border:" solid 1px #d9d7d7",
    borderRadius: "5px",
    fontSize:"20px",
    fontWeight:"350",
  },
  "currentPayments":{
    padding:"1%",
    marginLeft:"9%",
    float:"left",
    width:"25%",
    height:"100%",
    border:" solid 1px #d9d7d7",
    borderRadius: "5px",
    fontSize:"22px",
    fontWeight:"350",
  },
  "row":{
    marginTop:"10px",
    width:"100%",
    height:"100%"
  },
  "col50": {
    float:"left",
    width:"50%"
  },
  "colTextHeader":{
    fontSize:"22px",
    lineHeight:"22px",
    float:"left"
  },
  "colTextSub":{
    fontSize:"15px",
    lineHeight:"15px",
    clear:"both",
    float:"left"
  }
}

class MainExplorer extends Component
{

  state = {
    today: ""
  }

  componentDidMount(){
    registerLocale("tr", tr);
    setDefaultLocale(tr);
    this.setState({
      today: new Date().toLocaleDateString()
    })
  }

  classes = this.props.classes;
  render(){
    return(
      <div>
        <div>
          <LogoLink to="/orderexplorer" logo={<OrderLogo height={45} width={45}/>} label="Sipariş"/>
          <LogoLink to="/campaignexplorer" logo={<CampaignLogo height={45} width={45}/>} label="Kampanya"/>
          <LogoLink to="/stockexplorer" logo={<StockLogo height={45} width={45}/>} label="Stok"/>
          <LogoLink to="/customerexplorer" logo={<CustomerLogo height={45} width={45}/>} label="Müşteri"/>
          <LogoLink to="/productexplorer" logo={<ProductLogo height={45} width={45}/>} label="Ürün"/>
          <LogoLink to="/reportexplorer" logo={<ReportLogo height={45} width={45}/>} label="Rapor"/>
        </div>
        <div className={this.classes.overviewContainer} style={{display:"none"}}>
          <div className={this.classes.totalSales} >
            Anlık Bilanço:
            <div className={this.classes.spanDiv}></div>
            <div className={this.classes.divHeaderContainer}>
              <span className={this.classes.divHeader}>bugün:  </span> {this.state.today}
              <div className={this.classes.row}>
                <div className={this.classes.col50}>
                  <span className={this.classes.colTextHeader}>200</span>
                  <span className={this.classes.colTextSub}>ürün  </span> 
                </div>
                <div className={this.classes.col50}>
                  <span className={this.classes.colTextHeader}>50000€</span>
                  <span className={this.classes.colTextSub}>ciro  </span> 
                </div>
              </div>  
            </div>
          </div>
          <div className={this.classes.currentOrders}>
            Aktif Siparişler:
            <div className={this.classes.spanDiv}></div>
            <div className={this.classes.divHeaderContainerOrders}>
              <span className={this.classes.divHeader}>son sipariş:  </span> {this.state.today}
              <div className={this.classes.row}>
                <div className={this.classes.col50}>
                  <span className={this.classes.colTextHeader}>1</span>
                  <span className={this.classes.colTextSub}>sipariş  </span> 
                </div>
                <div className={this.classes.col50}>
                  <span className={this.classes.colTextHeader}>1000€</span>
                  <span className={this.classes.colTextSub}>tutar  </span> 
                </div>
              </div>  
            </div>
          </div>
          <div className={this.classes.currentPayments}>
            
          </div>
        </div>
      </div>
    )
  } 
}

export default withStyles(styles)(MainExplorer)