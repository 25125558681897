import React, {Component} from "react";
import "react-router-dom";
import {withStyles } from '@material-ui/core/styles';
import {ReactComponent as ReturnLogo} from "../../assets/icon/return.svg";
import LogoLinkSmall from "../../components/LogoLinkSmall";
import VitausLogo from "../VitausLogo"
import {Redirect} from "react-router-dom";
import axios from "axios";
import ip from "../../config/backend";

const styles = {
  "address": {
    '& label': {
      textAlign: "top"
    }
  },
  "root": {
    '& textarea': {
      resize: "none",
      marginTop: "2px"
    },
    '& input': {
      width: "160px"
    },
    '& label': {
      display: "inline-block",
      width: "70px"
    },
    '& form': {
      padding: "5px"
    },
    '& > div': {
      border: "1px solid #ccc"
    },
    '& h5,h4,h3': {
      padding: "0",
      margin: "0"
    }
  },
  "customer": {
    border: "1px solid #ccc",
    width: "30%",
    height: "300px",
    marginTop: "25px",
    '& > div': {
      padding: "5px",
      width: "auto"
    },
  },
}

class CustomerDetail extends Component{
  classes = this.props.classes;
  state = {
    customerName: null,
    customerAddress: null,
    shipmentDeadline: null,
    paymentDeadline: null,
    logo: "",
    contactPerson: null,
    contactEmail: null,
    vatNumber: null,
    vatOffice: null
  }
  componentDidMount(){
    axios.get(ip + "api/customer/" + this.props.match.params.id).then(response => {
      if(response.data){
        let data = JSON.parse(response.data)[0];
        this.setState(data)
      }else{
        this.setState({
          redirect: "/customers"
        })
      }
      
    })
  }
  handleChange = (e) => {
    if(e.target.type === "number"){
      this.setState({
        [e.target.name]: parseInt(e.target.value)
      })
    }
    else{
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }
  validated = () => {
    if(
      this.state.customerName &&
      this.state.customerAddress &&
      this.state.shipmentDeadline &&
      this.state.paymentDeadline &&
      this.state.contactPerson &&
      this.state.contactEmail &&
      this.state.vatNumber &&
      this.state.vatOffice
    ) return true;
    else{
      this.setState({
        missingError: "Lütfen müşteri adı, adresi, termin, vade, iletişim ve vat alanlarını doldurunuz."
      })
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let postObject = {
      customerName: this.state.customerName,
      customerAddress: this.state.customerAddress,
      shipmentDeadline: this.state.shipmentDeadline,
      paymentDeadline: this.state.paymentDeadline,
      logo: this.state.logo,
      contactPerson: this.state.contactPerson,
      contactEmail: this.state.contactEmail,
      vatNumber: this.state.vatNumber,
      vatOffice: this.state.vatOffice
    }
    console.log("Sent object", postObject)
    if(this.validated()){
      axios.put(ip + "api/customer/" + this.props.match.params.id, postObject).then(response => {
        console.log(response)
        let data = JSON.parse(response.data);
        this.setState({
          id: data.id
        })
      }).catch(error => {
        console.log(error)
      })
    }
  }
  render(){
    if(this.state.redirect){
      return <Redirect to={this.state.redirect}/>
    }
    return(
      <div className={this.classes.root}>
        <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
        <VitausLogo/>
        <LogoLinkSmall to="/customers" logo={<ReturnLogo height={20} width={20}/>} label=".."/>
          <div className={this.classes.firstRow}>
            <div className={this.classes.customer}>
              <h4>Müşteri Bilgileri</h4>
              <div className={this.classes.customerLeft}>
              <div>
                <label htmlFor="customerName">İsim</label>
                <input type="text" name="customerName" width="40" value={this.state.customerName}/>
              </div>
              <div>
                <label htmlFor="shipmentDeadline">Termin</label>
                <input type="number" name="shipmentDeadline" value={this.state.shipmentDeadline}/>
              </div>
              <div>
                <label htmlFor="paymentDeadline">Vade</label>
                <input type="number" name="paymentDeadline" value={this.state.paymentDeadline}/>
              </div>
              <div>
                <label htmlFor="contactPerson">İlgili kişi</label>
                <input type="text" name="contactPerson" width="40" value={this.state.contactPerson}/>
              </div>
              <div>
                <label htmlFor="contactEmail">E-posta</label>
                <input type="text" name="contactEmail" width="40" value={this.state.contactEmail}/>
              </div>
              <div>
                <label htmlFor="vatNumber">Vat no.</label>
                <input type="text" name="vatNumber" width="40" value={this.state.vatNumber}/>
              </div>
              <div>
                <label htmlFor="vatOffice">Vat ofis</label>
                <input type="text" name="vatOffice" width="40" value={this.state.vatOffice}/>
              </div>      
              <div className={this.classes.address}>
                <label htmlFor="customerAddress">Adres</label>
                <textarea type="text" name="customerAddress" rows={6} cols={20} value={this.state.customerAddress}/>
              </div>
              </div>
              <div className={this.classes.customerRight}>

              </div>
            </div>
          </div>
          <button className={this.classes.submitButton}>Düzenle</button>
          {this.state.missingError ? (<div>{this.state.missingError}</div>): null}
        </form>
      </div>   
    )
  }
}

export default withStyles(styles)(CustomerDetail)