import React from "react";
import {withStyles } from '@material-ui/core/styles';
import ip from "../../config/backend";
import axios from "axios";
import Handsontable from "handsontable";
import { HotTable } from "@handsontable/react";
import tr from "../../config/dict";
import "handsontable/dist/handsontable.full.css";
import LogoLinkSmall from "../LogoLinkSmall";
import VitausLogo from "../VitausLogo"
import  {ReactComponent as ReturnLogo} from "../../assets/icon/return.svg"
import * as XLSX from 'xlsx'
// const localIp = "http://192.168.1.106:3000/"
const localIp = "https://vitaus-erp.herokuapp.com/";

const styles = {
  "root": {

  },
  "excelupload": {
    border: "1px solid #ccc",
    background: "#c4c4c4",
    padding: "14px",
    width: "400px",
    marginTop: "25px"
  },
  "singleinput": {
    marginTop: "10px",
    border: "1px solid #ccc",
    background: "#c4c4c4",
    padding: "14px",
    width: "400px",
    position: "relative",
    '& input': {
      position: "absolute",
      right: "40%",
    },
    '& div': {
      marginTop: "10px"
    }

  },
  "error": {
    color: "#f54242"
  },
  "hottable": {
    marginTop: "20px"
  },
  "buttons": {
    marginTop: "20px"
  },
  "approval": {
    color: "#008705"
  },
  "denial": {
    color: "#c70300"
  }
}


class StockPage extends React.Component{
  ref = React.createRef();
  classes = this.props.classes;
  state = {
    ready: "",
    notready: "",
    stocks: [],
    "singleInputOK": 0
  }
  componentDidMount(){
    let instance = this.ref.current.hotInstance;
    Handsontable.languages.registerLanguageDictionary(tr)
    this.setState({
      instance: instance
    })
    axios.get(ip + "api/stock").then(response => {
      let data = JSON.parse(response.data);
      this.setState({
        stocks: data
      })
    }).catch(error => {
      console.log(error)
    })
  }

  handleUpload = (e) => {
    //attempt to create stock list
    
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = e => {
      var data = new Uint8Array(e.target.result);
      var wb = XLSX.read(data, {type: "array"});
      var first_worksheet = wb.Sheets[wb.SheetNames[0]];
      data = XLSX.utils.sheet_to_json(first_worksheet, {header:1});
      if(data[0][0] === "Stok Kodu" && data[0][1] === "Adet"){
        let postData = [];
        for(let i = 1; i < data.length; i++){
          let row = data[i]
          let obj = {};
          obj.stockCode = row[0];
          obj.amount = row[1];
          postData.push(obj);
        }

        this.setState({
          ready: "Gönderime hazır",
          notready: "",
          postdata: postData
        })
      }
      else{
        this.setState({
          ready: "",
          notready: "Dosya formatı yanlış"
        })
      }
    }
    reader.readAsArrayBuffer(file);
    //write error if there is an error
  }
  getStocks = () => {
    axios.get(ip + "api/stock").then(response => {
      let data = JSON.parse(response.data);
      this.setState({
        stocks: data
      })
    }).catch(error => {
      console.log(error)
    })
  }
  handleAdd = () => {
    console.log(this.state.postdata)
    axios.post(ip + "api/stock/add", this.state.postdata).then(response => {
      console.log(response)
      this.getStocks();
    })
  }

  handleRemove = () => {
    console.log(this.state.postdata)
    axios.post(ip + "api/stock/remove", this.state.postdata).then(response => {
      console.log(response)
      this.getStocks()
    })
  }

  createSinglePost = () => {
    let lst = [{
      amount: parseInt(document.getElementById("amnt").value),
      stockCode: document.getElementById("stockCode").value
    }]
    console.log(lst)
    return lst;
  }

  resetValues = () => {
    document.getElementById("amnt").value = "";
    document.getElementById("stockCode").value = "";

  }

  handleSingleInputReturn(response){
    if(response.status === 204){
      this.resetValues();
      this.setState({
        singleInputOK: 1
      })
    }
    else{
      this.setState({
        singleInputOK: -1
      })
    }
    this.getStocks();
  }

  validate(post){
    console.log(post.amount)
    console.log(post.stockCode)
    if(!isNaN(post.amount) && post.stockCode !== ""){
      this.setState({
        valError: ""
      })
      return true
    }
    else{
      this.setState({
        valError: "Lütfen geçerli bir stok kodu ve adet giriniz."
      })
      return false
    }
  }

  handleSingleAdd = () => {
    let post = this.createSinglePost();
    if(this.validate(post[0])){
      axios.post(ip + "api/stock/add", post).then(response => {
       this.handleSingleInputReturn(response);
      })
    }
  }

  handleSingleRemove = () => {
    let post = this.createSinglePost();
    if(this.validate(post[0])){
      axios.post(ip + "api/stock/remove", post).then(response => {
        this.handleSingleInputReturn(response);
      })
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render(){
    return(
      <div>
      <VitausLogo/>
      <LogoLinkSmall to="/StockExplorer" logo={<ReturnLogo height={20} width={20}/>} label=".."/>
        <div className={this.classes.excelupload}>
          <h4>Excel Ekle</h4>
          <input type="file" name="excelfile" onChange={this.handleUpload}/>
          <p>{this.state.ready}</p>
          <p className={this.classes.error}>{this.state.notready}</p>
          <button onClick={this.handleAdd}>Ekle</button>
          <button onClick={this.handleRemove}>Çıkar</button>
        </div>
        <div className={this.classes.singleinput}>
          <h4>Stok Girişi</h4>
          <div>
            <label htmlFor="stockcode">Stok Kodu</label>
            <input id="stockCode" type="text" name="stockCode" />          
          </div>
          <div>
            <label htmlFor="amnt">Adet</label>
            <input id="amnt" type="number" name="amnt"/>
          </div>
          {this.state.valError ? (<p className={this.classes.denial}>Lütfen geçerli bir <b>stok kodu</b> ve <b>adet</b> giriniz</p>) : null}
          <div className={this.classes.buttons}>
            <button onClick={this.handleSingleAdd}>Ekle</button>
            <button onClick={this.handleSingleRemove}>Çıkar</button>
          </div>
          {this.state.singleInputOK > 0 ? (<p className={this.classes.approval}>Stok girişi <b>başarılı</b></p>) : null}
          {this.state.singleInputOK < 0 ? ( <p className={this.classes.denial}>Stok girişi <b>başarısız</b></p>) : null} 
         
        </div>
        <div className={this.classes.hottable}>

          <HotTable
            ref={this.ref}
            licenseKey={"non-commercial-and-evaluation"}
            data={this.state.stocks.map(element => {
              return [
                element.barcode,
                element.stockCode,
                element.amount,
                element.category,
                element.priceDefault,
                element.provider,
                element.width,
                element.length,
                element.msquare
              ]
            })}
            colHeaders={["Barkod", "Stok Kodu", "Adet", "Kategori", "Fiyat", "Provider", "Genişlik", "Uzunluk", "m2"]}
            language={"tr-TR"}
            readOnly={true}
            tableClassName={this.classes.hot}
            filters={true}
            dropdownMenu={true}
            outsideClickDeselects={true}
            columns={[
              {
                type: "text"
              },{
                type: "text"
              },{
                type: "numeric"
              },{
                type: "text"
              },{
                type: "numeric"
              },{
                type: "text"
              },{
                type: "numeric"
              },{
                type: "numeric"
              },{
                type: "numeric"
              }]
            }
          />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(StockPage);

//stock