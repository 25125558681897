import React, {Component} from "react";
import "react-router-dom";
import {withStyles } from '@material-ui/core/styles';
import {ReactComponent as ReturnLogo} from "../../assets/icon/return.svg";
import LogoLinkSmall from "../LogoLinkSmall";
import VitausLogo from "../VitausLogo"
import ip from "../../config/backend";
import axios from "axios";
import Handsontable from "handsontable";
import { HotTable } from "@handsontable/react";
import tr from "../../config/dict";
import "handsontable/dist/handsontable.full.css";
import "../../custom-buttons.css"
const localIp = "https://vitaus-erp.herokuapp.com/";
var XLSX = require('xlsx');
// const localIp = "http://localhost:3000/";


const styles = {
  "root": {

  },
  "fetchByContainer": {
    padding: "10px",
    border: "1px solid #ccc",
    height: "90px",
    marginTop: "20px",
    width:"100%",
    // '& > div': {
    //   padding: "5px",
    //   width: "auto"
    // },
  },
  "fetchBySelect":{
    float:"left",
    width:"30%",
  },
  "fetchByClear":{
    position: "relative",
    float:"right",
    width:"30%",
    height:"100%",
  }
}

class FetchProductData extends Component{
  ref = React.createRef();
  state = {
    products: [],
    fetchColumnIndex: 0
  }
  componentDidMount(){
    let instance = this.ref.current.hotInstance;
    Handsontable.languages.registerLanguageDictionary(tr)
    this.setState({
      instance: instance
    })

    let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
    let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

    let authData = {
    authKey:  authKey,
    username: username,
    expirationTime: ""
    }

    let barcodes = [
      "8698913545323",
      "8698959531175"
    ]

    let mergedData = {"sessionData" : authData, "barcodes": barcodes}


  }

  handleExcelExport = () => {

    var headers = this.state.instance.getColHeader()
    var data = this.state.instance.getData();
    data.unshift(headers)
    var filename = 'Urun-Listesi-' + new Date().toLocaleDateString();
    const book = XLSX.utils.book_new();
    const sheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(book, sheet, 'Urun Listesi');
    XLSX.writeFile(book, `${filename}.xlsx`);
  }

  clearTable = e =>{
    let rowCount = 5
    let colCount = 9
    this.state.instance.updateSettings({
      data : Handsontable.helper.createEmptySpreadsheetData(rowCount, colCount)
  });
  }

  onChangeFetchType = change => {
    let rowCount = 5
    let colCount = 9
    this.state.instance.updateSettings({
      data : Handsontable.helper.createEmptySpreadsheetData(rowCount, colCount)
  });
  this.setState({
    fetchColumnIndex: change.target.value
  })
  }
  
  handleRemoveCellData = changes => {
    const ROW_INDEX = 0;
    const COLUMN_INDEX = 1;
    const VALUE_INDEX = 3;
    
    changes.forEach(change=>{
      if(change[VALUE_INDEX] == null || change[VALUE_INDEX] == "" ){
        if(change[COLUMN_INDEX] == this.state.fetchColumnIndex){
          for(let i=0; i<9; i++)
            this.state.instance.setDataAtCell(change[ROW_INDEX], i, "#Boş Veri")
        }
      }
      
    })
  }

  handleChange = changes => {
    
    const ROW_INDEX = 0;
    const COLUMN_INDEX = 1;
    const IMAGE_COLUMN_INDEX = 4;
    const BARCODE_COLUMN_INDEX = 0;
    const STOCKCODE_COLUMN_INDEX = 1;
    const VALUE_INDEX = 3;
    if(changes){
      if(changes[0][VALUE_INDEX] != null){
        
        let barcodeList = []
        let stockCodeList = []
        let changeRowList = []
        changes.forEach(change => {
          if(change[COLUMN_INDEX] == this.state.fetchColumnIndex){
            //request stockcode
            if(change[VALUE_INDEX] != null && change[VALUE_INDEX] != ""){
              barcodeList.push(change[VALUE_INDEX])
              changeRowList.push(change[ROW_INDEX])
            }
          }
        })
        if(changes[0][COLUMN_INDEX] == this.state.fetchColumnIndex ){
          
          let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
          let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

          let authData = {
            authKey:  authKey,
            username: username,
            expirationTime: ""
          }

          let postObject;
          
          if(this.state.fetchColumnIndex == BARCODE_COLUMN_INDEX)
            postObject = {"barcodes": barcodeList, "stockCodes": [], "sessionData": authData}
          else if(this.state.fetchColumnIndex == STOCKCODE_COLUMN_INDEX)
            postObject = {"barcodes": [], "stockCodes": barcodeList, "sessionData": authData}

          if(this.state.fetchColumnIndex != -1){
            axios.post(ip + "api/product/getproductdata", postObject).then(response => {

                if(response.data != "Authorization Error" && response.data != "Wrong data"){
                  
                  let productList = JSON.parse(response.data)
                  console.log(productList)
                  if(productList.length==1 ){
                    if(changes[0][VALUE_INDEX] != "#Boş Veri"){
                      if(this.state.fetchColumnIndex == BARCODE_COLUMN_INDEX)
                      this.state.instance.setDataAtCell(changeRowList[0], COLUMN_INDEX, productList[0].stockCode)
                    else if(this.state.fetchColumnIndex == STOCKCODE_COLUMN_INDEX)
                      this.state.instance.setDataAtCell(changeRowList[0], COLUMN_INDEX-1, productList[0].barcode)
                    this.state.instance.setDataAtCell(changeRowList[0], COLUMN_INDEX+1, productList[0].category)
                    this.state.instance.setDataAtCell(changeRowList[0], COLUMN_INDEX+2, productList[0].priceDefault)
                    this.state.instance.setDataAtCell(changeRowList[0], COLUMN_INDEX+3, productList[0].provider)
                    this.state.instance.setDataAtCell(changeRowList[0], COLUMN_INDEX+4, productList[0].width)
                    this.state.instance.setDataAtCell(changeRowList[0], COLUMN_INDEX+5, productList[0].length)
                    this.state.instance.setDataAtCell(changeRowList[0], COLUMN_INDEX+6, productList[0].msquare)
                    this.state.instance.setDataAtCell(changeRowList[0], COLUMN_INDEX+7, productList[0].weight) 
                    this.state.instance.setDataAtCell(changeRowList[0], COLUMN_INDEX+8, productList[0].material) 
                    this.state.instance.setDataAtCell(changeRowList[0], COLUMN_INDEX+9, productList[0].imageLink) 
                    }
                  }
                  else{
                    let productData = productList.map(product => {
                      return([ product.barcode, product.stockCode, product.category, product.priceDefault, product.provider, product.width, product.length, product.msquare, product.weight, product.material, product.imageLink])
                    })
                    this.state.instance.updateSettings({
                      data: productData
                    })
                  }
                }

              
                
                
            }).catch(error => {
              console.log(error)
            })
          }
        }
      
        
      }
    }
  }

  classes = this.props.classes;
  render(){
    return(
      <div>
        <div className={this.classes.tableContainer}>
          <div>
          <VitausLogo/>
          <LogoLinkSmall to="/productexplorer" logo={<ReturnLogo height={20} width={20}/>} label=".."/>
          <div className={this.classes.fetchByContainer} onChange={this.onChangeFetchType}>
            <div className={this.classes.fetchBySelect}>
              <h4>Girilecek Veri Sütununu Seçiniz</h4>
              <input type="radio" value="0" name="gender" defaultChecked /> Barkod
              <input type="radio" value="1" name="gender" /> Stok Kodu
            </div>
            <div className={this.classes.fetchByClear}>
              <button style={{position:"absolute", right:"0", bottom:"0", width:"100px", background: "#009933", color: "white", border: "solid 1px #a1a1a1", fontWeight:"500"}} onClick={this.handleExcelExport}>Tabloyu Dışa Aktar</button>
              <button style={{position:"absolute", right:"0", top:"0", width:"100px", background:"#ff1a1a", color:"white", border: "solid 1px #a1a1a1", fontWeight:"500"}} onClick={this.clearTable}>Tabloyu Temizle</button>
            </div>
            
          </div>
          <HotTable
              style = {{marginTop:"25px"}}
              afterChange={this.handleChange}
              afterSetDataAtCell={this.handleRemoveCellData}
              licenseKey={"non-commercial-and-evaluation"}
              ref={this.ref}
              startCols={4}
              colHeaders={["Barkod", "Stok Kodu", "Kategori", "Fiyat", "Tedarik Firması", "Genişlik", "Uzunluk", "Alan", "Ağırlık", "Materyal", "Fotoğraf Linki"]}
              rowHeaders={true}
              contextMenu={true}
              allowInsertColumn={false}
              allowRemoveColumn={false}
              afterGetColHeader ={ function(col, TH) {
                TH.className = 'htCenter'
               }}
              afterGetRowHeader={ function(col, TH) {
                TH.className = 'htMiddle'
               }}
              colWidths={[120, 200, 70, 70, 115,70,70,70,70,120,200]}
              columns={[
                {
                  type: "text",
                  className: "htCenter htMiddle"
                },{
                  type: "text",
                  className : "htMiddle"
                },{
                  type: "text",
                  className: "htCenter htMiddle",
                  readOnly: true
                },{
                  type: "numeric",
                  className: "htCenter htMiddle",
                  readOnly: true
                },
                {
                  type: "text",
                  className: "htCenter htMiddle",
                  readOnly: true
                },
                {
                  type: "numeric",
                  className: "htCenter htMiddle",
                  readOnly: true
                },
                {
                  type: "numeric",
                  className: "htCenter htMiddle",
                  readOnly: true
                },
                {
                  type: "numeric",
                  className: "htCenter htMiddle",
                  readOnly: true
                },
                {
                  type: "numeric",
                  className: "htCenter htMiddle",
                  readOnly: true
                },
                {
                  type: "text",
                  className: "htCenter htMiddle",
                  readOnly: true
                },
                {
                  type: "text",
                  className: "htCenter htMiddle",
                  readOnly: true
                },
              ]}
              language={"tr-TR"}
            />
          </div>
        </div>
      </div>
    )
  }
}



export default withStyles(styles)(FetchProductData)