import React from "react";

import axios from "axios";
import LogoLinkSmall from "../LogoLinkSmall";
import VitausLogo from "../VitausLogo"
import {ReactComponent as ReturnLogo} from "../../assets/icon/return.svg";
import ip from "../../config/backend";
import "handsontable/dist/handsontable.full.css";
import Handsontable from "handsontable";
import tr from "../../config/dict";
import {ColumnChart, LineChart} from 'bizcharts';
import {withStyles } from '@material-ui/core/styles';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';



// import Datetime from 'react-datetime';

const styles = {
    allCharts:{
        width:"100%",
        height:"550px",
    },
    allCategoriesChart:{
        width:"100%",
        height:"100%",
        marginTop:"100px"
        // float:"left"
    },
    chart:{
        height:"100%"
    }
}

const animatedComponents = makeAnimated();
const customStyles = {
    valueContainer: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        const overflowY = "auto"
        const maxHeight = "37px"
    
        return { ...provided, opacity, transition, overflowY, maxHeight };
      }
}

// const selectComponents = { ValueContainer, animatedComponents };
const options = [
    { value: 'all', label: 'Tümünü Seç' },
    { value: 'Ocak', label: 'Ocak' },
    { value: 'Şubat', label: 'Şubat' },
    { value: 'Mart', label: 'Mart' },
    { value: 'Nisan', label: 'Nisan' },
    { value: 'Mayıs', label: 'Mayıs' },
    { value: 'Haziran', label: 'Haziran' },
    { value: 'Temmuz', label: 'Temmuz' },
    { value: 'Ağustos', label: 'Ağustos' },
    { value: 'Eylül', label: 'Eylül' },
    { value: 'Ekim', label: 'Ekim' },
    { value: 'Kasım', label: 'Kasım' },
    { value: 'Aralık', label: 'Aralık' }
  ]


class YearlyCategories extends React.Component{
    ref = React.createRef();
    state = {
        data: [],
        productGraph: [],
        selectedMonths : [],
        totalSold : 0,
        isColumnChart: false,
        hotSettings: {
            rowHeaders: true,
            rowHeights: 25,
            colHeaders: ["Kategori", "Toplam", ""],
            licenseKey: "non-commercial-and-evaluation"
          },
          darkMode: false
        }

    handleMonthChange = (selectedOptions) => {
        let selecteds = []
        selectedOptions.forEach(element =>{
            selecteds.push(element.value)
        })
        if(selectedOptions.length>0){
            if(selectedOptions.find(element => element.value === "all")){
                let allMonthOptions =options.slice(1)
                this.setState({
                    selectedMonths : allMonthOptions
                })
                selecteds = []
                for(let i=0; i<allMonthOptions.length; i++)
                    selecteds.push(allMonthOptions[i].value)
            }
            else{
                this.setState({
                    selectedMonths : selectedOptions
                })
            }
        }
        else{
            selecteds = [options[1].value]
            this.setState({
                selectedMonths : options[1]
            })
        }
        if(selecteds.length>1){
            this.setState({
                isColumnChart : false
            })
        }
        else{
            this.setState({
                isColumnChart : true
            })
        }
            
        
         this.changeReportGraph("2023", selecteds);
    }


    handleCategoryClick = (e)=>{
        // console.log(e)
    }

    handleLegendClick = (e)=>{
        // console.log(e)
    }

    changeReportGraph =(year, months) =>{
        //   window.location = value
        let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
        let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

        let authData = {
        authKey:  authKey,
        username: username,
        expirationTime: ""
        }

        let postObject = {"year": year, "month": months, "sessionData": authData}

        axios.post(ip + "api/report/yearly/product/category/all", postObject ).then(response => {
            if(response.data !== "Authorization Error"){
                let data = JSON.parse(response.data);
                let totalSold = 0
                data.forEach(element => {
                    element.TotalSold = parseInt(element.TotalSold)
                    totalSold+= element.TotalSold
                });
                console.log(totalSold)
                this.setState({
                    productGraph: data,
                    totalSold : totalSold,
                })
            }
        }).catch(error => {
        console.log(error)
        })
    }

    componentDidMount(){

        Handsontable.languages.registerLanguageDictionary(tr)
        let defaultMonths = []
        options.forEach((element,index)=>{
            if(index!=0){
                defaultMonths.push(element.value)
            }
        })
        this.setState({
            selectedMonths : options.slice(1)
        })
        this.changeReportGraph("2023",defaultMonths)

    }
    classes = this.props.classes;
    

    

    render(){
        const isColumnChart = this.state.isColumnChart;
        return(
            <div id="hot-app" >
                
                <VitausLogo/>
                <LogoLinkSmall to="/reportexplorer" logo={<ReturnLogo height={20} width={20}/>} label=".."/>
                <div style={{width:"100%", marginTop:30}}>
                        <div style={{width:"80%", marginTop:"10px", float:"left"}}>
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                styles={customStyles}
                                isClearable
                                options={options}
                                defaultValue={options.slice(1)}
                                onChange={this.handleMonthChange}
                                value = {this.state.selectedMonths}
                            />
                        </div>
                        <div style={{width:"10%", float:"right"}}>
                            <p>
                                <b>Toplam: </b>
                                    {this.state.totalSold}
                            </p>
                            
                        </div>
                            
                </div>
                <div className={this.classes.allCharts}>
                    
                    <div className={this.classes.allCategoriesChart}>
                        <div className= {this.classes.chart}> 
                            {isColumnChart? (
                                
                                <ColumnChart
                                
                                    data={this.state.productGraph}
                                    title={{
                                        visible: true,
                                    }}
                                    height={"100%"}
                                    // highlight-end
                                    xField="Category"
                                    yField="TotalSold"
                                    colorField="Category"
                                    legend = {{
                                        position: "top-right"
                                    }}
                                    label = {{
                                        visible:true,
                                        position:"top"
                                    }}
                                    events={{
                                        // onPieClick: (event) => console.log("selam"),
                                        onPlotClick: (event) => this.handleCategoryClick(event),
                                    }}
                                />
                            ):(
                                
                                <LineChart
                                    data={this.state.productGraph}
                                    title={{
                                        visible: true,
                                    }}
                                    height={"100%"}
                                    // highlight-end
                                    xField="OrderMonth"
                                    yField="TotalSold"
                                    seriesField="Category"
                                    legend = {{
                                        position: "top-right",
                                    }}
                                    events={{
                                        // onPieClick: (event) => console.log("selam"),
                                        // onPlotClick: (event) => this.handleCategoryClick(event),
                                        onLineClick: (event) => console.log(event),
                                        onLegendClick: (event) => this.handleLegendClick(event)
                                    }}
                                >
                                     
                                </LineChart>
                            )}
                        </div>
                    </div>
                </div>
            </div>
           
        )
    }
}

export default withStyles(styles)(YearlyCategories)
