import React, {Component} from "react";
import "react-router-dom";
import {withStyles } from '@material-ui/core/styles';
import {ReactComponent as ReturnLogo} from "../../assets/icon/return.svg";
import LogoLinkSmall from "../../components/LogoLinkSmall";
import VitausLogo from "../VitausLogo"
import {Redirect} from "react-router-dom";
import axios from "axios";
import ip from "../../config/backend";

const styles = {
  "address": {
    '& label': {
      textAlign: "top"
    }
  },
  "root": {
    '& textarea': {
      resize: "none",
      marginTop: "2px"
    },
    '& input': {
      width: "160px"
    },
    '& label': {
      display: "inline-block",
      width: "70px"
    },
    '& form': {
      padding: "5px"
    },
    '& > div': {
      border: "1px solid #ccc"
    },
    '& h5,h4,h3': {
      padding: "0",
      margin: "0"
    }
  },
  "customer": {
    border: "1px solid #ccc",
    width: "30%",
    height: "260px",
    marginTop: "25px",
    '& > div': {
      padding: "5px",
      width: "auto"
    },
  },
}

class CreateProduct extends Component{
  classes = this.props.classes;
  state = {
    stockCode: undefined,
    barcode: undefined,
    category: undefined,
    priceDefault: undefined,
    provider: undefined,
    width: undefined,
    length: undefined,
    msquare: undefined
  }
  componentDidMount(){

    let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
    let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

    let authData = {
    authKey:  authKey,
    username: username,
    expirationTime: ""
    }

    axios.post(ip + "api/product/" + this.props.match.params.id, authData).then(response => {
      console.log(response)
      if(response.data){
        let data = JSON.parse(response.data)[0];
        this.setState(data)
      }else{
        this.setState({
          // redirect: "/products"
        })
      }
    })
  }
  handleChange = e => {
    if(e.target.type === "number"){
      this.setState({
        [e.target.name]: parseFloat(e.target.value)
      })
    }else{
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }
  validated = () => {
    if(
      this.state.stockCode &&
      this.state.barcode &&
      this.state.category &&
      this.state.priceDefault &&
      this.state.provider &&
      this.state.width &&
      this.state.length
    ) return true;
    else{
      this.setState({
        missingError: "Lütfen stok kodu, barkod, kategori, fiyat, genişlik, uzunluk ve m2 alanlarını doldurunuz."
      })
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let postObject = {
      stockCode: this.state.stockCode,
      barcode: this.state.barcode,
      category: this.state.category,
      priceDefault:this.state.priceDefault,
      provider: this.state.provider,
      width: this.state.width,
      length: this.state.length,
      weight: this.state.weight,
      material: this.state.material
    }

    let authKey = sessionStorage.getItem('vts-2016-xyztqwerty')
    let username = sessionStorage.getItem('vts-2016-ytrewqtzyx')

    let authData = {
    authKey:  authKey,
    username: username,
    expirationTime: ""
    }

    if(this.validated()){
      axios.put(ip + "api/postproduct/" + this.props.match.params.id, postObject).then(response => {
        console.log(response)
        let data = JSON.parse(response.data);
        this.setState({
          id: data.id
        })

        axios.post(ip + "api/product/" + this.props.match.params.id, authData).then(response => {
          let data = JSON.parse(response.data);
          this.setState(data)
        })
      }).catch(error => {
        console.log(error)
      })
    }
  }

  render(){
    if(this.state.redirect){
      return <Redirect to={this.state.redirect}/>
    }
    return(
      <div className={this.classes.root}>
        <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
        <VitausLogo/>
        <LogoLinkSmall to="/products" logo={<ReturnLogo height={20} width={20}/>} label=".."/>
          <div className={this.classes.firstRow}>
            <div className={this.classes.customer}>
              <h4>Ürün Bilgileri</h4>
              <div className={this.classes.customerLeft}>
              <div>
                <label htmlFor="stockCode">Stok Kodu</label>
                <input type="text" name="stockCode" width="40" value={this.state.stockCode}/>
              </div>
              <div>
                <label htmlFor="barcode">Barkod</label>
                <input type="text" name="barcode" value={this.state.barcode}/>
              </div>
              <div>
                <label htmlFor="category">Kategori</label>
                <input type="text" name="category" value={this.state.category}/>
              </div>
              <div>
                <label htmlFor="priceDefault">Fiyat</label>
                <input type="number" step={0.01} name="priceDefault" width="40" value={this.state.priceDefault}/>
              </div>
              <div>
                <label htmlFor="provider">Provider</label>
                <input type="text" name="provider" width="40" value={this.state.provider}/>
              </div>
              <div>
                <label htmlFor="width">Genişlik</label>
                <input type="number" step={0.01} name="width" width="40" value={this.state.width}/>
              </div>
              <div>
                <label htmlFor="length">Uzunluk</label>
                <input type="number" step={0.01} name="length" width="40" value={this.state.length}/>
              </div>      
              <div className={this.classes.address}>
                <label htmlFor="msquare">m2</label>
                <input readOnly type="number" name="msquare" step={0.01} value={this.state.msquare}/>
              </div>
              <div>
              <label htmlFor="weight">Ağırlık</label>
              <input type="number" step={0.01} name="weight" width="40" value={this.state.weight}/>
            </div>
            <div>
              <label htmlFor="material">Materyal</label>
              <input type="text" name="material" width="40" value={this.state.material}/>
            </div>   
              </div>
            </div>
          </div>
          <button className={this.classes.submitButton}>Düzenle</button>
          {this.state.missingError ? (<div>{this.state.missingError}</div>): null}
        </form>
      </div>   

      )
  }
}

export default withStyles(styles)(CreateProduct)