import React from "react";

const CampaignSummary = () => {
  return(
    <div>
      Campaign #?
    </div>
  )
}

export default CampaignSummary