import React from "react";


class GraphButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
      }

    // changeProductGraph =(pGraph) =>{
    //     //   window.location = value
    //         let queryStockCode= {
    //             stockCode: pGraph
    //         }
    //         console.log(pGraph)
    //         axios.post(ip + "api/report/productSellGraph", queryStockCode ).then(response => {

    //         let data = JSON.parse(response.data);
            
    //         data.forEach(element => 
    //             element.TotalSold = parseInt(element.TotalSold)
    //             );
    //         console.log(data)
    //         this.setState({
    //             productGraph: data
    //         })
    //         }).catch(error => {
    //         console.log(error)
    //         })
    // }

    render() {
        return <button onClick={() => this.props.onClick(this.props.value)}>Görüntüle</button>
      }
}

export default GraphButton