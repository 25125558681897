import React, {Component} from "react";
import "react-router-dom";
import {withStyles } from '@material-ui/core/styles';
import {ReactComponent as ReturnLogo} from "../../assets/icon/return.svg";
import LogoLinkSmall from "../../components/LogoLinkSmall";
import VitausLogo from "../VitausLogo"
import ip from "../../config/backend";
import axios from "axios";
import Handsontable from "handsontable";
import { HotTable } from "@handsontable/react";
import tr from "../../config/dict";
import "handsontable/dist/handsontable.full.css";
//const localIp = "http://192.168.1.106:3000/";
const localIp = "https://vitaus-erp.herokuapp.com/";
const styles = {
  hot: {
    margin: "auto"
  }
}

class CustomerPage extends Component{
  ref = React.createRef();
  state = {
    customers: [],
    displayedCustomers: []
  }
  classes = this.props.classes;
  componentDidMount(){
    let instance = this.ref.current.hotInstance;
    Handsontable.languages.registerLanguageDictionary(tr)
    this.setState({
      instance: instance
    })
    axios.get(ip + "api/customer").then(response => {
      let data = JSON.parse(response.data);
      this.setState({
        customers: data,
        displayedCustomers: data
      })
    }).catch(error => {
      console.log(error)
    })
  }
  render(){
    return(
      <div>
        <div className={this.classes.tableContainer}>
          <div>
            <VitausLogo/>
            <LogoLinkSmall to="/customerexplorer" logo={<ReturnLogo height={20} width={20}/>} label=".."/>
            <div style={{marginTop:"25px"}}>
              <HotTable
                ref={this.ref}
                licenseKey={"non-commercial-and-evaluation"}
                data={this.state.customers.map(element => {
                  return[
                    element.customerName, element.shipmentDeadline,
                    element.paymentDeadline,
                    element.contactPerson,
                    element.contactEmail,
                    "/customers/" + element.id
                    ]
                })}
                colHeaders={["İsim", "Termin", "Vade", "İlgili Kişi", "Email", ""]}
                language={"tr-TR"}
                readOnly={true}
                tableClassName={this.classes.hot}
                columns={[
                  {},{},{},{},{},
                  {
                    renderer: (instance, td, row, col, prop, value, cellProperties) => {
                      console.log(value)            
                      let button = document.createElement("button", {value: "dfsf"});
                      Handsontable.dom.addEvent(button, 'mousedown', function(event) {
                        event.preventDefault();
                        window.location = value
                      });
                      console.log(button)
                      let newlink = document.createElement('a');
                      newlink.setAttribute('href', value);
                      button.appendChild(newlink)
                      var text = document.createTextNode("Düzenle");
                      button.appendChild(text)
                      Handsontable.dom.empty(td);
                      td.appendChild(button)
                      return td;
                    }
                  }
                ]}
               />
            </div>
          </div>
        </div>
      </div>
    )
  }
}



export default withStyles(styles)(CustomerPage)