import React from "react";
import CampaignSummary from "./CampaignSummary";

const CampaignList = () => {
  return (
    <div className="campaign-list">
      <CampaignSummary/>
      <CampaignSummary/>
      <CampaignSummary/>
    </div>
  )
}

export default CampaignList