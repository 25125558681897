import React, {Component} from "react";
import "react-router-dom";
import {withStyles } from '@material-ui/core/styles';
import {ReactComponent as ReturnLogo} from "../../assets/icon/return.svg";
import LogoLinkSmall from "../../components/LogoLinkSmall";
import VitausLogo from "../VitausLogo"
import {Redirect} from "react-router-dom";
import axios from "axios";
import ip from "../../config/backend";
import * as XLSX from 'xlsx'

const styles = {
  "address": {
    '& label': {
      textAlign: "top"
    }
  },
  "root": {
    '& textarea': {
      resize: "none",
      marginTop: "2px"
    },
    '& input': {
      width: "160px"
    },
    '& label': {
      display: "inline-block",
      width: "70px"
    },
    '& form': {
      padding: "5px"
    },
    '& > div': {
      border: "1px solid #ccc"
    },
    '& h5,h4,h3': {
      padding: "0",
      margin: "0"
    }
  },
  "customer": {
    padding: "10px",
    border: "1px solid #ccc",
    width: "30%",
    height: "250px",
    marginTop: "25px",
    '& > div': {
      padding: "5px",
      width: "auto"
    },
  },
  "multiProduct": {
    padding: "10px",
    border: "1px solid #ccc",
    width: "30%",
    height: "165px",
    marginTop: "25px",
    '& > div': {
      padding: "5px",
      width: "auto"
    },
  },
  "multiProductButtonLabel" : {
    backgroundColor: "#4CAF50", /* Green */
    border: "1px solid #ccc",
    color: "white",
    padding: "10px 10px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "12px"
  }
}

class CreateProduct extends Component{
  classes = this.props.classes;
  state = {
    stockCode: undefined,
    barcode: undefined,
    category: undefined,
    priceDefault: undefined,
    provider: undefined,
    width: undefined,
    length: undefined,
    postData: undefined,
    isExcelLoaded: false,
    excelStatus : "Dosya Bekleniyor"
  }
  handleChange = e => {
    if(e.target.type == "number"){
      this.setState({
        [e.target.name]: parseFloat(e.target.value)
      })
    }else{
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }
  validated = () => {
    if(
      this.state.stockCode &&
      this.state.barcode &&
      this.state.category &&
      this.state.priceDefault &&
      this.state.provider &&
      this.state.width &&
      this.state.length
    ) return true;
    else{
      this.setState({
        missingError: "Lütfen stok kodu, barkod, kategori, fiyat, genişlik, uzunluk ve m2 alanlarını doldurunuz."
      })
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let postObject = {
      stockCode: this.state.stockCode,
      barcode: this.state.barcode,
      category: this.state.category,
      priceDefault:this.state.priceDefault,
      provider: this.state.provider,
      width: this.state.width,
      length: this.state.length,
      weight: this.state.weight,
      material: this.state.material
    }
    console.log("Sent object", postObject)
    if(this.validated()){
      axios.post(ip + "api/postproduct", postObject).then(response => {
        console.log(response)
        let data = JSON.parse(response.data);
        this.setState({
          id: data.id
        })
      }).catch(error => {
        console.log(error)
      })
    }
  }
  handleCreate = (e) => {
    e.preventDefault()
    if(typeof this.state.postdata != "undefined"){
      console.log(this.state.postdata)
      axios.post(ip + "api/postproduct/createmultiple", this.state.postdata).then(response => {
        this.setState({
          excelStatus: response.data,
        })
      })
    }
  }

  handleUpload = (e) => {
    //attempt to create stock list
    e.preventDefault()
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = e => {
      let productList = []
      let propertyList = []
      var data = new Uint8Array(e.target.result);
      
      var wb = XLSX.read(data, {type: "array"});
      var first_worksheet = wb.Sheets[wb.SheetNames[0]];
      var data = XLSX.utils.sheet_to_json(first_worksheet, {header:1});
      console.log(data)
      data.forEach(function (value,key){
        let singleProduct = {}
        if(key != 0){
          value.forEach(function(propertyData, i){
            if(!propertyList[i].includes("Image"))
              singleProduct[propertyList[i]]=propertyData
            else{
              if(typeof singleProduct["imageLinks"] == "undefined")
                singleProduct["imageLinks"]=[propertyData]
              else
              singleProduct["imageLinks"].push(propertyData)
            }
          })
          productList.push(singleProduct)
        }
        else{
          value.forEach(property =>{
            console.log(property)
            propertyList.push(property)
          })
        }
      })
      console.log(productList)
      this.setState({
        postdata: productList,
        isExcelLoaded: true,
        excelStatus: "Dosya Okundu",
        excelProducts: data.length -1
        
      })
    }
    reader.readAsArrayBuffer(file);
    //write error if there is an error
  }

  render(){
    if(this.state.id){
      return <Redirect to={"/products/" + this.state.id}/>
    }
    return(
      <div className={this.classes.root}>
        
        <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
        <VitausLogo/>
        <LogoLinkSmall to="/productexplorer" logo={<ReturnLogo height={20} width={20}/>} label=".."/>
        <div className={this.classes.firstRow}>
          <div className={this.classes.multiProduct}>
            <h4>Toplu Ürün Yükleme</h4>
            {/* <label htmlFor="multiProductButton" className={this.classes.multiProductButtonLabel}>Text</label> */}
            <input id="multiProductButton" style={{marginTop:"10px", width:"200px"}} type="file" name="excelfile" onChange={this.handleUpload}/>
            {this.state.isExcelLoaded ? (
              <div>
                <p>{this.state.excelStatus}</p>
                <p>Yüklenecek Ürün Sayısı:{this.state.excelProducts}</p>
              </div>
            ) : (
              <p>{this.state.excelStatus}</p>
            )}
            
            
            <button onClick={this.handleCreate}>Yükle</button>
          </div>
        </div>
          
        <div className={this.classes.firstRow}>
          <div className={this.classes.customer}>
            <h4>Tekli Ürün Yükleme</h4>
            <div className={this.classes.customerLeft}>
            <div style={{marginTop: "10px"}}>
              <label htmlFor="stockCode">Stok Kodu</label>
              <input type="text" name="stockCode" width="40" value={this.state.stockCode}/>
            </div>
            <div>
              <label htmlFor="barcode">Barkod</label>
              <input type="text" name="barcode" value={this.state.barcode}/>
            </div>
            <div>
              <label htmlFor="category">Kategori</label>
              <input type="text" name="category" value={this.state.category}/>
            </div>
            <div>
              <label htmlFor="priceDefault">Fiyat</label>
              <input type="number" step={0.01} name="priceDefault" width="40" value={this.state.priceDefault}/>
            </div>
            <div>
              <label htmlFor="provider">Provider</label>
              <input type="text" name="provider" width="40" value={this.state.provider}/>
            </div>
            <div>
              <label htmlFor="width">Genişlik</label>
              <input type="number" step={0.01} name="width" width="40" value={this.state.width}/>
            </div>
            <div>
              <label htmlFor="length">Uzunluk</label>
              <input type="number" step={0.01} name="length" width="40" value={this.state.length}/>
            </div>     
            <div>
              <label htmlFor="weight">Ağırlık</label>
              <input type="number" step={0.01} name="weight" width="40" value={this.state.weight}/>
            </div>
            <div>
              <label htmlFor="material">Materyal</label>
              <input type="text" name="material" width="40" value={this.state.material}/>
            </div>   
            {/* <div className={this.classes.address}>
              <label htmlFor="msquare">m2</label>
              <input type="number" name="msquare" step={0.01} value={this.state.msquare}/>
            </div> */}
            </div>
          </div>
        </div>
          <button className={this.classes.submitButton}>Oluştur</button>
          {this.state.missingError ? (<div>{this.state.missingError}</div>): null}
        </form>
      </div>   

      )
  }
}

export default withStyles(styles)(CreateProduct)