import { app } from "../../config/firebaseConfig"


function IsAuthenticated(){
    let authToken = sessionStorage.getItem('vts-2016-xyztqwerty')
        if (authToken) {
            return true
        }
        if (!authToken) {
            return false
        }       
}

export default {IsAuthenticated}